import React, { FC } from "react";
import styled from "styled-components";
import BoxButton from "./BoxButton";
import CheckBox from "./CheckBox";
import Image from "./Image";
import Typo from "./Typo";
interface CheckBoxJerseyItemProps {
  name: string;
  jersey: string;
  selected: boolean;
  onClick: () => void;
}
const TypoName = styled(Typo)`
  white-space: nowrap;
`;
const CheckBoxJerseyItem: FC<CheckBoxJerseyItemProps> = ({
  selected,
  name,
  jersey,
  onClick,
}) => {
  return (
    <BoxButton
      flexShrink={1}
      overflow="hidden"
      onClick={onClick}
      alignItems="center"
    >
      <CheckBox checked={selected} />
      <Image alt="jersey" ml="l" mr="m" size={24} url={jersey} />
      <TypoName display="inline" color="font.medium" variant="label">
        {name}
      </TypoName>
    </BoxButton>
  );
};

export default CheckBoxJerseyItem;
