import { OtherPositionStats } from "../utils/types";
import { PlayerStatKeyProps } from './PlayerStatKey';
import roundWithDecimal from "../utils/roundWithDecimal";
import PlayerStatisticsBlock from "./PlayerStatisticsBlock";
import { useTranslation } from "react-i18next";

interface DefenderEfficiencyCardStatsProps {
  statistics: OtherPositionStats;
}

export default function DefenderEfficiencyCardStats({
    statistics: {
        totalWonContest,
        totalPlayedMatches,
        totalContest,
        totalWonDuel,
        totalDuel,
        totalLostBall,
        totalFouls,
        totalFouled,
    }
}: DefenderEfficiencyCardStatsProps): JSX.Element {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
      stat: 'wonContest',
      value: totalPlayedMatches
        ? roundWithDecimal(totalWonContest / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalContest
          ? roundWithDecimal((totalWonContest / totalContest) * 100)
          : 0
      }%`,
    },
    {
        stat: 'wonDuel',
      value: totalPlayedMatches
        ? roundWithDecimal(totalWonDuel / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalDuel ? roundWithDecimal((totalWonDuel / totalDuel) * 100) : 0
      }%`,
    },
    {
        stat: 'lostBall',
      value: totalPlayedMatches
        ? roundWithDecimal(totalLostBall / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'fouls',
      value: totalPlayedMatches
        ? roundWithDecimal(totalFouls / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'fouled',
      value: totalPlayedMatches
        ? roundWithDecimal(totalFouled / totalPlayedMatches, 1)
        : 0,
    },
  ];

  return (
    <PlayerStatisticsBlock data={data} title={t('PlayerStatistics.tabs.Stats.efficiencyCard')} />
  )
};