import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import Chevron2 from "../assets/icons/Chevron2";
import { getChampionshipImage } from "../utils/championship";
import Box from "./Box";
import BoxButton from "./BoxButton";
import FilterContainer from "./FilterContainer";
import Image from "./Image";
import Typo from "./Typo";
interface FilterChampionshipProps {
  selectorItem: JSX.Element;
  championship: number;
  isLeft?: boolean;
}
const Container = styled(BoxButton)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.light};
    cursor: pointer;
  }
`;

const FilterChampionship: FC<FilterChampionshipProps> = ({
  championship,
  selectorItem,
  isLeft = false,
}) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <FilterContainer selectorItem={selectorItem} isLeft={isLeft} top="40px">
      <Container
        pl="m"
        py="s"
        width="192px"
        borderRadius="xs"
        bg="white"
        alignItems="center"
      >
        <Image
          mr="m"
          size={24}
          alt="championship logo"
          url={getChampionshipImage(championship)}
        />
        <Box flex={1}>
          <Typo display="inline" color="secondary" variant="label">
            {t(`championships.${championship}`)}
          </Typo>
        </Box>
        <Chevron2 color={themeContext.colors.secondary} size={24} />
      </Container>
    </FilterContainer>
  );
};

export default FilterChampionship;
