import React, { FC } from "react";
import styled from "styled-components";
import Box from "./Box";

const Container = styled(Box)`
  box-shadow: 0px 4px 8px #00000029;

  width: 308px;
`;
const SelectorContainer: FC = ({ children }) => {
  return (
    <Container borderRadius="s" padding="s" bg="white">
      <Box borderRadius="xs" flex={1} flexDirection="column" overflow="hidden">
        {children}
      </Box>
    </Container>
  );
};

export default SelectorContainer;
