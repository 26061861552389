import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Box from "./Box";
import PlayerTab from "./PlayerTab";
interface PlayerTabsProps {
  currentTab: number;
  onClickTab: (tab: number) => void;
}
const BgBox = styled(Box)`
  transition: all 0.5s;
`;
const PlayerTabs: FC<PlayerTabsProps> = ({ currentTab, onClickTab }) => {
  const { t } = useTranslation();

  return (
    <Box
      height="40px"
      border="1px solid"
      borderColor="bg.dark"
      borderRadius="s"
      width="528px"
      bg="white"
      position="relative"
    >
      <BgBox
        position="absolute"
        top="1"
        left={currentTab * 176}
        bg="primary"
        borderRadius="s"
        height="38px"
        width="176px"
      />
      <PlayerTab
        text={t("PlayerStatistics.tabs.Stats.title")}
        isSelected={currentTab === 0}
        onClick={() => onClickTab(0)}
      />
      <PlayerTab
        text={t("PlayerStatistics.tabs.Matches.title")}
        isSelected={currentTab === 1}
        onClick={() => onClickTab(1)}
      />
      <PlayerTab
        text={t("PlayerStatistics.tabs.Quotation.title")}
        isSelected={currentTab === 2}
        onClick={() => onClickTab(2)}
      />
    </Box>
  );
};

export default PlayerTabs;
