import React, { FC } from "react";
import { IIcon } from "./IIcons";

const Check: FC<IIcon> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-Check">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="Check" clipPath="url(#clip-Check)">
        <path
          fill={color}
          id="Union_7"
          data-name="Union 7"
          d="M-234.055-12.482a1.007,1.007,0,0,1-.072-.084.988.988,0,0,1-.08-.075l-2.889-3.009a1.037,1.037,0,0,1,0-1.425.94.94,0,0,1,1.369,0l2.356,2.454,5.245-5.463a.939.939,0,0,1,1.368,0,1.037,1.037,0,0,1,0,1.425l-5.928,6.176a.947.947,0,0,1-.684.295A.946.946,0,0,1-234.055-12.482Z"
          transform="translate(244 28.279)"
        />
      </g>
    </svg>
  );
};

export default Check;
