import React, { FC } from "react";
import styled from "styled-components";
import { getChampionshipImage } from "../utils/championship";
import Box from "./Box";
import BoxButton from "./BoxButton";
import Image from "./Image";
import Typo from "./Typo";
interface LanguageSelectorProps {
  currentLanguage: string;
  onClickLanguage: (lg: string) => void;
}

const StyledBoxButton = styled(BoxButton)`
  &:hover {
    background: ${({ theme }) => theme.colors.bg.light};
  }
`;

const LanguageChoice: FC<{ language: string; onClick: () => void }> = ({
  language,
  onClick,
}) => {
  const flag = language === "fr" ? 10 : language === "es" ? 3 : 2;
  const name =
    language === "fr" ? "Français" : language === "es" ? "Español" : "English";
  return (
    <StyledBoxButton
      px="10px"
      onClick={onClick}
      height="46px"
      alignItems="center"
    >
      <Image alt="flag" size={30} url={getChampionshipImage(flag)} />
      <Typo ml="m" color="font.dark" variant="label">
        {name}
      </Typo>
    </StyledBoxButton>
  );
};
const LanguageSelector: FC<LanguageSelectorProps> = ({
  currentLanguage,
  onClickLanguage,
}) => {
  return (
    <Box
      flexDirection="column"
      width="128px"
      py="10px"
      border="1px solid"
      borderColor="bg.dark"
      bg="white"
      borderRadius="xs"
    >
      {currentLanguage !== "fr" && (
        <LanguageChoice onClick={() => onClickLanguage("fr")} language={"fr"} />
      )}
      {currentLanguage !== "es" && (
        <LanguageChoice onClick={() => onClickLanguage("es")} language={"es"} />
      )}
      {currentLanguage !== "en" && (
        <LanguageChoice onClick={() => onClickLanguage("en")} language={"en"} />
      )}
    </Box>
  );
};

export default LanguageSelector;
