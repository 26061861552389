import React, { FC } from "react";
import Box from "./Box";
import Typo from "./Typo";
interface PlayerMatchBoxScoreProps {
  home: number;
  away: number;
}
const PlayerMatchBoxScore: FC<PlayerMatchBoxScoreProps> = ({ home, away }) => {
  return (
    <Box px="12px" mx="m" py="3px" borderRadius="s" bg="secondaryTrans.20">
      <Typo fontSize="20px" color="secondary" fontFamily="heavy">
        {home} : {away}
      </Typo>
    </Box>
  );
};

export default PlayerMatchBoxScore;
