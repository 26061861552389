import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import {PositionProps} from 'styled-system';
import Box from './Box';
import Typo from './Typo';
interface ChartLabelProps extends PositionProps {
  color: string;
  label: string;
  legendValue: number;
  isAbove: boolean;
}
const StyledLabel = styled(Typo)`
  white-space: pre-line;
  max-width: 100px;
  text-align: center;
`;
const PolarLabel: FC<ChartLabelProps> = ({
  color,
  label,
  legendValue,
  isAbove,
  ...props
}) => {
  const [height, setHeight] = useState(24);

  useEffect(() => {
    setHeight(
      (document.getElementById(`label+${label}`)?.offsetHeight ?? 16) + 8,
    );
  }, [label]);
  return (
    <Box
      width="48px"
      alignItems="center"
      flexDirection={isAbove ? 'column' : 'column-reverse'}
      {...props}>
      <Box
        width="100px"
        top={isAbove ? `-${height}px` : `${32}px`}
        position="absolute"
        justifyContent="center">
        <StyledLabel
          id={`label+${label}`}
          className={'label'}
          variant="legend"
          color="font.medium"
          my="s">
          {label}
        </StyledLabel>
      </Box>

      <Box
        height="32px"
        width="48px"
        alignItems="center"
        justifyContent="center"
        borderRadius="xs"
        bg={color}>
        <Typo variant="tab" color="white">
          {legendValue}
        </Typo>
      </Box>
    </Box>
  );
};

export default PolarLabel;
