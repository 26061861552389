import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Box from './Box';
import Typo from './Typo';
export interface PlayerStatKeyProps {
  stat: string;
  value: string | number;
  subValue?: string | number | undefined;
}
const PlayerStatKey: FC<PlayerStatKeyProps> = ({stat, value, subValue}) => {
  const {t} = useTranslation();
  const percentRegex = /\(.*(%|\s)\)/g;

  const labelStats = t(`PlayerStatistics.tabs.Stats.statsKeys.${stat}`).replace(
    percentRegex,
    '',
  );

  const test = t(`PlayerStatistics.tabs.Stats.statsKeys.${stat}`).match(
    percentRegex,
  );

  return (
    <Box
      flex={1}
      mb="1px"
      bg="white"
      borderRadius="xs"
      height="48px"
      px="m"
      py="10px"
      width="252px"
      justifyContent="space-between">
      <Box justifyContent="center" flexDirection="column">
        <Typo color="font.medium" variant="legend">
          {labelStats}
        </Typo>
        <Typo color="font.medium" variant="legend">
          {test}
        </Typo>
      </Box>
      <Box ml="s" alignItems="center">
        <Typo color="font.dark" variant="tab">
          {value}
        </Typo>
        {subValue ? (
          <Typo ml="s" variant="legend" color="font.light">
            ({subValue})
          </Typo>
        ) : null}
      </Box>
    </Box>
  );
};

export default PlayerStatKey;
