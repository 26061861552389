import { DefenderKeySeasonStats, GoalKeeperKeySeasonStats, MidfielderKeySeasonStats, StrikerKeySeasonStats } from "./types";

export interface strikerData {
  percentRanks: StrikerKeySeasonStats;
  keySeasonStats: StrikerKeySeasonStats;
  position: 4;
}
export interface midfielderData {
  percentRanks: MidfielderKeySeasonStats;
  keySeasonStats: MidfielderKeySeasonStats;
  position: 3;
}
export interface defenderData {
  percentRanks: DefenderKeySeasonStats;
  keySeasonStats: DefenderKeySeasonStats;
  position: 2;
}
export interface goalKeeperData {
  percentRanks: GoalKeeperKeySeasonStats;
  keySeasonStats: GoalKeeperKeySeasonStats;
  position: 1;
}
export type PlayerData =
  | strikerData
  | midfielderData
  | defenderData
  | goalKeeperData;

const roundWithDecimal = (value: number, decimalPoints: number = 0) => {
  return (
    Math.round(value * Math.pow(10, decimalPoints)) /
    Math.pow(10, decimalPoints)
  );
};

export const getPlayerData = (info: PlayerData, t: Function) => {
  if (info.position === 1) {
    const data = [
      {
        label: t("PlayerStatistics.statisticsKey.quotation"),
        value: info.percentRanks.quotation,
        legendValue: info.keySeasonStats.quotation,
      },
      {
        label: t("PlayerStatistics.statisticsKey.percentagePenaltyStopped"),
        value: info.percentRanks.percentagePenaltyStopped,
        legendValue: roundWithDecimal(
          info.keySeasonStats.percentagePenaltyStopped,
          0
        ),
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioGoalsConceded"),
        value: info.percentRanks.ratioGoalsConceded,
        legendValue: roundWithDecimal(
          info.keySeasonStats.ratioGoalsConceded,
          2
        ),
      },
      {
        label: t("PlayerStatistics.statisticsKey.averageRating"),
        value: info.percentRanks.averageRating,
        legendValue: roundWithDecimal(info.keySeasonStats.averageRating, 1),
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioSaves"),
        value: info.percentRanks.ratioSaves,
        legendValue: roundWithDecimal(info.keySeasonStats.ratioSaves, 2),
      },
      {
        label: t("PlayerStatistics.statisticsKey.percentageStarter"),
        value: info.percentRanks.percentageStarter,
        legendValue: roundWithDecimal(info.keySeasonStats.percentageStarter, 0),
      },
    ];
    return data;
  } else if (info.position === 2) {
    const data = [
      {
        label: t("PlayerStatistics.statisticsKey.quotation"),
        value: info.percentRanks.quotation,
        legendValue: info.keySeasonStats.quotation,
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioInterceptions"),
        value: info.percentRanks.ratioInterceptions,
        legendValue: roundWithDecimal(
          info.keySeasonStats.ratioInterceptions,
          2
        ),
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioGoalsConceded"),
        value: info.percentRanks.ratioGoalsConceded,
        legendValue: roundWithDecimal(
          info.keySeasonStats.ratioGoalsConceded,
          2
        ),
      },
      {
        label: t("PlayerStatistics.statisticsKey.averageRating"),
        value: info.percentRanks.averageRating,
        legendValue: roundWithDecimal(info.keySeasonStats.averageRating, 1),
      },
      {
        label: t("PlayerStatistics.statisticsKey.percentageCleanSheet"),
        value: info.percentRanks.percentageCleanSheet,
        legendValue: roundWithDecimal(
          info.keySeasonStats.percentageCleanSheet,
          0
        ),
      },
      {
        label: t("PlayerStatistics.statisticsKey.percentageStarter"),
        value: info.percentRanks.percentageStarter,
        legendValue: roundWithDecimal(info.keySeasonStats.percentageStarter, 0),
      },
    ];
    return data;
  } else if (info.position === 3) {
    const data = [
      {
        label: t("PlayerStatistics.statisticsKey.quotation"),
        value: info.percentRanks.quotation,
        legendValue: info.keySeasonStats.quotation,
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioBigChanceCreated"),
        value: info.percentRanks.ratioBigChanceCreated,
        legendValue: roundWithDecimal(
          info.keySeasonStats.ratioBigChanceCreated,
          2
        ),
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioScoringAtt"),
        value: info.percentRanks.ratioScoringAtt,
        legendValue: roundWithDecimal(info.keySeasonStats.ratioScoringAtt, 2),
      },
      {
        label: t("PlayerStatistics.statisticsKey.averageRating"),
        value: info.percentRanks.averageRating,
        legendValue: roundWithDecimal(info.keySeasonStats.averageRating, 1),
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioGoals"),
        value: info.percentRanks.ratioGoals,
        legendValue: roundWithDecimal(info.keySeasonStats.ratioGoals, 2),
      },
      {
        label: t("PlayerStatistics.statisticsKey.percentageStarter"),
        value: info.percentRanks.percentageStarter,
        legendValue: roundWithDecimal(info.keySeasonStats.percentageStarter, 0),
      },
    ];
    return data;
  } else {
    const data = [
      {
        label: t("PlayerStatistics.statisticsKey.quotation"),
        value: info.percentRanks.quotation,
        legendValue: info.keySeasonStats.quotation,
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioBigChanceCreated"),
        value: info.percentRanks.ratioBigChanceCreated,
        legendValue: roundWithDecimal(
          info.keySeasonStats.ratioBigChanceCreated,
          2
        ),
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioScoringAtt"),
        value: info.percentRanks.ratioScoringAtt,
        legendValue: roundWithDecimal(info.keySeasonStats.ratioScoringAtt, 2),
      },
      {
        label: t("PlayerStatistics.statisticsKey.averageRating"),
        value: info.percentRanks.averageRating,
        legendValue: roundWithDecimal(info.keySeasonStats.averageRating, 1),
      },
      {
        label: t("PlayerStatistics.statisticsKey.ratioGoals"),
        value: info.percentRanks.ratioGoals,
        legendValue: roundWithDecimal(info.keySeasonStats.ratioGoals, 2),
      },
      {
        label: t("PlayerStatistics.statisticsKey.percentageStarter"),
        value: info.percentRanks.percentageStarter,
        legendValue: roundWithDecimal(info.keySeasonStats.percentageStarter, 0),
      },
    ];

    return data;
  }
};

export enum PlayerPerformanceStatus {
  STARTER = 1,
  PLAYED_SUBSTITUTE = 2,
  NOT_PLAYED_SUBSTITUTE = 3,
  INJURED = 4,
  SUSPENDED = 5,
  NOT_IN_GROUP = 6,
}

export const playerRatingColor = (rating: number | undefined | null) => {
  if (rating === undefined || rating === null) {
    return "#959DAF";
  }
  if (rating >= 0 && rating <= 2.5) {
    return "#FA6F6F";
  }
  if (rating >= 3 && rating <= 3.5) {
    return "#F49538";
  }
  if (rating >= 4 && rating <= 4.5) {
    return "#EBA811";
  }
  if (rating >= 5 && rating <= 5.5) {
    return "#E0C948";
  }
  if (rating >= 6 && rating <= 6.5) {
    return "#B5E025";
  }
  if (rating >= 7 && rating <= 7.5) {
    return "#78C73D";
  }
  if (rating >= 8 && rating <= 10) {
    return "#00D600";
  }
  return "#959DAF";
};
