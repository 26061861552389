import React, { FC } from "react";
import { Range, getTrackBackground } from "react-range";
import styled from "styled-components";

import Box from "./Box";
const COLORS = ["white", "#4054CC", "white"];
interface RangeItemProps {
  values: number[];
  onValuesChange: (values: number[]) => void;
  min: number;
  max: number;
  step: number;
}
const ThumbBox = styled(Box)`
  outline: none;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  box-shadow: 0px 0px 2px #0000003d;
  border: 3px solid white;
`;
const TrackContainer = styled(Box)`
  height: 32px;
  flex: 1;
`;
const TrackBox = styled(Box)`
  height: 8px;
  flex: 1;
  border-radius: 8px;
  align-self: center;
`;
const RangeItem: FC<RangeItemProps> = ({
  max,
  min,
  step,
  values,
  onValuesChange,
}) => {
  return (
    <Box width="242px">
      <Range
        step={step}
        renderTrack={({ props, children }) => (
          <TrackContainer
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
          >
            <TrackBox
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values,
                  colors: COLORS,
                  min,
                  max,
                }),
              }}
            >
              {children}
            </TrackBox>
          </TrackContainer>
        )}
        renderThumb={({ props }) => (
          <ThumbBox bg="secondary" {...props} style={{ ...props.style }} />
        )}
        min={min}
        max={max}
        values={values}
        onChange={onValuesChange}
      />
    </Box>
  );
};

export default RangeItem;
