import React from 'react';
import { PlayerStatKeyProps } from './PlayerStatKey';
import roundWithDecimal from '../utils/roundWithDecimal';
import { IGoalKeeperStats } from '../utils/types';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';
import { useTranslation } from 'react-i18next';

interface GoalKeeperEfficiencyCardStatsProps {
  statistics: IGoalKeeperStats;
}

export default function GoalKeeperEfficiencyCardStats({
    statistics: {
        totalSaves,
        totalDeflected,
        totalGoalsConceded,
        totalCleanSheet,
        totalPenaltySaved,
      },
}: GoalKeeperEfficiencyCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
      stat: 'totalSaves',
      value: totalSaves,
    },
    {
        stat: 'totalDeflected',
      value: totalDeflected,
    },
    {
        stat: 'totalSavesPercent',
      value: `${
        totalGoalsConceded + totalSaves
          ? roundWithDecimal(
              (totalSaves / (totalGoalsConceded + totalSaves)) * 100,
            )
          : 0
      }%`,
    },
    {
        stat: 'totalCleanSheet',
      value: totalCleanSheet,
    },
    {
        stat: 'totalPenaltySaved',
      value: totalPenaltySaved,
    },
  ];
  
  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.efficiencyCard')} data={data} />
  )
};
