import React, { FC } from "react";
import Box from "./Box";
import { WIDTH_BOX } from "./LineDiagram";
import Typo from "./Typo";

interface LineDiagramLabelProps {
  label: string;
  isLabelReverse: boolean;
}

const LineDiagramLabelX: FC<LineDiagramLabelProps> = ({
  label,
  isLabelReverse,
}) => {
  return (
    <Box width={WIDTH_BOX} flexDirection="column" alignItems="center">
      <Box
        alignItems="center"
        flexDirection={isLabelReverse ? "column-reverse" : "column"}
      >
        <Box height="8px" borderLeft="1px solid" borderColor="bg.medium" />
        <Box width={WIDTH_BOX} borderTop="1px solid" borderColor="bg.medium" />
      </Box>
      <Typo pb="m" pt="s" variant="legend">
        {label}
      </Typo>
    </Box>
  );
};

export default LineDiagramLabelX;
