import { PlayerStatKeyProps } from './PlayerStatKey';
import roundWithDecimal from '../utils/roundWithDecimal';
import { OtherPositionStats } from '../utils/types';
import { useTranslation } from 'react-i18next';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';

interface StrikerOffensiveCardStatsProps {
  statistics: OtherPositionStats;
}

export default function StrikerOffensiveCardStats({
    statistics: {
        totalPlayedMatches,
        totalScoringAtt,
        totalShotOffTarget,
        totalMinutesPlayed,
        totalGoals,
        totalBigChanceCreated,
        totalBigChanceMissed,
    },
}: StrikerOffensiveCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
        stat: 'shotOnTarget',
      value: totalPlayedMatches
        ? roundWithDecimal(
            (totalScoringAtt - totalShotOffTarget) / totalPlayedMatches,
            1,
          )
        : 0,
      subValue: `${
        totalScoringAtt
          ? roundWithDecimal(
              ((totalScoringAtt - totalShotOffTarget) / totalScoringAtt) * 100,
            )
          : 0
      }%`,
    },
    {
        stat: 'goalFrequency',
      value: totalGoals
        ? roundWithDecimal(totalMinutesPlayed / totalGoals, 1)
        : 0,
    },
    {
        stat: 'bigChanceCreated',
      value: totalBigChanceCreated,
    },
    {
        stat: 'bigChanceMissed',
      value: totalBigChanceMissed,
    },
  ];

  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.offensiveCard')} data={data} />
  )
};
