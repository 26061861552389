import React, { FC, Suspense } from "react";
import { PlayerPerformanceStatus } from "../utils/playerStats";
const Injured = React.lazy(() => import("../assets/icons/Injured"));
const NotInGroup = React.lazy(() => import("../assets/icons/NotInGroup"));
const NotPlayedSubstitute = React.lazy(
  () => import("../assets/icons/NotPlayedSubstitute")
);
const PlayedSubstitute = React.lazy(
  () => import("../assets/icons/PlayedSubstitute")
);
const Starter = React.lazy(() => import("../assets/icons/Starter"));
const Suspended = React.lazy(() => import("../assets/icons/Suspended"));
interface PlayerRatingStatusProps {
  status: PlayerPerformanceStatus;
}
const PlayerRatingStatus: FC<PlayerRatingStatusProps> = ({ status }) => {
  return (
    <>
      <Suspense fallback={status}>
        {status === PlayerPerformanceStatus.INJURED && <Injured size={24} />}
        {status === PlayerPerformanceStatus.NOT_IN_GROUP && (
          <NotInGroup size={24} />
        )}
        {status === PlayerPerformanceStatus.NOT_PLAYED_SUBSTITUTE && (
          <NotPlayedSubstitute size={24} />
        )}
        {status === PlayerPerformanceStatus.PLAYED_SUBSTITUTE && (
          <PlayedSubstitute size={24} />
        )}
        {status === PlayerPerformanceStatus.STARTER && <Starter size={24} />}
        {status === PlayerPerformanceStatus.SUSPENDED && (
          <Suspended size={24} />
        )}
      </Suspense>
    </>
  );
};

export default PlayerRatingStatus;
