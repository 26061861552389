import { IGetDefenderStatistics } from "../utils/types";
import Box from "./Box";
import DefenderDefensiveCardStats from "./DefenderDefensiveCardStats";
import DefenderEfficiencyCardStats from "./DefenderEfficiencyCardStats";
import DefenderOffensiveCardStats from "./DefenderOffensiveCardStats";
import DefenderPassCardStats from "./DefenderPassCardStats";
import DefenderPlayerCardStats from "./DefenderPlayerCardStats";

interface DefenderStatsTabProps {
  statistics: IGetDefenderStatistics;
  championshipId: number;
}

export default function DefenderStatsTab({
    statistics,
    championshipId,
}: DefenderStatsTabProps) {
  const total = statistics?.championships?.[
    championshipId
  ]?.total;

  return (
    <Box width='100%' flexDirection='row'>
      {Object.keys(total.stats) && (
        <Box mr='m' ml='m' mb='m' display='flex' flex='1' flexDirection='row'>
          <Box display='flex' flex='1' flexDirection='column'>
            <DefenderPlayerCardStats
              statistics={total.stats}
            />
            <Box mr='s' ml='s' />
            <DefenderOffensiveCardStats
              statistics={total.stats}
            />
          </Box>
          <Box ml='m' display='flex' flex='1' flexDirection='column'>
            <DefenderEfficiencyCardStats
              statistics={total.stats}
            />
            <Box mr='s' ml='s' />
            <DefenderDefensiveCardStats
              statistics={total.stats}
            />
            <Box mr='s' ml='s' />
            <DefenderPassCardStats
              statistics={total.stats}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

