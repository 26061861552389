import React, { FC } from "react";
import styled from "styled-components";
import { IIcon } from "./IIcons";
type direction = "top" | "bottom";
const StyledChevron = styled.div<{ direction: direction }>`
  transform: rotate(
    ${({ direction }) => (direction === "top" ? "180" : "0")}deg
  );
`;
const Chevron: FC<IIcon & { direction?: direction }> = ({
  color,
  size,
  direction = "bottom",
}) => {
  return (
    <StyledChevron direction={direction}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <g id="Chevron" transform="translate(-40 -27)">
          <g id="_16px" data-name="16px" transform="translate(-36 4)">
            <g id="check" transform="translate(5.208 70.426) rotate(-45)">
              <path
                fill={color}
                id="Rectangle_3"
                data-name="Rectangle 3"
                d="M1,0H1A1,1,0,0,1,2,1V7A0,0,0,0,1,2,7H0A0,0,0,0,1,0,7V1A1,1,0,0,1,1,0Z"
                transform="translate(80 30)"
              />
              <path
                fill={color}
                id="Rectangle_4"
                data-name="Rectangle 4"
                d="M1,0H1A1,1,0,0,1,2,1V7A0,0,0,0,1,2,7H0A0,0,0,0,1,0,7V1A1,1,0,0,1,1,0Z"
                transform="translate(87 35) rotate(90)"
              />
            </g>
          </g>
        </g>
      </svg>
    </StyledChevron>
  );
};

export default Chevron;
