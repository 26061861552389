import React, { FC } from "react";
import { IIcon } from "./IIcons";

const Delete: FC<IIcon> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g id="Delete" transform="translate(-40 -27)">
        <rect
          id="Rectangle_1"
          data-name="Rectangle 1"
          width="24"
          height="24"
          transform="translate(40 27)"
          fillOpacity={0}
        />
        <g
          id="Icon_feather-trash-2"
          data-name="Icon feather-trash-2"
          transform="translate(43.497 30.441)"
        >
          <path
            id="Tracé_1485"
            data-name="Tracé 1485"
            d="M2,4H15.007"
            transform="translate(0 0.224)"
            fill="rgba(255,255,255,0)"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.333"
          />
          <path
            id="Tracé_1486"
            data-name="Tracé 1486"
            d="M13.45,4.224V14.34A1.445,1.445,0,0,1,12,15.785H4.779A1.445,1.445,0,0,1,3.333,14.34V4.224m2.168,0V2.779A1.445,1.445,0,0,1,6.946,1.333h2.89a1.445,1.445,0,0,1,1.445,1.445V4.224"
            transform="translate(0.112 0)"
            fill="rgba(255,255,255,0)"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.333"
          />
          <path
            id="Tracé_1487"
            data-name="Tracé 1487"
            d="M6.667,7.333v4.336"
            transform="translate(0.392 0.503)"
            fill="rgba(255,255,255,0)"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.333"
          />
          <path
            id="Tracé_1488"
            data-name="Tracé 1488"
            d="M9.333,7.333v4.336"
            transform="translate(0.615 0.503)"
            fill="rgba(255,255,255,0)"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.333"
          />
        </g>
      </g>
    </svg>
  );
};

export default Delete;
