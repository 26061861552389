import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { IIcon } from "./IIcons";
type direction = "left" | "right" | "top" | "bottom";
const StyledArrow = styled.div<{ direction: direction }>`
  transform: rotate(
    ${({ direction }) => (direction === "left" ? "180" : "0")}deg
  );
`;
const Arrow: FunctionComponent<IIcon & { direction: direction }> = ({
  size,
  color,
  direction,
}) => {
  return (
    <StyledArrow direction={direction}>
      <svg width={size} height={size} viewBox="0 0 24 24">
        <g id="Next" transform="translate(-40 -27)" fill={color}>
          <path
            id="Union_3"
            data-name="Union 3"
            d="M-277.414-330.808a1,1,0,0,1,0-1.414l1.829-1.829H-281a1,1,0,0,1-1-1,1,1,0,0,1,1-1h5.414l-1.829-1.829a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l4.243,4.243L-276-330.808a1,1,0,0,1-.707.293A1,1,0,0,1-277.414-330.808Z"
            transform="translate(329.4 374.075)"
          />
        </g>
      </svg>
    </StyledArrow>
  );
};
export default Arrow;
