export const QUOTATION_WIDTH = 40;
export const GOAL_WIDTH = 29;
export const POSITION_WIDTH = 44;
export const USE_WIDTH = 57;
export const PRICE_WIDTH = 32;

export const QUOTATION_MIN = 0;
export const QUOTATION_MAX = 100;
export const QUOTATION_STEP = 1;

export const RATING_MIN = 0;
export const RATING_MAX = 10;
export const RATING_STEP = 0.1;

export const GOAL_MIN = 0;
export const GOAL_MAX = 50;
export const GOAL_STEP = 1;

export const USE_MIN = 0;
export const USE_MAX = 100;
export const USE_STEP = 1;
