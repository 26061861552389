import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { actionFilter, IFilters } from "../hooks/useFilters";
import {
  GOAL_MIN,
  GOAL_STEP,
  QUOTATION_MIN,
  QUOTATION_STEP,
  RATING_MAX,
  RATING_MIN,
  RATING_STEP,
  USE_MAX,
  USE_MIN,
  USE_STEP,
} from "../utils/listPlayer";
import { positionsQuantity } from "../utils/position";
import Box from "./Box";
import BoxButton from "./BoxButton";
import FilterInput from "./FilterInput";
import FilterItem from "./FilterItem";
import FilterItemActive from "./FilterItemActive";
import FilterSeason from "./FilterSeason";
import PositionSelector from "./PositionSelector";
import RangeSelector from "./RangeSelector";
import SeasonSelector from "./SeasonSelector";
import TeamSelector, { ITeamSelector } from "./TeamSelector";
import Typo from "./Typo";
interface FilterProps {
  width: number;
  possibleSeasons: number[];
  setSeason: (season: number) => void;
  season: number;
  clubs: ITeamSelector[];
  filters: IFilters;
  handleFilter: (payload: actionFilter) => void;
  positionsCurrentQuantity: positionsQuantity;
  handleQuotation: (range: number[]) => void;
  handleUse: (range: number[]) => void;
  handleGoal: (range: number[]) => void;
  resetFilter: () => void;
  handleRating: (range: number[]) => void;
  activatedFiltersLabel: [string, () => void][];
  inputFilter: string;
  setInputFilter: (text: string) => void;
  maxQuotation: number;
  maxGoal: number;
  isFilterRating: boolean;
  isFilterUse: boolean;
  isFilterGoal: boolean;
}
const FiltersList = styled(Box)`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-width: 0;
`;
const Filter: FC<FilterProps> = ({
  inputFilter,
  setInputFilter,
  width,
  possibleSeasons,
  setSeason,
  clubs,
  season,
  filters,
  handleFilter,
  handleQuotation,
  handleGoal,
  handleRating,
  handleUse,
  resetFilter,
  activatedFiltersLabel,
  positionsCurrentQuantity,
  maxGoal,
  maxQuotation,
  isFilterGoal,
  isFilterUse,
  isFilterRating,
}) => {
  const { t } = useTranslation();
  const isFilterActive =
    Boolean(filters.position.length) ||
    Boolean(filters.team.length) ||
    Boolean(filters.quotation) ||
    Boolean(filters.rating) ||
    Boolean(filters.goal) ||
    Boolean(filters.use);
  const isLittleWidth = width < 675;

  return (
    <Box width={width} flexDirection="column" flex={1}>
      <FilterInput filter={inputFilter} setFilter={setInputFilter} />
      <FiltersList
        width={width}
        border="1px solid"
        borderColor="bg.dark"
        bg="white"
        px="m"
        py="m"
        justifyContent="space-between"
      >
        <Box>
          <FilterItem
            mr={isLittleWidth ? "m" : "l"}
            activated={Boolean(filters.position.length)}
            name={t("filters.title.position")}
            selectorItem={
              <PositionSelector
                onClickPosition={handleFilter}
                positionSelected={filters.position}
                positionsCurrentQuantity={positionsCurrentQuantity}
              />
            }
          />
          <FilterItem
            mr={isLittleWidth ? "m" : "l"}
            activated={Boolean(filters.team.length)}
            name={t("filters.title.team")}
            selectorItem={
              <TeamSelector
                onClickTeam={handleFilter}
                selectedTeams={filters.team}
                teams={clubs}
              />
            }
          />

          <FilterItem
            mr={isLittleWidth ? "m" : "l"}
            activated={Boolean(filters.quotation)}
            name={t("filters.title.price")}
            selectorItem={
              <RangeSelector
                max={maxQuotation}
                min={QUOTATION_MIN}
                step={QUOTATION_STEP}
                onValuesChange={handleQuotation}
                rangeType="price"
                values={filters.quotation || [QUOTATION_MIN, maxQuotation]}
              />
            }
          />
          {isFilterRating && (
            <FilterItem
              mr={isLittleWidth ? "m" : "l"}
              activated={Boolean(filters.rating)}
              name={t("filters.title.quotation")}
              selectorItem={
                <RangeSelector
                  max={RATING_MAX}
                  min={RATING_MIN}
                  step={RATING_STEP}
                  onValuesChange={handleRating}
                  rangeType="quotation"
                  values={filters.rating || [RATING_MIN, RATING_MAX]}
                />
              }
            />
          )}
          {isFilterGoal && (
            <FilterItem
              mr={isLittleWidth ? "m" : "l"}
              activated={Boolean(filters.goal)}
              name={t("filters.title.goal")}
              selectorItem={
                <RangeSelector
                  max={maxGoal}
                  min={GOAL_MIN}
                  step={GOAL_STEP}
                  onValuesChange={handleGoal}
                  rangeType="goal"
                  values={filters.goal || [GOAL_MIN, maxGoal]}
                />
              }
            />
          )}
          {isFilterUse && (
            <FilterItem
              mr={isLittleWidth ? "m" : "l"}
              activated={Boolean(filters.use)}
              name={t("filters.title.use")}
              selectorItem={
                <RangeSelector
                  max={USE_MAX}
                  min={USE_MIN}
                  step={USE_STEP}
                  onValuesChange={handleUse}
                  rangeType="use"
                  values={filters.use || [USE_MIN, USE_MAX]}
                />
              }
            />
          )}
        </Box>
        <FilterSeason
          onClick={() => {}}
          season={season}
          selectorItem={
            <SeasonSelector
              isLeft={false}
              onClickSeason={setSeason}
              selectedSeason={season}
              seasons={possibleSeasons}
            />
          }
        />
      </FiltersList>
      <Box justifyContent="space-between" flex={1}>
        <Box flexWrap="wrap" my="s">
          {activatedFiltersLabel.map((label) => (
            <FilterItemActive
              key={label[0]}
              ml="m"
              my="s"
              name={label[0]}
              onClickItem={label[1]}
            />
          ))}
        </Box>
        {isFilterActive && (
          <BoxButton onClick={resetFilter} mt="5px">
            <DeleteBox borderRadius="xs" p="5px" alignItems="center">
              <DeleteTypo color="secondary" fontFamily="book" fontSize="s">
                {t("filters.delete")}
              </DeleteTypo>
            </DeleteBox>
          </BoxButton>
        )}
      </Box>
    </Box>
  );
};
const DeleteBox = styled(Box)`
  &:hover {
    background: ${({ theme }) => theme.colors.secondaryTrans[20]};
  }
`;
const DeleteTypo = styled(Typo)`
  white-space: nowrap;
`;
export default Filter;
