import React, {FC, useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import Chevron2 from '../assets/icons/Chevron2';
import BoxButton from './BoxButton';
import FilterContainer from './FilterContainer';
import Typo from './Typo';
interface FilterSeasonProps {
  selectorItem: JSX.Element;
  season: number;
  onClick: () => void;
  isLeft?: boolean;
}
const Container = styled(BoxButton)`
  &:hover {
    background-color: ${({theme}) => theme.colors.bg.light};
    cursor: pointer;
  }
`;

const FilterSeason: FC<FilterSeasonProps> = ({
  season,
  selectorItem,
  isLeft = false,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <FilterContainer selectorItem={selectorItem} isLeft={isLeft} top="45px">
      <Container pl="m" py="s" borderRadius="xs" bg="white" alignItems="center">
        <Typo color="secondary" variant="label">
          {season} - {season + 1}
        </Typo>
        <Chevron2 color={themeContext.colors.secondary} size={24} />
      </Container>
    </FilterContainer>
  );
};

export default FilterSeason;
