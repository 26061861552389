import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { PlayerPerformanceStatus } from "../utils/playerStats";

import LineDiagram from "./LineDiagram";
import PlayerRating from "./PlayerRating";
interface pointData {
  rating: number | null | undefined;
  weekNumber: number;
  status: PlayerPerformanceStatus;
  goals?: number;
  ownGoals?: number;
}
interface PlayerRatingDiagramProps {
  data: pointData[];
}
const PlayerRatingDiagram: FC<PlayerRatingDiagramProps> = ({ data }) => {
  const { t } = useTranslation();
  const lineData = data.map((value) => ({
    y: (value.rating || 5) / 10,
    label: t("PlayerStatistics.lineDiagram.tickFormat", {
      tick: value.weekNumber,
    }),
  }));
  const rateData = data.map((value) => ({
    rating: value.rating,
    ownGoals: value.ownGoals,
    goals: value.goals,
    status: value.status,
  }));

  return (
    <LineDiagram
      titleDiagram={t("PlayerStatistics.lineDiagram.yAxisLabel")}
      data={lineData}
    >
      {rateData.map((value, index) => (
        <PlayerRating
          key={index}
          goals={value.goals || 0}
          ownGoals={value.ownGoals || 0}
          rating={value.rating}
          status={value.status}
        />
      ))}
    </LineDiagram>
  );
};

export default PlayerRatingDiagram;
