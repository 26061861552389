import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Close from '../assets/icons/Close';
import Box from './Box';
import BoxButton from './BoxButton';
import React, {FC, useState} from 'react';
const StyledInput = styled.input`
  flex: 1;
  display: flex;
  -webkit-outline: none;
  -moz-outline: none;
  -ms-outline: none;
  -o-outline: none;
  outline: none;

  border: none;
  font-size: 24px;
  font-family: ${({theme}) => theme.fonts.medium};
  color: ${({theme}) => theme.colors.font.dark};
  &::placeholder {
    font-family: ${({theme}) => theme.fonts.book};
    color: ${({theme}) => theme.colors.font.light};
  }
`;
const StyledContainer = styled(Box)<{focused: boolean}>`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  max-width: 800px;
  box-shadow: 0 0 0
    ${({theme, focused}) =>
      !focused ? '0' : ` 3px ${theme.colors.secondaryTrans[50]}`};
`;
interface FilterInputProps {
  filter: string;
  setFilter: (value: string) => void;
}
const FilterInput: FC<FilterInputProps> = ({filter, setFilter}) => {
  const {t} = useTranslation();
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <StyledContainer
      border="1px solid"
      borderColor={Boolean(filter.length) ? 'secondary' : 'bg.dark'}
      focused={focused}
      bg="white"
      px="24px"
      py="11px"
      flex={1}
      zIndex={1}>
      <StyledInput
        onChange={e => setFilter(e.target.value)}
        value={filter}
        onFocus={onFocus}
        autoCapitalize="sentences"
        autoCorrect="false"
        onBlur={onBlur}
        placeholder={t('filters.placeholder')!}
      />
      {Boolean(filter.length) && (
        <BoxButton
          onClick={() => setFilter('')}
          height="16px"
          width="16px"
          borderRadius="16px"
          alignItems="center"
          bg="secondary"
          position="relative">
          <Box position="absolute" left="0">
            <Close color="white" size={16} />
          </Box>
        </BoxButton>
      )}
    </StyledContainer>
  );
};

export default FilterInput;
