import React from 'react';
import { PlayerStatKeyProps } from './PlayerStatKey';
import { OtherPositionStats } from '../utils/types';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';
import { useTranslation } from 'react-i18next';

interface DefenderDefensiveCardStatsProps {
    statistics: OtherPositionStats;
}

export default function DefenderDefensiveCardStats({
    statistics: {
        totalTackle,
        totalMistake,
        totalCleanSheet
    }
}: DefenderDefensiveCardStatsProps): JSX.Element {
    const {t} = useTranslation();

    const data: Array<PlayerStatKeyProps> = [
        {
            stat: 'tackle',
            value: totalTackle,
        },
        {
            stat: 'totalMistake',
            value: totalMistake,
        },
        {
            stat: 'totalCleanSheet',
            value: totalCleanSheet,
        },
    ];
  
    return (
        <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.defensiveCard')} data={data} />
    )
};
