const colors = {
  // Main Colors
  primary: "#45C945",
  secondary: "#4054CC",
  white: "#FFFFFF",
  gold: "#E0C948",
  blue: "#4054CC",

  // Background Colors
  bg: {
    light: "#F4F6F8",
    medium: "#EDF0F2",
    dark: "#D8DAE8",
  },

  // Font Colors
  font: {
    light: "#959DAF",
    medium: "#696773",
    dark: "#272727",
  },

  // Special Colors
  error: "#E81E29",

  // Colors with transparency
  primaryTrans: {
    25: "#45C94541",
    16: "#45C94529",
    20: "#45C945AA",
    40: "#45C94580",
  },

  secondaryTrans: {
    10: "#4054CC1A",
    20: "#4054CC14",
    50: "#4054CC80",
  },
  errorTrans: {
    20: "#E81E2914",
  },
};
export default colors;
