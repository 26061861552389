import QRCode from "qrcode.react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Box from "./Box";
import BoxButton from "./BoxButton";
import Typo from "./Typo";
interface QRCodeProps {
  value: string;
  onClickCopy: () => void;
}
const QRCodeComponent: FC<QRCodeProps> = ({ value, onClickCopy }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        pt="l"
        bg="white"
        width="180px"
        flexDirection="column"
        alignItems="center"
      >
        <Typo variant="header" color="font.dark">
          {t("qr.scan")}
        </Typo>
        <Box pb="l" pt="24px">
          <QRCode renderAs="svg" value={value} size={180} />
        </Box>
        <Box width="1px" height="32px" bg="bg.dark" />
        <Typo py="m" variant="legend" color="font.light">
          {t("qr.or").toUpperCase()}
        </Typo>
        <Box width="1px" height="32px" bg="bg.dark" />
        <Typo mt="l" variant="header" color="font.dark">
          {t("qr.useCode")}
        </Typo>
        <Box
          mt="24px"
          width="180px"
          height="56px"
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderColor="bg.dark"
          borderRadius="xs"
        >
          <Typo color="font.dark" variant="section">
            {value}
          </Typo>
        </Box>
        <BoxButton
          width="180px"
          alignItems="center"
          justifyContent="center"
          height="40px"
          mt="m"
          mb="l"
          onClick={onClickCopy}
        >
          <Typo variant="tab" color="secondary">
            {t("qr.copyCode")}
          </Typo>
        </BoxButton>
      </Box>
    </>
  );
};

export default QRCodeComponent;
