import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Box from "./Box";
import Image from "./Image";
import Typo from "./Typo";
interface PlayerRankingProps {
  image: string;
  ranking: number;
  label: string;
}

const PlayerRanking: FC<PlayerRankingProps> = ({ image, ranking, label }) => {
  const { t } = useTranslation();
  return (
    <Box alignItems="center" flexDirection="column">
      <Image mx="40px" alt="ranking" url={image} size={72} />
      <Typo mt="l" variant="title" color="font.dark">
        {t(
          ranking === 1
            ? "PlayerStatistics.tabs.Quotation.ranking.firstValue"
            : "PlayerStatistics.tabs.Quotation.ranking.value",
          { value: ranking }
        )}
      </Typo>
      <Typo color="font.light" variant="legend">
        {label}
      </Typo>
    </Box>
  );
};

export default PlayerRanking;
