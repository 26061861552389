import React, { FC } from "react";
import { actionTeamFilter } from "../hooks/useFilters";
import Box from "./Box";
import SelectorContainer from "./SelectorContainer";
import TeamItem from "./TeamItem";
export interface ITeamSelector {
  name: string;
  jersey: string;
  teamId: string;
}
export interface TeamSelectorProps {
  teams: ITeamSelector[];
  selectedTeams: string[];
  onClickTeam: (arg: actionTeamFilter) => void;
}

const TeamSelector: FC<TeamSelectorProps> = ({
  teams,
  selectedTeams,
  onClickTeam,
}) => {
  return (
    <SelectorContainer>
      <Box overflow="scroll" flexDirection="column" height="248px">
        {teams.map((item) => (
          <TeamItem
            key={item.teamId}
            name={item.name}
            jersey={item.jersey}
            onClickTeam={() =>
              onClickTeam({ payload: item.teamId, type: "team" })
            }
            selected={selectedTeams.includes(item.teamId)}
          />
        ))}
      </Box>
    </SelectorContainer>
  );
};

export default TeamSelector;
