import React, { FC } from "react";
import useClickFilter from "../hooks/useClickFilter";
import Box from "./Box";
interface FilterContainerProps {
  selectorItem: JSX.Element;
  isLeft?: boolean;
  top: string;
  forceClose?: boolean;
}
const FilterContainer: FC<FilterContainerProps> = ({
  children,
  selectorItem,
  isLeft,
  top,
  forceClose = false,
}) => {
  const {
    containerRef,
    filterRef,
    isFilterOpen,
    onClickFilter,
  } = useClickFilter();
  return (
    <Box position="relative">
      <Box ref={containerRef} onClick={onClickFilter}>
        {children}
      </Box>
      {isFilterOpen &&
        (isLeft ? (
          <Box
            ref={filterRef}
            zIndex={1}
            position="absolute"
            left="0"
            top={top}
          >
            {selectorItem}
          </Box>
        ) : (
          <Box
            ref={filterRef}
            zIndex={1}
            position="absolute"
            right="0"
            top={top}
          >
            {selectorItem}
          </Box>
        ))}
    </Box>
  );
};

export default FilterContainer;
