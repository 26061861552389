import React, {FC, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {List as VirtualizedList} from 'react-virtualized';
import {IListPLayer, ISelectionPlayer, orderBy} from '../utils/types';
import Box from './Box';
import ListHeader from './ListHeader';
import ListPlayer from './ListPlayer';
import Typo from './Typo';

export interface ListProps {
  players: IListPLayer[];
  orderBy: orderBy;
  setOrderBy: (orderBy: orderBy) => void;
  currentChampionship: number;
  sens: number;
  width: number;
  height: number;
  addPlayer: (player: ISelectionPlayer) => void;
  removePlayer: (id: string) => void;
  handleOpenModal: (
    id: string,
    selected: boolean,
    player: ISelectionPlayer,
  ) => void;
  isLoading: boolean;
}

const ListPlayers: FC<ListProps> = ({
  currentChampionship,
  players,
  setOrderBy,
  orderBy,
  sens,
  width,
  height,
  addPlayer,
  removePlayer,
  handleOpenModal,
  isLoading,
}) => {
  const {t} = useTranslation();
  const listRef = useRef<VirtualizedList>(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToRow(0);
    }
  }, [orderBy, currentChampionship]);

  const dataForList = players.map(player => ({
    ...player,
    rating: player.stats.averageRating?.toFixed(2),
    name: (player.firstName || '') + ' ' + player.lastName,
    use: Math.floor(
      ((player.stats.totalStartedMatches || 0) /
        (player.stats.totalMatches || 1)) *
        100,
    ),
    selectionPlayer: {
      name: (player.firstName || '') + ' ' + player.lastName,
      id: player.id,
      position: player.position,
      quotation: player.quotation,
      jersey: player.jersey,
    },
    handleOpenModal,
    removePlayer,
    addPlayer,
  }));
  const renderItem = ({
    index,
    key,

    style,
  }: {
    index: number;
    key: string;
    style: React.CSSProperties;
  }) => {
    const player = dataForList[index];
    const handleOpenModal = dataForList[index].handleOpenModal;
    const removePlayer = dataForList[index].removePlayer;
    const addPlayer = dataForList[index].addPlayer;

    return (
      <div key={key} style={style}>
        <Box flexDirection="column">
          <ListPlayer
            goal={player.stats.totalGoals}
            jerseyUrl={player.jersey}
            name={player.name}
            onClickInformation={() =>
              handleOpenModal(player.id, player.selected, player)
            }
            onClickPlayer={
              player.selected
                ? () => removePlayer(player.selectionPlayer.id)
                : () => addPlayer(player.selectionPlayer)
            }
            position={player.ultraPosition}
            price={player.quotation}
            quotation={player.rating}
            selected={player.selected}
            use={player.use}
          />
        </Box>
      </div>
    );
  };

  return (
    <Box width={width} flexDirection="column">
      <ListHeader
        sens={sens}
        championshipId={currentChampionship}
        itemSelected={orderBy}
        playersNumber={players.length}
        onClickItem={setOrderBy}
      />

      {Boolean(players.length) || isLoading ? (
        <VirtualizedList
          ref={listRef}
          height={height}
          style={{outline: 'none'}}
          rowCount={players.length}
          rowRenderer={renderItem}
          rowHeight={param =>
            param.index === dataForList.length - 1 ? 56 + 24 : 56
          }
          overscanRowCount={50}
          estimatedRowSize={56}
          width={width}
        />
      ) : (
        <Box ml="l">
          <Typo variant="paragraph" color="error">
            {t('filters.noResult')}
          </Typo>
        </Box>
      )}
    </Box>
  );
};

export default ListPlayers;
