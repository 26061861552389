import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {SpaceProps} from 'styled-system';
import {positionsMinQuantity} from '../utils/position';
import {ISelectionPlayer} from '../utils/types';
import Box from './Box';
import SelectionButton from './SelectionButton';
import SelectionIndicator from './SelectionIndicator';
import SelectionPlayer from './SelectionPlayer';
import SelectionPosition from './SelectionPosition';
import Typo from './Typo';
interface SelectionProps extends SpaceProps {
  setQuotation: (id: string, newQuotation: number) => void;
  removePlayer: (id: string) => void;
  budget: number;
  nbPlayers: number;
  selectionGoalKeeper: ISelectionPlayer[];
  selectionDefender: ISelectionPlayer[];
  selectionMidfielder: ISelectionPlayer[];
  selectionStriker: ISelectionPlayer[];
  generateCode: () => void;
}
const Selection: FC<SelectionProps> = ({
  budget,
  selectionGoalKeeper,
  nbPlayers,
  removePlayer,
  selectionDefender,
  selectionMidfielder,
  selectionStriker,
  setQuotation,
  generateCode,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <Box
      {...props}
      bg="white"
      border="1px solid"
      borderColor="bg.dark"
      borderRadius="s"
      flexDirection="column"
      flex={1}>
      <Box
        py="l"
        justifyContent="center"
        borderBottom="1px solid"
        borderColor="bg.dark">
        <Typo color="font.dark" variant="header">
          {t('selection.title')}
        </Typo>
      </Box>
      <Box px="l" flexDirection="column">
        <SelectionIndicator my="m" info={budget} label="budget" />
        <SelectionIndicator mb="m" info={nbPlayers} label="players" />
      </Box>
      <Box
        flex={1}
        overflow="scroll"
        px="l"
        justifyContent="flex-start"
        flexDirection="column">
        <SelectionPosition
          my="m"
          minQuantity={positionsMinQuantity[1]}
          quantity={selectionGoalKeeper.length}
          position={1}
        />
        {selectionGoalKeeper.map(player => (
          <SelectionPlayer
            key={player.id}
            id={player.id}
            name={player.name}
            onCustomPriceChange={setQuotation}
            onDelete={() => removePlayer(player.id)}
            price={player.quotation}
            jersey={player.jersey}
            customPrice={player?.customQuotation}
          />
        ))}
        <SelectionPosition
          my="m"
          minQuantity={positionsMinQuantity[2]}
          quantity={selectionDefender.length}
          position={2}
        />
        {selectionDefender.map(player => (
          <SelectionPlayer
            key={player.id}
            id={player.id}
            name={player.name}
            onCustomPriceChange={setQuotation}
            onDelete={() => removePlayer(player.id)}
            price={player.quotation}
            jersey={player.jersey}
            customPrice={player?.customQuotation}
          />
        ))}
        <SelectionPosition
          my="m"
          minQuantity={positionsMinQuantity[3]}
          quantity={selectionMidfielder.length}
          position={3}
        />
        {selectionMidfielder.map(player => (
          <SelectionPlayer
            key={player.id}
            id={player.id}
            name={player.name}
            onCustomPriceChange={setQuotation}
            onDelete={() => removePlayer(player.id)}
            price={player.quotation}
            jersey={player.jersey}
            customPrice={player?.customQuotation}
          />
        ))}
        <SelectionPosition
          my="m"
          minQuantity={positionsMinQuantity[4]}
          quantity={selectionStriker.length}
          position={4}
        />
        {selectionStriker.map(player => (
          <SelectionPlayer
            key={player.id}
            id={player.id}
            name={player.name}
            onCustomPriceChange={setQuotation}
            onDelete={() => removePlayer(player.id)}
            price={player.quotation}
            jersey={player.jersey}
            customPrice={player?.customQuotation}
          />
        ))}
      </Box>
      <Box p="m">
        <SelectionButton
          disabled={!Boolean(nbPlayers)}
          onClick={generateCode}
        />
      </Box>
    </Box>
  );
};

export default Selection;
