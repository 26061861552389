/**
 * SHOULD NOT BE EDITED
 */

// Used to control margins and paddings
const spaces = {
  none: 0,
  xs: 2,
  s: 4,
  m: 8,
  l: 16,
  xl: 24,
};
export default spaces;
