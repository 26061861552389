import FR from "../assets/images/flags/FR.png";
import L1 from "../assets/images/flags/L1.png";
import GB from "../assets/images/flags/GB-ENG.png";
import ES from "../assets/images/flags/ES.png";
import L2 from "../assets/images/flags/L2.png";
import EU from "../assets/images/flags/EU.png";
import IT from "../assets/images/flags/IT.png";
export const getChampionshipImage = (championshipsId: number) => {
  switch (championshipsId) {
    case 1:
      return L1;
    case 2:
      return GB;
    case 3:
      return ES;
    case 4:
      return L2;
    case 5:
      return IT;
    case 6:
      return EU;
    default:
      return FR;
  }
};
export const orderedChampionship = (lg: string) => {
  if ("es" === lg) {
    return [3, 2, 5, 1, 4, 6];
  }
  if ("en" === lg) {
    return [2, 3, 5, 1, 4, 6];
  } else {
    return [1, 2, 3, 4, 5, 6];
  }
};
