const radii = [4, 8, 12, 16, 20, 24, 28];

const radiiValues = {
  xs: radii[0], // 4
  s: radii[1], // 8
  m: radii[2], // 12
  l: radii[3], // 16
  xl: radii[4], // 20
  xxl: radii[5], // 24
  xxxl: radii[6], // 28
};
export default radiiValues;
