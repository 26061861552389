import {useTranslation} from 'react-i18next';
import {Position} from '../utils/position';
import {
  IGetGoalKeeperStatistics,
  IGetDefenderStatistics,
  IGetMidfielderStatistics,
  IGetStrikerStatistics,
  IMatch,
  IGetPlayerStatistics,
  IChampionships,
  ITotal,
  OtherPositionStats,
} from '../utils/types';
import Box from './Box';
import DefenderStatsTab from './DefenderStatsTab';
import GoalKeeperStatsTab from './GoalKeeperStatsTab';
import MidfielderStatsTab from './MidfielderStatsTab';
import PlayerRatingDiagram from './PlayerRatingDiagram';
import StrikerStatsTab from './StrikerStatsTab';
import Typo from './Typo';
import {isEmpty} from 'lodash';
import EmptyState from './EmptyState';
import {hasEnoughDataToDisplay} from '../utils/hasEnoughDataToDisplay';
import {useCallback, useMemo} from 'react';
import DefaultLigue from '../assets/images/default_ligue.jpg';
import Bresil from '../assets/images/bresil.jpg';

type StatsTabProps = {
  currentChampionshipStats:
    | IChampionships<OtherPositionStats, ITotal<OtherPositionStats>>
    | undefined;
  statistics: IGetPlayerStatistics | undefined;
  currentChampionship: number | undefined;
  availableSeasons: string[] | undefined;
};

export default function StatsTab({
  currentChampionshipStats,
  statistics,
  currentChampionship = 0,
  availableSeasons = [],
}: StatsTabProps): JSX.Element {
  const {t} = useTranslation();

  const playerRatingDiagramData = useMemo(() => {
    return currentChampionshipStats?.total?.matches?.map((match: IMatch) => {
      let obj = {
        weekNumber: match?.gameWeekNumber,
        ...match?.playerPerformance,
      };
      return obj;
    });
  }, [currentChampionshipStats]);

  const renderContent = useCallback(() => {
    switch (statistics?.position) {
      case Position.GOALKEEPER:
        return (
          <GoalKeeperStatsTab
            championshipId={currentChampionship}
            statistics={statistics as IGetGoalKeeperStatistics}
          />
        );
      case Position.DEFENDER:
        return (
          <DefenderStatsTab
            championshipId={currentChampionship}
            statistics={statistics as IGetDefenderStatistics}
          />
        );
      case Position.MID_FIELDER:
        return (
          <MidfielderStatsTab
            championshipId={currentChampionship}
            statistics={statistics as IGetMidfielderStatistics}
          />
        );
      case Position.STRIKER:
        return (
          <StrikerStatsTab
            championshipId={currentChampionship}
            statistics={statistics as IGetStrikerStatistics}
          />
        );
      default:
        return <></>;
    }
  }, [currentChampionship, statistics]);

  const isStatisticsEmpty = useMemo(
    () =>
      isEmpty(currentChampionshipStats?.total?.stats) ||
      currentChampionshipStats?.total?.stats?.totalPlayedMatches === 0,
    [currentChampionshipStats],
  );

  if (availableSeasons?.length === 1 && isStatisticsEmpty) {
    return (
      <EmptyState
        image={Bresil}
        title={t('PlayerStatistics.emptyStats.2.title')}
        subtitle={t('PlayerStatistics.emptyStats.2.subtitle')}
      />
    );
  }

  if (
    isStatisticsEmpty ||
    (currentChampionshipStats &&
      !hasEnoughDataToDisplay(currentChampionshipStats?.total?.matches, 1))
  ) {
    return (
      <EmptyState
        image={DefaultLigue}
        title={t('PlayerStatistics.emptyStats.1.title')}
        subtitle={t('PlayerStatistics.emptyStats.1.subtitle')}
      />
    );
  }

  return (
    <Box flexDirection="column" mt="m" mb="m" width="100%" alignItems="center">
      <Typo mt="m" mb="m" variant="tab">
        {t('PlayerStatistics.tabs.Stats.playerRatingBlock.title')}
      </Typo>
      <PlayerRatingDiagram data={(playerRatingDiagramData as any) ?? []} />
      {renderContent()}
    </Box>
  );
}
