import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Box from './Box';
import BoxButton from './BoxButton';
import CheckBox from './CheckBox';
import Typo from './Typo';
export interface SeasonItemProps {
  season: number;
  selected: boolean;
  onClickSeason: () => void;
  isLeft: boolean;
}
const SeasonItem: FC<SeasonItemProps> = ({
  season,
  selected,
  onClickSeason,
  isLeft,
}) => {
  const [hover, setHover] = useState(false);
  const {t} = useTranslation();

  return (
    <Box
      onClick={() => onClickSeason()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      bg={selected ? 'secondaryTrans.20' : hover ? 'bg.light' : 'white'}
      px="l"
      py="14px"
      flex={1}
      justifyContent={isLeft ? 'flex-start' : 'flex-end'}>
      <BoxButton alignItems="center">
        {isLeft && <CheckBox isRadio checked={selected} />}

        <Typo mx="22px" display="inline" color="font.medium" variant="label">
          {t('filters.season', {season: `${season} - ${season + 1}`})}
        </Typo>
        {!isLeft && <CheckBox isRadio checked={selected} />}
      </BoxButton>
    </Box>
  );
};

export default SeasonItem;
