import React, { FC } from "react";
import { IIcon } from "./IIcons";

const Add: FC<IIcon> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-Add">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="Add" clipPath="url(#clip-Add)">
        <rect width="24" height="24" fill="none" />
        <g id="Add-2" data-name="Add" transform="translate(-40 -27)">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="24"
            height="24"
            transform="translate(40 27)"
            fill="none"
          />
          <rect
            id="Rectangle_499"
            data-name="Rectangle 499"
            width="2"
            height="12"
            rx="1"
            fill={color}
            transform="translate(51 33)"
          />
          <rect
            id="Rectangle_500"
            data-name="Rectangle 500"
            width="2"
            height="12"
            rx="1"
            fill={color}
            transform="translate(58 38) rotate(90)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Add;
