import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IGetChampionshipClub} from '../api/useChampionshipClub';
import {
  GOAL_MIN,
  QUOTATION_MIN,
  RATING_MAX,
  RATING_MIN,
  USE_MAX,
  USE_MIN,
} from '../utils/listPlayer';
import {IListPLayer} from '../utils/types';
export type actionTeamFilter = {
  type: 'team';
  payload: string;
};
export type actionPositionFilter = {
  type: 'position';
  payload: number;
};
export type actionQuotationFilter = {
  type: 'quotation';
  payload: number[];
};
export type actionGoalFilter = {
  type: 'goal';
  payload: number[];
};
export type actionUseFilter = {
  type: 'use';
  payload: number[];
};
export type actionRatingFilter = {
  type: 'rating';
  payload: number[];
};
export type actionFilter =
  | actionTeamFilter
  | actionPositionFilter
  | actionQuotationFilter
  | actionGoalFilter
  | actionUseFilter
  | actionRatingFilter;
export interface IFilters {
  team: string[];
  position: number[];
  quotation: number[] | null;
  use: number[] | null;
  goal: number[] | null;
  rating: number[] | null;
}

const initialStateFilter: IFilters = {
  team: [],
  position: [],
  quotation: null,
  use: null,
  goal: null,
  rating: null,
};
const filteringTeam = (team: string[], player: IListPLayer) =>
  Boolean(!team.length) || team.includes(player.clubId);
const filteringPosition = (position: number[], player: IListPLayer) =>
  Boolean(!position.length) ||
  position.includes(player.ultraPosition) ||
  position.includes(player.position);

const filteringQuotation = (quotations: number[] | null, player: IListPLayer) =>
  Boolean(!quotations) ||
  (player.quotation >= quotations![0] && player.quotation <= quotations![1]);
const filteringInput = (text: string, player: IListPLayer) => {
  return (
    Boolean(!text) ||
    ((player.firstName || '') + ' ' + player.lastName)
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        text
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      )
  );
};

const filteringRating = (ratings: number[] | null, player: IListPLayer) =>
  Boolean(!ratings) ||
  ((player.stats.averageRating || 0) >= ratings![0] &&
    (player.stats.averageRating || 0) <= ratings![1]);
const filteringGoal = (goals: number[] | null, player: IListPLayer) =>
  Boolean(!goals) ||
  ((player.stats.totalGoals || 0) >= goals![0] &&
    (player.stats.totalGoals || 0) <= goals![1]);
const filteringUse = (use: number[] | null, player: IListPLayer) =>
  Boolean(!use) ||
  (((player.stats.totalStartedMatches || 0) /
    (player.stats.totalMatches || 1)) *
    100 >=
    use![0] &&
    ((player.stats.totalStartedMatches || 0) /
      (player.stats.totalMatches || 1)) *
      100 <=
      use![1]);

const useFilters = (
  players: IListPLayer[],
  dataClub: IGetChampionshipClub | undefined,
  championshipId: number,
  language: string,
  maxQuotation: number,
  maxGoal: number,
) => {
  const {t} = useTranslation();
  const [inputFilter, setInputFilter] = useState('');
  const [filters, setFilters] = useState(initialStateFilter);
  const resetFilter = () => setFilters(initialStateFilter);

  const lnKey =
    language === 'en' ? 'en-GB' : language === 'es' ? 'es-ES' : 'fr-FR';
  const clubPossible = Object.values(dataClub?.championshipClubs || {})
    .filter(club =>
      Boolean(
        club.championships[championshipId] &&
          club.championships[championshipId].active,
      ),
    )
    .map(value => ({
      jersey: value.defaultJerseyUrl,
      name: value.name[lnKey],
      teamId: value.id,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  useEffect(() => {
    resetFilter();
  }, [championshipId]);

  const handleFilter = (action: actionFilter) => {
    switch (action.type) {
      case 'team':
        setFilters(prev => {
          let newTeamFilter;
          if (prev.team.includes(action.payload)) {
            newTeamFilter = prev.team.filter(el => el !== action.payload);
          } else {
            newTeamFilter = [...prev.team, action.payload];
          }
          return {...prev, team: newTeamFilter};
        });
        break;
      case 'position':
        setFilters(prev => {
          let newPositionFilter;
          if (action.payload === 1 || action.payload === 4) {
            if (prev.position.includes(action.payload)) {
              newPositionFilter = prev.position.filter(
                position => position !== action.payload,
              );
            } else {
              newPositionFilter = [...prev.position, action.payload];
            }
          } else if (action.payload === 2 || action.payload === 3) {
            if (
              prev.position.includes(action.payload) ||
              prev.position.includes(action.payload * 10) ||
              prev.position.includes(action.payload * 10 + 1)
            ) {
              newPositionFilter = prev.position.filter(
                position =>
                  position !== action.payload &&
                  position !== action.payload * 10 &&
                  position !== action.payload * 10 + 1,
              );
            } else {
              newPositionFilter = [
                ...prev.position,
                action.payload,
                action.payload * 10,
                action.payload * 10 + 1,
              ];
            }
          } else if (action.payload === 20 || action.payload === 21) {
            if (prev.position.includes(action.payload)) {
              newPositionFilter = prev.position.filter(
                position => position !== action.payload && position !== 2,
              );
            } else {
              if (
                prev.position.includes(action.payload - 1) ||
                prev.position.includes(action.payload + 1)
              )
                newPositionFilter = [...prev.position, action.payload, 2];
              else {
                newPositionFilter = [...prev.position, action.payload];
              }
            }
          } else if (action.payload === 30 || action.payload === 31) {
            if (prev.position.includes(action.payload)) {
              newPositionFilter = prev.position.filter(
                position => position !== action.payload && position !== 3,
              );
            } else {
              if (
                prev.position.includes(action.payload - 1) ||
                prev.position.includes(action.payload + 1)
              )
                newPositionFilter = [...prev.position, action.payload, 3];
              else {
                newPositionFilter = [...prev.position, action.payload];
              }
            }
          } else {
            newPositionFilter = prev.position;
          }

          return {...prev, position: newPositionFilter};
        });
        break;
      case 'quotation':
        setFilters(prev => {
          if (
            action.payload[0] === QUOTATION_MIN &&
            action.payload[1] === maxQuotation
          ) {
            return {...prev, quotation: null};
          } else {
            return {...prev, quotation: action.payload};
          }
        });
        break;
      case 'goal':
        setFilters(prev => {
          if (action.payload[0] === GOAL_MIN && action.payload[1] === maxGoal) {
            return {...prev, goal: null};
          } else {
            return {...prev, goal: action.payload};
          }
        });
        break;
      case 'use':
        setFilters(prev => {
          if (action.payload[0] === USE_MIN && action.payload[1] === USE_MAX) {
            return {...prev, use: null};
          } else {
            return {...prev, use: action.payload};
          }
        });
        break;
      case 'rating':
        setFilters(prev => {
          if (
            action.payload[0] === RATING_MIN &&
            action.payload[1] === RATING_MAX
          ) {
            return {...prev, rating: null};
          } else {
            return {...prev, rating: action.payload};
          }
        });
        break;

      default:
        break;
    }
  };
  const handleQuotation = (range: number[]) =>
    handleFilter({type: 'quotation', payload: range});
  const handleRating = (range: number[]) =>
    handleFilter({type: 'rating', payload: range});
  const handleUse = (range: number[]) =>
    handleFilter({type: 'use', payload: range});
  const handleGoal = (range: number[]) =>
    handleFilter({type: 'goal', payload: range});

  const filteredPlayers = players.filter(
    player =>
      filteringTeam(filters.team, player) &&
      filteringPosition(filters.position, player) &&
      filteringQuotation(filters.quotation, player) &&
      filteringRating(filters.rating, player) &&
      filteringGoal(filters.goal, player) &&
      filteringUse(filters.use, player) &&
      filteringInput(inputFilter, player),
  );

  const labelPositions = filters.position.filter(
    position => position !== 2 && position !== 3,
  );

  const activatedFiltersLabel = [
    ...labelPositions.map(position => [
      t(`playerPositions.filter.${position}`),
      () => handleFilter({type: 'position', payload: position}),
    ]),
    ...filters.team.map(team => [
      dataClub?.championshipClubs[team].name[lnKey],
      () => handleFilter({type: 'team', payload: team}),
    ]),
    ...(filters.quotation
      ? [
          [
            t('filters.range.price.labelActive', {
              value1: filters.quotation[0],
              value2: filters.quotation[1],
            }),
            () => handleQuotation([QUOTATION_MIN, maxQuotation]),
          ],
        ]
      : []),
    ...(filters.use
      ? [
          [
            t('filters.range.use.labelActive', {
              value1: filters.use[0],
              value2: filters.use[1],
            }),
            () => handleUse([USE_MIN, USE_MAX]),
          ],
        ]
      : []),
    ...(filters.rating
      ? [
          [
            t('filters.range.quotation.labelActive', {
              value1: filters.rating[0],
              value2: filters.rating[1],
            }),
            () => handleRating([RATING_MIN, RATING_MAX]),
          ],
        ]
      : []),
    ...(filters.goal
      ? [
          [
            t('filters.range.goal.labelActive', {
              value1: filters.goal[0],
              value2: filters.goal[1],
            }),
            () => handleGoal([GOAL_MIN, maxGoal]),
          ],
        ]
      : []),
  ] as [string, () => void][];

  return {
    filteredPlayers,
    clubPossible,
    handleFilter,
    handleGoal,
    handleUse,
    handleRating,
    handleQuotation,
    resetFilter,
    filters,
    activatedFiltersLabel,
    inputFilter,
    setInputFilter,
  };
};

export default useFilters;
