import React, { FC, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { SpaceProps } from "styled-system";
import Close from "../assets/icons/Close";
import BoxButton from "./BoxButton";
import Typo from "./Typo";

interface FilterItemActiveProps extends SpaceProps {
  name: string;
  onClickItem: () => void;
}
const FilterItemActive: FC<FilterItemActiveProps> = ({
  name,
  onClickItem,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const themeContext = useContext(ThemeContext);
  return (
    <BoxButton
      onClick={onClickItem}
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      height="24px"
      pr="8px"
      alignItems="center"
      border="1px solid"
      borderColor={"secondary"}
      bg={hover ? "bg.light" : "white"}
      borderRadius="m"
    >
      <Close color={themeContext.colors.secondary} size={24} />
      <Typo color={"secondary"} fontFamily="book" fontSize="xs">
        {name}
      </Typo>
    </BoxButton>
  );
};

export default FilterItemActive;
