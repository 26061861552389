import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Box from "../components/Box";
import BoxButton from "../components/BoxButton";
import Modal from "../components/Modal";
import Typo from "../components/Typo";
interface CloseModalProps {
  isVisible: boolean;
  closeModal: () => void;
  link: string;
}
const StyledLink = styled.a`
  text-decoration: none;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex: 1;
`;
const CloseModal: FC<CloseModalProps> = ({ isVisible, closeModal, link }) => {
  const { t } = useTranslation();
  return (
    <Modal isVisible={isVisible}>
      <Box flexDirection="column">
        <Box justifyContent="center">
          <Typo mt="l" fontFamily="heavy" color="font.dark" fontSize="20px">
            {t("modalClose.title")}
          </Typo>
        </Box>
        <Box width="295px" mx="l">
          <Typo
            textAlign="center"
            fontFamily="book"
            mt="m"
            color="font.medium"
            fontSize="15px"
          >
            {t("modalClose.text")}
          </Typo>
        </Box>
        <Box justifyContent="center">
          <Typo fontFamily="book" mb="l" mt="m" color="error" fontSize="15px">
            {t("modalClose.warning")}
          </Typo>
        </Box>
        <Box flex={1} borderTop="1px solid" borderColor="bg.dark">
          <BoxButton
            onClick={closeModal}
            flex={1}
            justifyContent="center"
            padding="20px"
          >
            <Typo fontFamily="book" color="font.medium" fontSize="15px">
              {t("modalClose.cancel")}
            </Typo>
          </BoxButton>

          <StyledLink href={link}>
            <Typo fontFamily="book" color="secondary" fontSize="15px">
              {t("modalClose.leave")}
            </Typo>
          </StyledLink>
        </Box>
      </Box>
    </Modal>
  );
};

export default CloseModal;
