import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { getChampionshipImage } from "../utils/championship";
import Box from "./Box";
import BoxButton from "./BoxButton";
import CheckBox from "./CheckBox";
import Image from "./Image";
import Typo from "./Typo";
interface ChampionshipItemProps {
  championship: number;
  selected: boolean;
  onClickChampionship: () => void;
}
const ChampionshipItem: FC<ChampionshipItemProps> = ({
  championship,
  selected,
  onClickChampionship,
}) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  return (
    <BoxButton
      onClick={() => onClickChampionship()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      bg={selected ? "secondaryTrans.20" : hover ? "bg.light" : "white"}
      px="l"
      py="14px"
      alignItems="center"
      flex={1}
      justifyContent="flex-end"
    >
      <CheckBox isRadio checked={selected} />

      <Box flex={1}>
        <Typo display="inline" mx="22px" color="font.medium" variant="label">
          {t(`championships.${championship}`)}
        </Typo>
      </Box>
      <Image
        size={24}
        alt="championship logo"
        url={getChampionshipImage(championship)}
      />
    </BoxButton>
  );
};

export default ChampionshipItem;
