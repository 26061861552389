import {orderBy} from 'lodash';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';
import {
  IChampionshipClub,
  IChampionships,
  IGetPlayerStatistics,
  IPoolPlayer,
  IQuotation,
  ISelectionPlayer,
  ITotal,
  OtherPositionStats,
} from '../utils/types';
import Box from './Box';
import PlayerQuotationDiagram from './PlayerQuotationDiagram';
import PlayerRankings from './PlayerRankings';
import Typo from './Typo';
import {format, parseISO, compareAsc} from 'date-fns';
import EmptyState from './EmptyState';
import {isEmpty} from 'lodash';
import {hasEnoughDataToDisplay} from '../utils/hasEnoughDataToDisplay';
import DefaultLigue from '../assets/images/default_ligue.jpg';
import Bresil from '../assets/images/bresil.jpg';

type QuotationsTabProps = {
  currentChampionshipStats:
    | IChampionships<OtherPositionStats, ITotal<OtherPositionStats>>
    | undefined;
  statistics: IGetPlayerStatistics | undefined;
  currentChampionship: number | undefined;
  poolPlayers: IPoolPlayer[] | undefined;
  currentPlayer: ISelectionPlayer | undefined;
  currentClub: IChampionshipClub | undefined;
  availableSeasons: string[] | undefined;
};

export default function QuotationsTab({
  statistics,
  currentChampionshipStats,
  poolPlayers,
  currentPlayer,
  currentChampionship,
  currentClub,
  availableSeasons = [],
}: QuotationsTabProps): JSX.Element {
  const {t} = useTranslation();

  const lnKey = useMemo(
    () =>
      i18n.language === 'en'
        ? 'en-GB'
        : i18n.language === 'es'
        ? 'es-ES'
        : 'fr-FR',
    [],
  );

  const rankChampionship =
    orderBy(poolPlayers, 'quotation', 'desc').findIndex(
      player => player.id === currentPlayer?.id,
    ) + 1;

  const positionRanking = useCallback(
    (position: number) => {
      return (
        orderBy(poolPlayers, 'quotation', 'desc')
          .filter(player => player.position === position)
          .findIndex(player => player.id === currentPlayer?.id) + 1
      );
    },
    [currentPlayer, poolPlayers],
  );

  const clubRanking =
    orderBy(poolPlayers, 'quotation', 'desc')
      .filter(player => player.clubId === currentPlayer?.clubId)
      .findIndex(player => player.id === currentPlayer?.id) + 1;

  const quotations: IQuotation[] = useMemo(() => {
    return (
      currentChampionshipStats?.total?.quotations
        ?.sort((a: IQuotation, b: IQuotation) =>
          compareAsc(new Date(a.date), new Date(b.date)),
        )
        ?.map((q: IQuotation) => {
          return {
            quotation: q?.quotation,
            date: format(parseISO(q.date), 'dd/MM'),
          };
        }) ?? []
    );
  }, [currentChampionshipStats]);

  const isStatisticsEmpty = useMemo(
    () =>
      isEmpty(currentChampionshipStats?.total?.stats) ||
      currentChampionshipStats?.total?.stats?.totalPlayedMatches === 0,
    [currentChampionshipStats],
  );

  if (availableSeasons?.length === 1 && isStatisticsEmpty) {
    return (
      <EmptyState
        image={Bresil}
        title={t('PlayerStatistics.emptyStats.2.title')}
        subtitle={t('PlayerStatistics.emptyStats.2.subtitle')}
      />
    );
  }

  if (
    isStatisticsEmpty ||
    (currentChampionshipStats &&
      !hasEnoughDataToDisplay(currentChampionshipStats?.total?.matches, 1))
  ) {
    return (
      <EmptyState
        image={DefaultLigue}
        title={t('PlayerStatistics.emptyStats.1.title')}
        subtitle={t('PlayerStatistics.emptyStats.1.subtitle')}
      />
    );
  }

  return (
    <Box alignItems="center" flexDirection="column" mt="l" mb="l">
      <Typo mb="m" variant="tab">
        {t('PlayerStatistics.tabs.Stats.playerQuotationBlock.title1')}
      </Typo>
      <PlayerQuotationDiagram data={quotations} />
      <Typo mt="l" mb="m" variant="tab">
        {t('PlayerStatistics.tabs.Stats.playerQuotationBlock.title2')}
      </Typo>
      <PlayerRankings
        championship={currentChampionship ?? 0}
        championshipRanking={rankChampionship}
        club={currentClub?.name[lnKey] ?? ''}
        clubJersey={currentPlayer?.jersey ?? ''}
        clubRanking={clubRanking}
        position={currentPlayer?.position ?? 0}
        positionRanking={positionRanking(statistics?.position!) ?? 0}
      />
    </Box>
  );
}
