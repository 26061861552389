import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Box from './Box';
import CodeInput from './CodeInput';
import Typo from './Typo';
interface CodeProps {
  code: string;
  setCode: (value: string) => void;
  submit: () => void;
}
const Code: FC<CodeProps> = ({setCode, submit, code}) => {
  const {t} = useTranslation();
  return (
    <Box
      flexDirection="column"
      py="20px"
      px="24px"
      mb="m"
      border="1px solid"
      borderColor="bg.dark"
      borderRadius="s"
      bg="white">
      <Box>
        <Box flex={1} />
        <Typo mb="m" variant="label" color="font.dark">
          {t('code.title')}
        </Typo>
        <Box flex={1} />
      </Box>

      <CodeInput value={code} onChange={setCode} onSubmit={submit} />
    </Box>
  );
};

export default Code;
