import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import { SpaceProps } from "styled-system";
import Goal from "../assets/icons/Goal";
import Box from "./Box";
interface PlayerRatingGoalProps extends SpaceProps {
  goals: number;
  ownGoals: number;
}
const PlayerRatingGoal: FC<PlayerRatingGoalProps> = ({
  goals,
  ownGoals,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);

  const arrayGoals = new Array(goals || 0).fill(null);
  const arrayOwnGoals = new Array(ownGoals || 0).fill(null);

  return (
    <Box {...props}>
      {arrayGoals.map((_, index) => (
        <Box key={index}>
          <Goal size={12} color={themeContext.colors.secondary} />
        </Box>
      ))}
      {arrayOwnGoals.map((_, index) => (
        <Goal key={index} size={12} color={themeContext.colors.error} />
      ))}
    </Box>
  );
};

export default PlayerRatingGoal;
