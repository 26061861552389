import React, { FC } from "react";
import { actionPositionFilter } from "../hooks/useFilters";
import {
  positionsFilter,
  positionsMinQuantity,
  positionsQuantity,
  TypePosition,
} from "../utils/position";
import PositionItem from "./PositionItem";
import SelectorContainer from "./SelectorContainer";
export interface PositionSelectorProps {
  positionSelected: number[];
  positionsCurrentQuantity: positionsQuantity;

  onClickPosition: (arg: actionPositionFilter) => void;
}

const PositionSelector: FC<PositionSelectorProps> = ({
  positionSelected,
  positionsCurrentQuantity,
  onClickPosition,
}) => {
  return (
    <SelectorContainer>
      {positionsFilter.map((position) => (
        <PositionItem
          partiallySelected={
            positionSelected.includes(position * 10 ) ||
            positionSelected.includes(position * 10 + 1)
          }
          key={position}
          py="14px"
          px="16px"
          onClickPosition={() =>
            onClickPosition({ type: "position", payload: position })
          }
          position={position}
          selected={positionSelected.includes(position)}
          globalPosition={position < 10}
          playerNumber={positionsCurrentQuantity[position as TypePosition] || 0}
          playersMin={positionsMinQuantity[position as TypePosition] || 0}
        />
      ))}
    </SelectorContainer>
  );
};

export default PositionSelector;
