import React, { FC } from "react";
import Box from "./Box";
import { HEIGHT_GRAPH_AREA_USED, WIDTH_BOX } from "./LineDiagram";
import PlayerRatingCircle from "./PlayerRatingCircle";
import PlayerRatingGoal from "./PlayerRatingGoal";
import PlayerRatingStatus from "./PlayerRatingStatus";
interface PlayerRatingProps {
  goals: number;
  ownGoals: number;
  status: number;
  rating: number | null | undefined;
}

const PlayerRating: FC<PlayerRatingProps> = ({
  goals,
  ownGoals,
  rating,
  status,
}) => {
  return (
    <Box
      zIndex={15}
      flexDirection="column"
      position="relative"
      height={HEIGHT_GRAPH_AREA_USED}
      width={WIDTH_BOX}
    >
      <Box
        width={WIDTH_BOX}
        flexDirection="column"
        bottom={((rating || 5) * HEIGHT_GRAPH_AREA_USED) / 10 - 45}
        alignItems="center"
        position="absolute"
      >
        <PlayerRatingGoal mb="2px"  goals={goals} ownGoals={ownGoals} />
        <PlayerRatingCircle rating={rating} />
        <PlayerRatingStatus status={status} />
      </Box>
    </Box>
  );
};

export default PlayerRating;
