import {useEffect, useState} from 'react';
import {ISelectionPlayer} from '../utils/types';
interface ISSelections {
  [key: number]: {[id: string]: ISelectionPlayer};
}
const initialState = {
  1: {},
  2: {},
  3: {},
  4: {},
  5: {},
  6: {},
};
const useSelection = (championshipId: number) => {
  // SELECTION SECTION ----------
  const [selections, setSelections] = useState<ISSelections>(initialState);
  const [budget, setBudget] = useState(0);

  const calculateBudget = (selection: ISelectionPlayer[]) => {
    let budget = 0;
    selection.forEach(element => {
      budget = (element.customQuotation || element.quotation) + budget;
    });
    setBudget(budget);
  };
  const addPlayer = (player: ISelectionPlayer) => {
    setSelections(prev => ({
      ...prev,
      [championshipId]: {...prev[championshipId], [player.id]: player},
    }));
  };
  const removePlayer = (playerId: string) => {
    setSelections(prev => {
      let newSelections = prev[championshipId];
      delete newSelections[playerId];
      return {
        ...prev,
        [championshipId]: {...newSelections},
      };
    });
  };
  const setQuotation = (playerId: string, newQuotation: number) => {
    setSelections(prev => {
      let newSelections = prev;
      delete newSelections[championshipId][playerId].customQuotation;
      const isValid =
        prev[championshipId][playerId] &&
        prev[championshipId][playerId]!.quotation < newQuotation;
      calculateBudget(Object.values(newSelections[championshipId]));
      return isValid
        ? {
            ...prev,
            [championshipId]: {
              ...newSelections[championshipId],
              [playerId]: {
                ...newSelections[championshipId][playerId]!,
                customQuotation: newQuotation,
              },
            },
          }
        : newSelections;
    });
  };

  const listIds = Object.keys(selections[championshipId]);
  const currentSelection = Object.values(selections[championshipId]);

  const selectionNbPlayers = currentSelection.length;
  const selectionGoalKeeper = currentSelection.filter(
    player => player.position === 1,
  );
  const selectionDefender = currentSelection.filter(
    player => player.position === 2,
  );
  const selectionMidfielder = currentSelection.filter(
    player => player.position === 3,
  );
  const selectionStriker = currentSelection.filter(
    player => player.position === 4,
  );
  const positionQuantity = {
    1: selectionGoalKeeper.length,
    2: selectionDefender.length,
    3: selectionMidfielder.length,
    4: selectionStriker.length,
  };

  useEffect(() => {
    calculateBudget(currentSelection);
  }, [currentSelection]);

  // QR CODE SECTION ----------

  return {
    budget,
    selectionNbPlayers,
    selectionGoalKeeper,
    selectionDefender,
    selectionMidfielder,
    selectionStriker,
    currentSelection,
    listIds,
    positionQuantity,
    addPlayer,
    removePlayer,
    setQuotation,
  };
};

export default useSelection;
