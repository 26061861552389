import React, { FC, useState } from "react";
import styled from "styled-components";
import logo from "../assets/images/logo.png";
import useClickFilter from "../hooks/useClickFilter";
import { orderedChampionship } from "../utils/championship";
import Box from "./Box";
import BoxButton from "./BoxButton";
import HeaderChampionship from "./HeaderChampionship";
import HeaderLanguage from "./HeaderLanguge";
import LanguageSelector from "./LanguageSelector";

interface HeaderProps {
  currentChampionship: number;
  onClickChampionShip: (id: number) => void;
  onChangeLanguage: (ln: string) => void;
  linkLogo: string;
  currentLanguage: string;
  closeModalOpened: boolean;
  openCloseModal: () => void;
}
const ContainerHeader = styled(Box)`
  padding: 0 108px;

  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
`;
const Header: FC<HeaderProps> = ({
  currentChampionship,
  onClickChampionShip,
  currentLanguage,
  onChangeLanguage,
  linkLogo,
  closeModalOpened,
  openCloseModal,
}) => {
  const [language, setLanguage] = useState(currentLanguage);
  const championships = orderedChampionship(language);
  const {
    containerRef,
    filterRef,
    isFilterOpen,
    forceClose,
    onClickFilter,
  } = useClickFilter();

  return (
    <ContainerHeader zIndex={10} position="sticky" top="0" bg="primary">
      {closeModalOpened ? (
        <a href={linkLogo}>
          <img src={logo} alt="logo" height="40px" width="103px" />
        </a>
      ) : (
        <BoxButton onClick={openCloseModal}>
          <img src={logo} alt="logo" height="40px" width="103px" />
        </BoxButton>
      )}

      <Box
        maxWidth="950px"
        height={88}
        mx="l"
        alignItems="center"
        justifyContent="space-around"
        flex={1}
      >
        {championships.map((id) => (
          <HeaderChampionship
            key={id}
            championshipId={id}
            focused={id === currentChampionship}
            onClick={() => onClickChampionShip(id)}
          />
        ))}
      </Box>
      <HeaderLanguage
        selectorItem={
          <LanguageSelector
            currentLanguage={language}
            onClickLanguage={(lg: string) => {
              onChangeLanguage(lg);
              setLanguage(lg);
              forceClose();
            }}
          />
        }
        containerRef={containerRef}
        filterRef={filterRef}
        isFilterOpen={isFilterOpen}
        onClickFilter={onClickFilter}
        currentLanguage={language}
      />
    </ContainerHeader>
  );
};

export default Header;
