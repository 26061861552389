import React, { FC } from "react";
import styled from "styled-components";
import Box from "./Box";
import logo from "../assets/images/logo.png";
import Image from "./Image";
import { useTranslation } from "react-i18next";
import Typo from "./Typo";
interface PlayerPresentationProps {
  name: string;
  jersey: string;
  clubName: string;
  position: number;
}
const StyledContainer = styled(Box)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-direction: column;
  align-items: center;
`;
const PlayerPresentation: FC<PlayerPresentationProps> = ({
  clubName,
  position,
  jersey,
  name,
}) => {
  const { t } = useTranslation();
  return (
    <StyledContainer
      bg="white"
      border="1px solid"
      borderColor="bg.dark"
      height="212px"
      pt="xl"
      width="300px"
    >
      <img width={43} height={16} src={logo} alt="log" />
      <Typo mt="m" variant="title" color="font.dark">
        {name}
      </Typo>
      <Typo mb="m" variant="legend" color="font.light">
        {t(`playerPositions.presentation.${position}`)}
      </Typo>
      <Image url={jersey} alt="jersey" size={56} />
      <Typo mt="m" variant="tab">
        {clubName.toUpperCase()}
      </Typo>
    </StyledContainer>
  );
};

export default PlayerPresentation;
