import React, {FC, useContext} from 'react';
import {ThemeContext} from 'styled-components';
import {SpaceProps} from 'styled-system';
import Goal from '../assets/icons/Goal';
import Box from './Box';
interface PlayerMatchGoalProps extends SpaceProps {
  goals: number;
}
const PlayerMatchGoal: FC<PlayerMatchGoalProps> = ({goals, ...props}) => {
  const boxWidth = 12 + (goals - 1) * 8;
  const arrGoals = new Array(goals).fill(null);
  const themeContext = useContext(ThemeContext);
  return (
    <Box alignItems="center" position="relative" width={boxWidth} {...props}>
      {arrGoals.map((_, index) => (
        <Box key={index} position="absolute" left={-4 + 8 * index}>
          <Goal size={20} color={themeContext.colors.font.medium} />
        </Box>
      ))}
    </Box>
  );
};

export default PlayerMatchGoal;
