import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpaceProps } from "styled-system";
import Box from "./Box";
import BoxButton from "./BoxButton";
import CheckBox from "./CheckBox";
import Typo from "./Typo";
interface PositionItemProps extends SpaceProps {
  position: number;
  selected: boolean;
  globalPosition: boolean;
  playerNumber?: number;
  partiallySelected: boolean;
  playersMin?: number;
  onClickPosition: () => void;
}
const PositionItem: FC<PositionItemProps> = ({
  selected,
  position,
  playerNumber,
  playersMin,
  partiallySelected,
  globalPosition,
  onClickPosition,
  ...props
}) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  return (
    <Box
      {...props}
      onClick={onClickPosition}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      bg={selected ? "secondaryTrans.20" : hover ? "bg.light" : "white"}
      justifyContent="space-between"
      alignItems="center"
    >
      <BoxButton ml={globalPosition ? "0" : "20px"} alignItems="center">
        <CheckBox
          checked={partiallySelected || selected}
          isMinus={partiallySelected && !selected}
        />
        <Typo ml="l" display="inline" color="font.medium" variant="label">
          {t(`playerPositions.filter.${position}`)}
        </Typo>
      </BoxButton>
      {globalPosition && (
        <Typo
          fontSize="s"
          fontFamily="book"
          color={(playerNumber || 0) >= (playersMin || 0) ? "primary" : "error"}
        >
          {playerNumber}/{playersMin}
        </Typo>
      )}
    </Box>
  );
};

export default PositionItem;
