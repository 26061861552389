import React, { FC } from "react";
import Box from "./Box";
import Typo from "./Typo";

const ListPlayerSection: FC<{ width: string }> = ({ width, children }) => (
  <Box
    overflow="hidden"
    alignItems="center"
    justifyContent="center"
    width={width}
  >
    <Typo color="font.light" fontSize="s" fontFamily="book">
      {children}
    </Typo>
  </Box>
);

export default ListPlayerSection;
