import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Box from './Box';
import RangeItem from './RangeItem';
import SelectorContainer from './SelectorContainer';
import Typo from './Typo';
export type RangeType = 'use' | 'quotation' | 'goal' | 'price';
export interface RangeSelectorProps {
  min: number;
  max: number;
  step: number;
  values: number[];
  onValuesChange: (values: number[]) => void;
  rangeType: RangeType;
}
const RangeSelector: FC<RangeSelectorProps> = ({
  rangeType,
  max,
  min,
  step,
  values,
  onValuesChange,
}) => {
  const {t} = useTranslation();
  return (
    <SelectorContainer>
      <Box p="l" flex={1} flexDirection="column">
        <Typo variant="legend" textAlign="justify" color="font.medium">
          {t(`filters.range.${rangeType}.desc`)}
        </Typo>

        <Box mt="l" mb="m" justifyContent="center">
          <RangeItem
            step={step}
            values={values}
            onValuesChange={onValuesChange}
            max={max}
            min={min}
          />
        </Box>
        <Box justifyContent="space-between">
          <Box flexDirection="column" alignItems="flex-start">
            <Typo color="font.dark" variant="header">
              {t(`filters.range.${rangeType}.value`, {value: values[0]})}
            </Typo>
            <Typo fontFamily="book" fontSize="s" color="font.medium">
              {t(`filters.range.${rangeType}.label1`)}
            </Typo>
          </Box>
          <Box flexDirection="column" alignItems="flex-end">
            <Typo color="font.dark" variant="header">
              {t(`filters.range.${rangeType}.value`, {value: values[1]})}
            </Typo>
            <Typo fontFamily="book" fontSize="s" color="font.medium">
              {t(
                `filters.range.${rangeType}.${
                  values[1] <= 1 ? 'label1' : 'label2'
                }`,
              )}
            </Typo>
          </Box>
        </Box>
      </Box>
    </SelectorContainer>
  );
};

export default RangeSelector;
