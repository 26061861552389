import { IGetMidfielderStatistics } from "../utils/types";
import Box from "./Box";
import MidfielderDefensiveCardStats from "./MidfielderDefensiveCardStats";
import MidfielderEfficiencyCardStats from "./MidfielderEfficiencyCardStats";
import MidfielderOffensiveCardStats from "./MidfielderOffensiveCardStats";
import MidfielderPassCardStats from "./MidfielderPassCardStats";
import MidfielderPlayerCardStats from "./MidfielderPlayerCardStats";

interface MidfielderStatsTabProps {
  statistics: IGetMidfielderStatistics;
  championshipId: number;
}

export default function MidfielderStatsTab({
    statistics,
    championshipId,
}: MidfielderStatsTabProps) {
  const total = statistics?.championships?.[
    championshipId
  ]?.total;
  
  return (
    <Box flexDirection='column'>
      {Object.keys(total.stats) && (
        <Box mr='m' ml='m' mb='m' display='flex' flex='1' flexDirection='row'>
          <Box display='flex' flex='1' flexDirection='column'>
            <MidfielderPlayerCardStats
              statistics={total.stats}
            />
            <Box mr='s' ml='s' />
            <MidfielderOffensiveCardStats
              statistics={total.stats}
            />
            <Box mr='s' ml='s' />
            <MidfielderPassCardStats
              statistics={total.stats}
            />
          </Box>
          <Box ml='m' display='flex' flex='1' flexDirection='column'>
            <MidfielderEfficiencyCardStats
              statistics={total.stats}
            />
            <Box mr='s' ml='s' />
            <MidfielderDefensiveCardStats
              statistics={total.stats}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
