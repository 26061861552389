import React, { FC, RefObject, useContext } from "react";
import { ThemeContext } from "styled-components";
import Chevron from "../assets/icons/Chevron";
import { getChampionshipImage } from "../utils/championship";
import Box from "./Box";
import BoxButton from "./BoxButton";
import Image from "./Image";
import Typo from "./Typo";
interface HeaderLanguageProps {
  currentLanguage: string;
  containerRef: RefObject<HTMLDivElement>;
  filterRef: RefObject<HTMLDivElement>;
  isFilterOpen: boolean;
  onClickFilter: () => void;
  selectorItem: JSX.Element;
}

const HeaderLanguage: FC<HeaderLanguageProps> = ({
  currentLanguage,
  selectorItem,
  containerRef,
  filterRef,
  isFilterOpen,
  onClickFilter,
}) => {
  const flag = currentLanguage === "fr" ? 10 : currentLanguage === "es" ? 3 : 2;
  const themeContext = useContext(ThemeContext);

  return (
    <Box position="relative">
      <Box ref={containerRef} onClick={onClickFilter}>
        <BoxButton alignItems="center">
          <Typo variant="label" color="white">
            {currentLanguage.toUpperCase()}
          </Typo>
          <Box mx="m">
            <Image alt="country" url={getChampionshipImage(flag)} size={32} />
          </Box>
          <Chevron color={themeContext.colors.white} size={24} />
        </BoxButton>
      </Box>
      {isFilterOpen && (
        <Box
          ref={filterRef}
          zIndex={1}
          position="absolute"
          right="0"
          top={"50px"}
        >
          {selectorItem}
        </Box>
      )}
    </Box>
  );
};

export default HeaderLanguage;
