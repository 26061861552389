import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SpaceProps } from "styled-system";
import { Position } from "../utils/position";
import Box from "./Box";
import Typo from "./Typo";
interface SelectionPositionProps extends SpaceProps {
  position: Position;
  minQuantity: number;
  quantity: number;
}
const SelectionPosition: FC<SelectionPositionProps> = ({
  position,
  minQuantity,
  quantity,
  ...props
}) => {
  const { t } = useTranslation();
  const color = minQuantity > quantity ? "error" : "primary";
  return (
    <Box {...props} justifyContent={"space-between"}>
      <Typo color="font.light" variant="legend">
        {t(`playerPositions.selection.${position}`)}
      </Typo>
      <Typo color={color} variant="legend">
        {t("selection.indicator.players.value", {
          value: `${quantity} /${minQuantity}`,
        })}
      </Typo>
    </Box>
  );
};

export default SelectionPosition;
