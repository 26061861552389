import { IGetStrikerStatistics } from "../utils/types";
import Box from "./Box";
import StrikerDefensiveCardStats from "./StrikerDefensiveCardStats";
import StrikerEfficiencyCardStats from "./StrikerEfficiencyCardStats";
import StrikerOffensiveCardStats from "./StrikerOffensiveCardStats";
import StrikerPassCardStats from "./StrikerPassCardStats";
import StrikerPlayerCardStats from "./StrikerPlayerCardStats";

interface StrikerStatsTabProps {
  statistics: IGetStrikerStatistics;
  championshipId: number;
}

export default function StrikerStatsTab({
    statistics,
    championshipId,
}: StrikerStatsTabProps) {
  const total = statistics?.championships?.[
    championshipId
  ]?.total;

  return (
    <Box flexDirection='column' flexGrow={1} display='flex'>
        {Object.keys(total.stats) && (
          <Box mr='m' ml='m' mb='m' display='flex' flex='1' flexDirection='row'>
            <Box display='flex' flex='1' flexDirection='column'>
              <StrikerPlayerCardStats
                statistics={total.stats}
              />
              <Box mr='s' ml='s' />
              <StrikerOffensiveCardStats
                statistics={total.stats}
              />
              <Box mr='s' ml='s' />
              <StrikerPassCardStats
                statistics={total.stats}
              />
            </Box>
            <Box ml='m' display='flex' flex='1' flexDirection='column'>
              <StrikerEfficiencyCardStats
                statistics={total.stats}
              />
              <Box mr='s' ml='s' />
              <StrikerDefensiveCardStats
                statistics={total.stats}
              />
            </Box>
          </Box>
        )}
    </Box>
  );
};
