import React, { FC } from "react";
import Box from "./Box";
import { HEIGHT_BOX } from "./LineDiagram";
import Typo from "./Typo";

interface LineDiagramLabelProps {
  label: string;
}

const LineDiagramLabelY: FC<LineDiagramLabelProps> = ({ label }) => {
  return (
    <Box height={HEIGHT_BOX} alignItems="center" justifyContent="flex-end">
      <Typo pb="m" pt="s" variant="legend">
        {label}
      </Typo>
      <Box ml="5px" width="8px" borderTop="1px solid" borderColor="bg.medium" />
    </Box>
  );
};

export default LineDiagramLabelY;
