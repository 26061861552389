import React, { FC } from "react";
import Box, { BoxProps } from "./Box";

interface ImageProps extends Omit<BoxProps, 'color'> {
  url: string;
  alt: string;
  size: number;
}

const Image: FC<ImageProps> = ({ url, size, alt, ...props }) => {
  return (
    <Box {...props}>
      <img height={size} width={size} src={url} alt={alt} />
    </Box>
  );
};

export default Image;
