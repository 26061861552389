import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  GOAL_WIDTH,
  POSITION_WIDTH,
  PRICE_WIDTH,
  QUOTATION_WIDTH,
  USE_WIDTH,
} from '../utils/listPlayer';
import Box from './Box';
import BoxButton from './BoxButton';
import CheckBoxJerseyItem from './CheckBoxJerseyItem';
import ListPlayerSection from './ListPlayerSection';
import Typo from './Typo';

const QUOTATION = QUOTATION_WIDTH + 32 + 'px';
const GOAL = GOAL_WIDTH + 32 + 'px';
const POSITION = POSITION_WIDTH + 32 + 'px';
const USE = USE_WIDTH + 32 + 'px';
const PRICE = PRICE_WIDTH + 32 + 'px';

const ButtonInfo = styled(BoxButton)`
  background-color: white;
  align-items: center;
  justify-content: center;
  height: 32px;
  top: 12px;
  left: -120px;
  position: absolute;
  width: 120px;
  border: 1px solid;
  border-color: ${({theme}) => theme.colors.secondary as string};
  &:hover {
    background-color: #e5e9f5;
  }
`;
interface ListPlayerProps {
  selected: boolean;
  jerseyUrl: string;
  name: string;
  quotation: string;
  goal: number;
  position: number;
  use: number;
  price: number;
  onClickPlayer: () => void;
  onClickInformation: () => void;
}
const ListPlayer: FC<ListPlayerProps> = ({
  selected,
  jerseyUrl,
  name,
  use,
  goal,
  position,
  price,
  quotation,
  onClickPlayer,
  onClickInformation,
}) => {
  const [hover, setHover] = useState(false);

  const {t} = useTranslation();

  return (
    <Box
      flex={1}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      bg={selected ? 'secondaryTrans.20' : hover ? 'bg.light' : 'white'}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      pl="l"
      borderBottom="1px solid"
      borderColor="bg.light">
      <CheckBoxJerseyItem
        onClick={onClickPlayer}
        name={name}
        jersey={jerseyUrl}
        selected={selected}
      />
      <BoxButton height="56px" flex={1} onClick={onClickPlayer} />

      <Box height="56px" position="relative">
        {hover && (
          <ButtonInfo onClick={onClickInformation} borderRadius="xs">
            <Typo color="secondary" variant="tab">
              {t('listPlayers.button')}
            </Typo>
          </ButtonInfo>
        )}
        <BoxButton alignItems="center" onClick={onClickPlayer}>
          <ListPlayerSection width={QUOTATION}>
            {quotation || '-'}
          </ListPlayerSection>
          <ListPlayerSection width={GOAL}>
            {goal === 0 || goal ? goal : '-'}
          </ListPlayerSection>
          <ListPlayerSection width={POSITION}>
            {t(`playerPositions.list.${position}`)}
          </ListPlayerSection>
          <ListPlayerSection width={USE}>
            {use ? `${use}%` : '-'}
          </ListPlayerSection>
          <Box
            alignItems="center"
            justifyContent="flex-end"
            pr="l"
            width={PRICE}>
            <Typo color="font.light" fontSize="s" fontFamily="book">
              {price}
            </Typo>
          </Box>
        </BoxButton>
      </Box>
    </Box>
  );
};

export default ListPlayer;
