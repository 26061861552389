import React, { FC } from "react";
import ChampionshipItem from "./ChampionshipItem";
import SelectorContainer from "./SelectorContainer";
interface ChampionshipSelectorProps {
  championships: number[];
  currentChampionship: number;
  onClickChampionship: (championship: number) => void;
}
const ChampionshipSelector: FC<ChampionshipSelectorProps> = ({
  onClickChampionship,
  championships,
  currentChampionship,
}) => {
  return (
    <SelectorContainer>
      {championships.map((value) => (
        <ChampionshipItem
          selected={value === currentChampionship}
          championship={value}
          onClickChampionship={() => onClickChampionship(value)}
        />
      ))}
    </SelectorContainer>
  );
};

export default ChampionshipSelector;
