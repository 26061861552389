import { CSSProperties } from "styled-components";
import Box from "./Box";
import PlayerStatKey, { PlayerStatKeyProps } from "./PlayerStatKey";
import Typo from "./Typo";

interface PlayerStatisticsBlockProps {
  title: string;
  data: Array<PlayerStatKeyProps>;
  style?: CSSProperties;
};

export default function PlayerStatisticsBlock({
    title,
    data,
}: PlayerStatisticsBlockProps): JSX.Element {
  return (
        <Box alignItems='center' flexDirection='column' width='100%'>
            <Typo mt='l' mb='m' variant="tab">{title}</Typo>
            {data.map(({stat, value, subValue}, index) => (
                <Box key={index} width='100%'>
                    <PlayerStatKey stat={stat} value={value ?? 0} subValue={subValue} />
                </Box>
            ))}
        </Box>
    );
};
