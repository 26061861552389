/**
 * Not definitive
 */

// used to control default widths/height of components
const sizes = [12, 24, 48, 56];

const sizeValues = {
  buttonHeight: sizes[2],
  bigButtonHeight: sizes[3],
  logo: sizes[3],
  headerHeight: sizes[2],
};
export default sizeValues;
