import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import Minus from "../assets/icons/Minus";
import Add from "../assets/icons/Add";
import BoxButton from "./BoxButton";
import Typo from "./Typo";
import Box from "./Box";
import { useTranslation } from "react-i18next";
interface PlayerSelectionButtonProps {
  isSelected: boolean;
  onPress: () => void;
}

const PlayerSelectionButton: FC<PlayerSelectionButtonProps> = ({
  isSelected,
  onPress,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <BoxButton
      onClick={onPress}
      px="22px"
      py="10px"
      border="1px solid"
      borderRadius="xl"
      alignItems="center"
      borderColor={isSelected ? "error" : "secondary"}
      bg={isSelected ? "white" : "secondary"}
    >
      <Box
        alignItems="center"
        width="16px"
        height="16px"
        borderRadius="16px"
        bg={isSelected ? "error" : "white"}
      >
        {isSelected ? (
          <Minus size={24} color={themeContext.colors.white} />
        ) : (
          <Add size={24} color={themeContext.colors.secondary} />
        )}
      </Box>
      <Typo ml="m" variant="label" color={isSelected ? "error" : "white"}>
        {t(`playerInfo.buttons.${isSelected ? "remove" : "add"}`)}
      </Typo>
    </BoxButton>
  );
};

export default PlayerSelectionButton;
