import Box from "./Box";
import Image from "./Image";
import Typo from "./Typo";

interface EmptyStateProps {
  image: string;
  altImage?: string;
  title: string;
  subtitle: string;
  imageSize?: number;
}

export default function EmptyState({
    image,
    altImage = '',
    title,
    subtitle,
    imageSize = 140,
}: EmptyStateProps) {
  return (
    <Box flexDirection='column' mt='m' mb='m' maxWidth='528px' alignItems='center' flex={1} minHeight={'428px'} justifyContent='center'>
      <Image mb='l' size={imageSize} url={image} alt={altImage} borderRadius={imageSize/2} overflow='hidden' />
      <Typo variant='header'>{title}</Typo>
      <Typo variant='legend' pt={'m'} pb={'m'} mt={'m'} mb={'xl'} textAlign='center' color={'font.medium'}>{subtitle}</Typo>
    </Box>
  );
};
