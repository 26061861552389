import { PlayerStatKeyProps } from './PlayerStatKey';
import { IGoalKeeperStats } from '../utils/types';
import { useTranslation } from 'react-i18next';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';

interface GoalKeeperPlayerCardStatsProps {
  statistics: IGoalKeeperStats;
}

export default function GoalKeeperPlayerCardStats({
    statistics: {
        totalStartedMatches,
        totalPlayedMatches,
        totalYellowCard,
        totalRedCard,
      },
}: GoalKeeperPlayerCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
      stat: 'totalStartedMatches',
      value: totalStartedMatches,
      subValue:
        totalPlayedMatches === 0
          ? totalPlayedMatches
          : totalPlayedMatches - totalStartedMatches,
    },
    {
        stat: 'totalYellowCard',
      value: totalYellowCard,
    },
    {
        stat: 'totalRedCard',
      value: totalRedCard,
    },
  ];

  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.playerCard')} data={data} />
  )
};
