import {useMemo} from 'react';
import {useChampionshipClub} from '../api/useChampionshipClub';
import i18n from '../i18n';
import {
  IChampionships,
  IMatch,
  ITotal,
  OtherPositionStats,
} from '../utils/types';
import Box from './Box';
import PlayerMatch from './PlayerMatch';
import {format, parseISO, compareDesc} from 'date-fns';
import {isEmpty} from 'lodash';
import {hasEnoughDataToDisplay} from '../utils/hasEnoughDataToDisplay';
import EmptyState from './EmptyState';
import {useTranslation} from 'react-i18next';
import DefaultLigue from '../assets/images/default_ligue.jpg';
import Bresil from '../assets/images/bresil.jpg';

type MatchesTabProps = {
  currentChampionshipStats:
    | IChampionships<OtherPositionStats, ITotal<OtherPositionStats>>
    | undefined;
  availableSeasons: string[] | undefined;
};

type CustomIMatch = IMatch & {
  newDate: string;
};

export default function MatchesTab({
  currentChampionshipStats,
  availableSeasons = [],
}: MatchesTabProps): JSX.Element {
  const {t} = useTranslation();

  const {data: dataClub} = useChampionshipClub();

  const lnKey = useMemo(
    () =>
      i18n.language === 'en'
        ? 'en-GB'
        : i18n.language === 'es'
        ? 'es-ES'
        : 'fr-FR',
    [],
  );

  const matches: CustomIMatch[] = useMemo(() => {
    return (
      currentChampionshipStats?.total?.matches
        ?.sort((a: IMatch, b: IMatch) =>
          compareDesc(new Date(a.date), new Date(b.date)),
        )
        ?.map((m: IMatch) => {
          return {
            newDate: format(parseISO(m.date), 'dd/MM/yy'),
            ...m,
          };
        }) ?? []
    );
  }, [currentChampionshipStats]);

  const isStatisticsEmpty = useMemo(
    () =>
      isEmpty(currentChampionshipStats?.total?.stats) ||
      currentChampionshipStats?.total?.stats?.totalPlayedMatches === 0,
    [currentChampionshipStats],
  );

  if (availableSeasons?.length === 1 && isStatisticsEmpty) {
    return (
      <EmptyState
        image={Bresil}
        title={t('PlayerStatistics.emptyStats.2.title')}
        subtitle={t('PlayerStatistics.emptyStats.2.subtitle')}
      />
    );
  }

  if (
    isStatisticsEmpty ||
    (currentChampionshipStats &&
      !hasEnoughDataToDisplay(currentChampionshipStats?.total?.matches, 1))
  ) {
    return (
      <EmptyState
        image={DefaultLigue}
        title={t('PlayerStatistics.emptyStats.1.title')}
        subtitle={t('PlayerStatistics.emptyStats.1.subtitle')}
      />
    );
  }

  return (
    <Box flexDirection="column" mt="m" mb="m" width="100%">
      {matches?.map((match: CustomIMatch, index) => {
        const away = dataClub?.championshipClubs[match?.away?.clubId];
        const home = dataClub?.championshipClubs[match?.home?.clubId];
        return (
          <PlayerMatch
            key={index}
            ml="m"
            mr="m"
            mb={1}
            away={{
              score: match?.away?.score ?? 0,
              name: away?.name[lnKey] ?? '',
              jersey: away?.defaultJerseyUrl ?? '',
            }}
            home={{
              score: match?.home?.score ?? 0,
              name: home?.name[lnKey] ?? '',
              jersey: home?.defaultJerseyUrl ?? '',
            }}
            isPlayerHome
            playerGoals={match?.playerPerformance?.goals}
            playerRating={match?.playerPerformance?.rating}
            gameDate={match?.newDate}
            weekNumber={match?.gameWeekNumber}
          />
        );
      })}
    </Box>
  );
}
