import React, { FC } from "react";
import Box from "./Box";
import Image from "./Image";
import PlayerMatchGoal from "./PlayerMatchGoal";
import PlayerRatingCircle from "./PlayerRatingCircle";
import Typo from "./Typo";
interface PlayerMatchTeamProps {
  name: string;
  jersey: string;
  isTeamPlayer: boolean;
  isHome: boolean;
  playerRating: number | null | undefined;
  playerGoals: number | undefined;
}
const PlayerMatchTeam: FC<PlayerMatchTeamProps> = ({
  jersey,
  name,
  isTeamPlayer,
  isHome,
  playerRating,
  playerGoals,
}) => {
  return (
    <Box
      flex={1}
      alignItems="center"
      flexDirection={isHome ? "row" : "row-reverse"}
      justifyContent="space-between"
    >
      <Box alignItems="center" flexDirection={isHome ? "row" : "row-reverse"}>
        <Image alt="jersey" size={32} url={jersey} />
        <Typo color="font.dark" variant="label" mx="m">
          {name}
        </Typo>
      </Box>
      {isTeamPlayer && (
        <Box>
          <PlayerRatingCircle rating={playerRating} size={"24px"} />
          {Boolean(playerGoals) && (
            <PlayerMatchGoal ml="m" goals={playerGoals!} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default PlayerMatchTeam;
