import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Box from "./Box";
import Typo from "./Typo";
interface PlayerMatchDateProps {
  weekNumber: number;
  gameDate: string;
}
const PlayerMatchDate: FC<PlayerMatchDateProps> = ({
  weekNumber,
  gameDate,
}) => {
  const { t } = useTranslation();
  return (
    <Box justifyContent="center">
      <Typo color="font.dark" fontFamily="medium" fontSize="xs">
        {t("PlayerStatistics.lineDiagram.tickFormat", { tick: weekNumber })}
      </Typo>
      <Typo variant="legend" color="font.medium" ml="m">
        {gameDate}
      </Typo>
    </Box>
  );
};

export default PlayerMatchDate;
