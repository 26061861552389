import React, { FC, useEffect, useRef, useState } from "react";
import Arrow from "../assets/icons/Arrow";
import Box from "./Box";
import BoxButton from "./BoxButton";
import LineBox from "./LineBox";
import LineDiagramLabelX from "./LineDiagramLabelX";
import LineDiagramLabelY from "./LineDiagramLabelY";
import Typo from "./Typo";

export const WIDTH_BOX = 61;
export const HEIGHT_GRAPH = 200;
export const HEIGHT_GRAPH_STAT_AREA = HEIGHT_GRAPH - 36;
export const HEIGHT_GRAPH_AREA_USED = HEIGHT_GRAPH - 36 - 70;

export const HEIGHT_BOX = 100 / 5;

interface pointData {
  y: number;
  label: string;
}
interface LineDiagramProps {
  data: pointData[];
  color?: string;
  titleDiagram: string;
  axisYLabels?: string[];
  isLabelReverse?: boolean;
  scrollNumber?: number;
}

const LineDiagram: FC<LineDiagramProps> = ({
  data,
  titleDiagram,
  color,
  isLabelReverse = false,
  children,
  axisYLabels,
  scrollNumber = 5,
}) => {
  const diagramRef = useRef<HTMLDivElement>(null);
  const [isOverHidden, setIsOverHidden] = useState(true);
  const [isArrowLeftDisplayed, setIsArrowLeftDisplayed] = useState(true);
  const [isArrowRightDisplayed, setIsArrowRightDisplayed] = useState(false);
  const Ys = data.map((value) => value.y * HEIGHT_GRAPH_AREA_USED);

  useEffect(() => {
    if (diagramRef.current) {
      diagramRef.current.scroll({ left: WIDTH_BOX * data.length });
      if (WIDTH_BOX * data.length < 480) {
        setIsArrowLeftDisplayed(false);
        setIsArrowRightDisplayed(false);
        setIsOverHidden(false);
      }
    }
  }, [diagramRef, data]);

  const onPressLeft = () => {
    if (diagramRef.current) {
      diagramRef.current.scroll({
        behavior: "smooth",
        left: diagramRef.current.scrollLeft - WIDTH_BOX * scrollNumber,
      });
    }
  };

  const onPressRight = () => {
    if (diagramRef.current) {
      diagramRef.current.scroll({
        behavior: "smooth",
        left: diagramRef.current.scrollLeft + WIDTH_BOX * scrollNumber,
      });
    }
  };

  const onScroll = () => {
    if (diagramRef.current) {
      if (diagramRef.current.scrollLeft > 0) {
        setIsArrowLeftDisplayed(true);
      } else {
        setIsArrowLeftDisplayed(false);
      }
      if (diagramRef.current.scrollLeft < WIDTH_BOX * data.length - 480) {
        setIsArrowRightDisplayed(true);
      } else {
        setIsArrowRightDisplayed(false);
      }
    }
  };

  return (
    <Box
      overflow="hidden"
      borderRadius="s"
      bg="white"
      height="212px"
      position="relative"
      width="512px"
    >
      <Box zIndex={2} bg="white" left="32px" top="8px" position="absolute">
        <Typo variant="legend">{titleDiagram}</Typo>
      </Box>
      {/* ARROW / AXIS Y */}
      <Box
        position="sticky"
        width="32px"
        borderTopLeftRadius="s"
        overflow="hidden"
        borderBottomLeftRadius="s"
        height="212px"
        left="0"
        top="0"
      >
        {isArrowLeftDisplayed && (
          <BoxButton
            zIndex={1}
            position="absolute"
            left="0"
            top="0"
            bg="#00000052"
            onClick={onPressLeft}
            width="31px"
            height="212px"
            alignItems="center"
            justifyContent="center"
          >
            <Arrow direction="left" color="white" size={32} />
          </BoxButton>
        )}
        <Box
          flex={1}
          mt="30px"
          mb="35px"
          position="relative"
          borderRight="1px solid"
          borderColor="bg.medium"
          flexDirection="column"
        >
          {axisYLabels && (
            <>
              <LineDiagramLabelY label={axisYLabels[5]} />
              <LineDiagramLabelY label={axisYLabels[4]} />
              <LineDiagramLabelY label={axisYLabels[3]} />
              <LineDiagramLabelY label={axisYLabels[2]} />
              <LineDiagramLabelY label={axisYLabels[1]} />
              <LineDiagramLabelY label={axisYLabels[0]} />
            </>
          )}
        </Box>
      </Box>
      {/* ARROW RIGHT */}
      <Box
        position="absolute"
        overflow="hidden"
        width="32px"
        height="212px"
        right="0"
        top="0"
      >
        {isOverHidden && isArrowRightDisplayed && (
          <BoxButton
            zIndex={100}
            position="absolute"
            left="0"
            top="0"
            bg="#00000052"
            onClick={onPressRight}
            width="32px"
            height="212px"
            alignItems="center"
            justifyContent="center"
          >
            <Arrow direction="right" color="white" size={32} />
          </BoxButton>
        )}
      </Box>
      <Box
        height="212px"
        pr="12px"
        py="6px"
        width="480px"
        overflow="scroll"
        onScroll={onScroll}
        ref={diagramRef}
      >
        <Box
          flexDirection="column"
          position="relative"
          height={HEIGHT_GRAPH}
          pt="35px"
          width={WIDTH_BOX * data.length}
          zIndex={1}
        >
          {/* DIAGRAM */}
          <Box
            position="absolute"
            height={HEIGHT_GRAPH_STAT_AREA}
            width={WIDTH_BOX * data.length}
            bottom={0}
          >
            {children}
          </Box>

          {/* AXIS X */}
          <Box
            zIndex={10}
            position="relative"
            height={HEIGHT_GRAPH_STAT_AREA}
            width={WIDTH_BOX * data.length}
          >
            {Ys.map((value, index) => (
              <Box key={index}>
                {index < data.length - 1 && (
                  <LineBox
                    color={color}
                    y1={value}
                    y2={Ys[index + 1]}
                    index={index}
                  />
                )}
              </Box>
            ))}
          </Box>

          <Box>
            {data.map((value, index) => (
              <LineDiagramLabelX
                isLabelReverse={isLabelReverse}
                key={index}
                label={value.label}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LineDiagram;
