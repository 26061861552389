import {useMutation, useQuery, UseQueryOptions} from 'react-query';
import {apiWrapper, axiosConfig} from './config';
export interface IPayloadDraft {
  championshipId: number;
  selectedPlayers: {[key: string]: number};
}
interface IResultDraft {
  draftTeam: {id: string};
}

const postDraftTeam = (payload: IPayloadDraft) => {
  return apiWrapper(
    axiosConfig.post<IResultDraft>(`/api/data/draft-team`, payload),
  );
};

const getDraftTeam = (code: string) => {
  return apiWrapper(
    axiosConfig.get<{draftTeam: IPayloadDraft}>(
      `/api/draft-team/mpg_draft_team_${code}`,
    ),
  );
};

export const usePostDraftTeam = () =>
  useMutation(({payload}: {payload: IPayloadDraft}) => postDraftTeam(payload));

export const useGetDraftTeam = (
  code: string,
  options: UseQueryOptions<{draftTeam: IPayloadDraft}>,
) => {
  return useQuery<{draftTeam: IPayloadDraft}>(
    ['draftTeam', code],
    () => getDraftTeam(code),
    options,
  );
};
