import React from "react";
import { PlayerStatKeyProps } from "./PlayerStatKey";
import roundWithDecimal from "../utils/roundWithDecimal";
import { OtherPositionStats } from "../utils/types";
import { useTranslation } from "react-i18next";
import PlayerStatisticsBlock from "./PlayerStatisticsBlock";

interface DefenderOffensiveCardStatsProps {
  statistics: OtherPositionStats;
}

export default function DefenderOffensiveCardStats({
    statistics: {
        totalPlayedMatches,
        totalScoringAtt,
        totalShotOffTarget,
        totalMinutesPlayed,
        totalGoals,
        totalBigChanceCreated,
        totalBigChanceMissed,
      },
}: DefenderOffensiveCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
      stat: 'shotOnTarget',
      value: totalPlayedMatches
        ? roundWithDecimal(
            (totalScoringAtt - totalShotOffTarget) / totalPlayedMatches,
            1,
          )
        : 0,
      subValue: `${
        totalScoringAtt
          ? roundWithDecimal(
              ((totalScoringAtt - totalShotOffTarget) / totalScoringAtt) * 100,
            )
          : 0
      }%`,
    },
    {
        stat: 'goalFrequency',
      value: totalGoals
        ? roundWithDecimal(totalMinutesPlayed / totalGoals, 1)
        : 0,
    },
    {
        stat: 'goalsByMatch',
      value: totalMinutesPlayed
        ? roundWithDecimal(totalGoals / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'shotByMatch',
      value: totalMinutesPlayed
        ? roundWithDecimal(totalScoringAtt / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'bigChanceCreated',
      value: totalBigChanceCreated,
    },
    {
        stat: 'bigChanceMissed',
      value: totalBigChanceMissed,
    },
  ];

  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.offensiveCard')} data={data} />
  )
};
