import React, { FC } from "react";
import { SpaceProps } from "styled-system";
import Box from "./Box";
import PlayerMatchBoxScore from "./PlayerMatchBoxScore";
import PlayerMatchDate from "./PlayerMatchDate";
import PlayerMatchTeam from "./PlayerMatchTeam";

interface ITeam {
  name: string;
  jersey: string;
  score: number;
}
interface PlayerMatchProps extends SpaceProps {
  weekNumber: number;
  gameDate: string;
  home: ITeam;
  away: ITeam;
  playerRating: number | null | undefined;
  playerGoals: number | undefined;
  isPlayerHome: boolean;
}

const PlayerMatch: FC<PlayerMatchProps> = ({
  gameDate,
  weekNumber,
  home,
  isPlayerHome,
  playerGoals,
  playerRating,
  away,
  ...props
}) => {
  return (
    <Box
      {...props}
      borderRadius="s"
      bg="white"
      flex={1}
      px="l"
      py="m"
      flexDirection="column"
    >
      <PlayerMatchDate gameDate={gameDate} weekNumber={weekNumber} />
      <Box mt="m" justifyContent="space-between">
        <PlayerMatchTeam
          playerGoals={playerGoals}
          playerRating={playerRating}
          isTeamPlayer={isPlayerHome}
          isHome
          jersey={home.jersey}
          name={home.name}
        />
        <PlayerMatchBoxScore home={home.score} away={away.score} />
        <PlayerMatchTeam
          playerGoals={playerGoals}
          playerRating={playerRating}
          isTeamPlayer={!isPlayerHome}
          isHome={false}
          jersey={away.jersey}
          name={away.name}
        />
      </Box>
    </Box>
  );
};

export default PlayerMatch;
