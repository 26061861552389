import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BoxButton from "./BoxButton";
import Typo from "./Typo";

interface SelectionButtonProps {
  onClick: () => void;
  disabled: boolean;
}
const StyledButton = styled(BoxButton)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryTrans[50]};
  }
  &:disabled {
    background-color: #d8dae8;
  }
`;

const SelectionButton: FC<SelectionButtonProps> = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      borderRadius="xs"
      bg={"secondary"}
      flex={1}
      p="14px"
      alignItems="center"
      justifyContent="center"
    >
      <Typo color="white" variant="section">
        {t("selection.button")}
      </Typo>
    </StyledButton>
  );
};

export default SelectionButton;
