import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { SpaceProps } from "styled-system";
import Close from "../assets/icons/Close";
import Box from "./Box";

type ModalProps = {
  isVisible: boolean;
  hide?: () => void;
  children: React.ReactNode;
} & SpaceProps;

export default function Modal({
  isVisible,
  hide,
  children,
  ...props
}: ModalProps): JSX.Element {
  const themeContext = useContext(ThemeContext);
  return isVisible ? (
    <React.Fragment>
      <Overlay>
        <Wrapper>
          <Container>
            {hide && (
              <CloseContainer>
                <CloseButton onClick={hide}>
                  <Close size={24} color={themeContext.colors.font.dark} />
                </CloseButton>
              </CloseContainer>
            )}
            <ChildrenContainer {...props}>{children}</ChildrenContainer>
          </Container>
        </Wrapper>
      </Overlay>
    </React.Fragment>
  ) : (
    <></>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #000000a3;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  z-index: 100;
  background: #fff;
  position: relative;
  flex-direction: row;
  border-radius: 16px;
  background-color: transparent;
`;

const CloseContainer = styled.div`
  z-index: 100;
  position: relative;
  background-color: transparent;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: -32px;
`;

const CloseButton = styled.div`
  color: #000;
  border: none;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ChildrenContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
`;
