import React from 'react';
import { PlayerStatKeyProps } from './PlayerStatKey';
import roundWithDecimal from '../utils/roundWithDecimal';
import { OtherPositionStats } from '../utils/types';
import { useTranslation } from 'react-i18next';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';

interface DefenderPassCardStatsProps {
  statistics: OtherPositionStats;
}

export default function DefenderPassCardStats({
    statistics: {
        totalPlayedMatches,
        totalAccuratePass,
        totalPasses,
        totalPassFwdZone,
        totalAccuratePassFwdZone,
        totalAccuratePassBackZone,
        totalPassBackZone,
        totalAccurateLongPass,
        totalLongPass,
        totalAccurateCross,
      },
}: DefenderPassCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
        stat: 'totalAccuratePass',
      value: totalPlayedMatches
        ? roundWithDecimal(totalAccuratePass / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalPasses
          ? roundWithDecimal((totalAccuratePass / totalPasses) * 100)
          : 0
      }%`,
    },
    {
        stat: 'totalPassFwdZone',
      value: totalPlayedMatches
        ? roundWithDecimal(totalPassFwdZone / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalPassFwdZone
          ? roundWithDecimal(
              (totalAccuratePassFwdZone / totalPassFwdZone) * 100,
            )
          : 0
      }%`,
    },
    {
        stat: 'totalAccuratePassBackZone',
      value: totalPlayedMatches
        ? roundWithDecimal(totalAccuratePassBackZone / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalPassBackZone
          ? roundWithDecimal(
              (totalAccuratePassBackZone / totalPassBackZone) * 100,
            )
          : 0
      }%`,
    },
    {
        stat: 'totalAccurateLongPass',
      value: totalPlayedMatches
        ? roundWithDecimal(totalAccurateLongPass / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalLongPass
          ? roundWithDecimal((totalAccurateLongPass / totalLongPass) * 100)
          : 0
      }%`,
    },
    {
        stat: 'totalAccurateCross',
      value: totalPlayedMatches
        ? roundWithDecimal(totalAccurateCross / totalPlayedMatches, 1)
        : 0,
    },
  ];
  
  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.passCard')} data={data} />
  )
};
