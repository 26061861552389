import { useQuery, UseQueryOptions } from "react-query";
import { IGetPlayerStatistics } from "../utils/types";
import { ApiError, apiWrapper, axiosConfig } from "./config";

const PLAYER_STATS_QUERYKEY = 'PLAYER_STATS_QUERYKEY';

const getPlayerStatistics = (championshipPlayerId: string, season: number) => {
    return apiWrapper(axiosConfig.get<IGetPlayerStatistics>(`/api/data/championship-player-stats/${championshipPlayerId}/${season}`));
}

export const usePlayerStatistics = (
    championshipPlayerId: string,
    season: number,
    options?: UseQueryOptions<IGetPlayerStatistics, ApiError>,
  ) =>
    useQuery<IGetPlayerStatistics, ApiError>(
      [PLAYER_STATS_QUERYKEY, championshipPlayerId, season],
      () => getPlayerStatistics(championshipPlayerId, season),
      options,
    );