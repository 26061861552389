import React, {FC, useContext, useRef, useState} from 'react';
import styled, {ThemeContext} from 'styled-components';
import Delete from '../assets/icons/Delete';
import Box, {BoxHover} from './Box';
import Image from './Image';
import Typo from './Typo';
interface SelectionPlayerProps {
  name: string;
  jersey: string;
  price: number;
  customPrice: number | undefined;
  onCustomPriceChange: (id: string, newQuotation: number) => void;
  onDelete: () => void;
  id: string;
}

const SelectionInput = styled.input<{borderFocus: string}>`
  display: flex;
  -moz-appearance: textfield;
  outline: none;
  border: 1px solid ${({theme}) => theme.colors.bg.dark};
  border-radius: 4px;
  width: 38px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  text-align: center;
  font-size: 15px;
  font-family: ${({theme}) => theme.fonts.medium};
  color: ${({theme}) => theme.colors.font.dark};
  background: white;
  &:hover {
    border-color: ${({theme}) => theme.colors.secondary};
  }
  &::placeholder {
    font-family: ${({theme}) => theme.fonts.book};
    color: ${({theme}) => theme.colors.font.light};
  }
  &:focus {
    border-color: ${({borderFocus}) => borderFocus};
  }
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const DeleteButton = styled(BoxHover)`
  &:hover {
    background: ${({theme}) => theme.colors.secondaryTrans[20]};
  }
`;

const SelectionPlayer: FC<SelectionPlayerProps> = ({
  name,
  jersey,
  id,
  price,
  onDelete,
  customPrice,
  onCustomPriceChange,
}) => {
  const themeContext = useContext(ThemeContext);
  const [value, setValue] = useState<string | undefined>();
  const refInput = useRef<HTMLInputElement>(null);

  const handleSubmit = (value: number) => {
    if (!Boolean(value) || value < price) {
      setValue('');
      if (refInput.current) {
        refInput.current.value = '';
      }
    }
  };
  return (
    <Box my="m" justifyContent="space-between">
      <Box alignItems="center">
        <Image alt="jersey" url={jersey} size={24} />
        <Typo ml="m" variant="paragraph" color="font.medium">
          {name}
        </Typo>
      </Box>
      <Box alignItems="center">
        <Typo mr="l" variant="paragraph" color="font.medium">
          {price}
        </Typo>

        <SelectionInput
          ref={refInput}
          borderFocus={
            !Boolean(value) || parseInt(value || '') > price
              ? themeContext.colors.secondary
              : themeContext.colors.error
          }
          onBlur={() => handleSubmit(parseInt(value || ''))}
          value={value || customPrice || ''}
          onChange={e => {
            setValue(e.target.value);
            onCustomPriceChange(id, parseInt(e.target.value));
          }}
          type="number"
          placeholder={price + ''}
        />
        <DeleteButton
          height="40px"
          width="40px"
          borderRadius="s"
          alignItems="center"
          justifyContent="center"
          onClick={onDelete}>
          <Delete size={24} color={themeContext.colors.secondary} />
        </DeleteButton>
      </Box>
    </Box>
  );
};

export default SelectionPlayer;
