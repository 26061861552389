import React, { FC } from "react";
import Check from "../assets/icons/Check";
import Minus from "../assets/icons/Minus";
import Box from "./Box";

interface CheckBoxProps {
  checked: boolean;
  isRadio?: boolean;
  isMinus?: boolean;
}

const CheckBox: FC<CheckBoxProps> = ({
  checked,
  isRadio = false,
  isMinus = false,
}) => {
  return (
    <Box
      width="20px"
      borderRadius={isRadio ? "20px" : "xs"}
      height="20px"
      border="1px solid"
      borderColor="secondary"
      bg={checked ? "secondary" : "white"}
      alignItems="center"
      justifyContent="center"
    >
      {!isMinus ? (
        <Check size={24} color="white" />
      ) : (
        <Minus color={"white"} size={24} />
      )}
    </Box>
  );
};

export default CheckBox;
