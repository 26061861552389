import {AxiosError} from 'axios';
import _ from 'lodash';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {ThemeContext} from 'styled-components';
import {useChampionshipClub} from '../api/useChampionshipClub';
import {useChampionshipSettings} from '../api/useChampionshipSettings';
import {useGetDraftTeam} from '../api/useDraftTeam';
import {usePlayerSeasons} from '../api/usePlayerSeasons';
import {usePlayersPool} from '../api/usePlayersPool';
import {usePlayerStatistics} from '../api/usePlayerStatistics';
import Box from '../components/Box';
import Code from '../components/Code';
import Filter from '../components/Filter';
import Header from '../components/Header';
import ListPlayers from '../components/List';
import Selection from '../components/Selection';
import useFilters from '../hooks/useFilters';
import useOrderBy from '../hooks/useOrderBy';
import useQRCode from '../hooks/useQrCode';
import useSelection from '../hooks/useSelection';
import useWindowDimensions from '../hooks/useWindowDimensions';
import i18n from '../i18n';
import {ISelectionPlayer} from '../utils/types';
import CloseModal from './CloseModal';
import PlayerStatisticsModal from './PlayerStatisticsModal';
import QRCodeModal from './QRCodeModal';

const Mercato: FC = () => {
  const {t} = useTranslation();
  const themeContext = useContext(ThemeContext);

  const refFilter = useRef<HTMLDivElement>(null);

  const [championshipId, setChampionshipId] = useState(
    i18n.language === 'es' ? 3 : i18n.language === 'en' ? 2 : 1,
  );

  const {data: dataSettings} = useChampionshipSettings();

  const {data: dataClubs} = useChampionshipClub();

  const currentSeason = dataSettings?.championships[championshipId]?.season;
  const firstSeason =
    dataSettings?.championships[championshipId]?.firstSeason || currentSeason;

  const [season, setSeason] = useState<number | undefined>(currentSeason);
  useEffect(() => {
    setSeason(currentSeason);
  }, [currentSeason, championshipId]);

  const possibleSeasons = Array.from(
    {
      length: 1 + (currentSeason || 0) - (firstSeason || 0),
    },
    (_, i) => i + (firstSeason || 0),
  );

  const {data: dataPlayersPool, status: playersStatus} = usePlayersPool(
    championshipId,
    season,
    {
      enabled: Boolean(dataSettings),
    },
  );

  const {
    listIds,
    addPlayer,
    removePlayer,
    budget,
    selectionNbPlayers,
    setQuotation,
    currentSelection,
    selectionMidfielder,
    selectionStriker,
    selectionDefender,
    positionQuantity,
    selectionGoalKeeper,
  } = useSelection(championshipId);

  const {qrCode, generateCode, closeModal, onClickCopy} = useQRCode(
    championshipId,
    currentSelection,
  );

  const listPlayers = useMemo(
    () =>
      dataPlayersPool?.poolPlayers.map(player => ({
        ...player,
        selected: listIds.includes(player.id),
        jersey: dataClubs?.championshipClubs[player.clubId]?.defaultJerseyUrl!,
      })) || [],
    [dataClubs, dataPlayersPool, listIds],
  );
  const maxQuotation = Math.max(...listPlayers.map(player => player.quotation));
  const maxGoal = Math.max(
    ...listPlayers.map(player => player.stats.totalGoals || 0),
  );

  const [code, setCode] = useState('');
  const [enabledCode, setEnabledCode] = useState(false);

  useGetDraftTeam(code, {
    enabled: enabledCode,
    onError: e => {
      toast(t('code.error.draftTeamNotFound'), {
        progressStyle: {background: themeContext.colors.error},
      });
    },
    onSuccess: data => {
      if (data.draftTeam.championshipId !== championshipId) {
        toast(t('code.error.wrongChampionship'), {
          progressStyle: {background: themeContext.colors.error},
        });
      } else {
        Object.keys(data.draftTeam.selectedPlayers).forEach(player => {
          if (listPlayers.find(poolPlayer => poolPlayer.id === player)) {
            const playerFind = listPlayers.find(
              poolPlayer => poolPlayer.id === player,
            )!;
            const selectionPlayer = {
              name: (playerFind.firstName || '') + ' ' + playerFind.lastName,
              id: playerFind.id,
              position: playerFind.position,
              quotation: playerFind.quotation,
              jersey: playerFind.jersey,
            };
            addPlayer({
              customQuotation: data.draftTeam.selectedPlayers[player],
              ...selectionPlayer,
            });
          }
        });
      }
    },
    retry: false,
    onSettled: () => setEnabledCode(false),
  });

  const {
    clubPossible,
    filteredPlayers,
    filters,
    activatedFiltersLabel,
    inputFilter,
    setInputFilter,
    resetFilter,
    handleFilter,
    handleGoal,
    handleRating,
    handleUse,
    handleQuotation,
  } = useFilters(
    listPlayers,
    dataClubs,
    championshipId,
    i18n.language,
    maxQuotation,
    maxGoal,
  );

  const {orderBy, sens, orderedPLayers, onClickOrder} = useOrderBy(
    filteredPlayers,
    championshipId,
  );

  const isFilterRating =
    Math.max(...orderedPLayers.map(player => player.stats.averageRating || 0)) >
    0;
  const isFilterGoal =
    Math.max(...orderedPLayers.map(player => player.stats.totalGoals || 0)) > 0;
  const isFilterUse =
    Math.max(
      ...orderedPLayers.map(player => player.stats.totalPlayedMatches || 0),
    ) > 0;

  const onChangeLanguage = (lg: string) => i18n.changeLanguage(lg);

  const [enabled, setEnabled] = useState<boolean>(false);
  const [currentPlayerId, setCurrentPlayerId] = useState<string>('');
  const [currentPlayer, setCurrentPlayer] = useState<
    ISelectionPlayer | undefined
  >();
  const [playerIsSelected, setPlayerIsSelected] = useState<boolean>(false);
  const [displayPlayerStats, setDisplayPlayerStats] = useState<boolean>(false);

  const {
    data: playerStats,
    isLoading: playerStatsIsLoading,
  } = usePlayerStatistics(currentPlayerId, season!, {
    enabled,
  });

  const {
    data: playerSeasons,
    isLoading: playerSeasonsIsLoading,
  } = usePlayerSeasons(currentPlayerId, {
    enabled,
  });

  const [playerStatsIsVisible, setPlayerStatsIsVisible] = useState<boolean>(
    false,
  );

  useEffect(() => {
    if (!_.isEmpty(currentPlayerId)) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [currentPlayerId]);

  useEffect(() => {
    if (
      !_.isEmpty(playerStats) &&
      !_.isEmpty(playerSeasons) &&
      !playerStatsIsLoading &&
      !playerSeasonsIsLoading &&
      displayPlayerStats
    ) {
      setDisplayPlayerStats(false);
      setPlayerStatsIsVisible(true);
    }
  }, [
    displayPlayerStats,
    playerSeasons,
    playerSeasonsIsLoading,
    playerStats,
    playerStatsIsLoading,
  ]);

  useEffect(() => {
    if (playersStatus === 'error') {
      toast(t('error'), {
        progressStyle: {background: themeContext.colors.error},
      });
    }
  }, [playersStatus, t, themeContext]);

  const openStatisticsModal = useCallback(
    (id: string, selected: boolean, player: ISelectionPlayer) => {
      setPlayerIsSelected(selected);
      if (_.isEqual(id, currentPlayerId)) {
        setPlayerStatsIsVisible(true);
      } else {
        setDisplayPlayerStats(true);
        setCurrentPlayerId(id);
        setCurrentPlayer(player);
      }
    },
    [currentPlayerId],
  );

  const {height, width} = useWindowDimensions();
  const widthSectionList = width > 1224 ? 800 : width - 424;

  const [heightSectionList, setHeight] = useState(
    height - 60 - 24 * 2 - 88 - 116,
  );
  useEffect(() => {
    if (refFilter.current) {
      setHeight(height - 60 - 24 * 2 - 88 - refFilter.current.offsetHeight);
    }
  }, [filters, height]);
  const toggleSelectPlayer = useCallback(() => {
    if (currentPlayer) {
      if (playerIsSelected) {
        setPlayerIsSelected(false);
        removePlayer(currentPlayerId);
      } else {
        setPlayerIsSelected(true);
        addPlayer(currentPlayer);
      }
    }
  }, [
    addPlayer,
    currentPlayer,
    currentPlayerId,
    playerIsSelected,
    removePlayer,
  ]);

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [closeModalOpened, setCloseModalOpened] = useState(false);

  return (
    <>
      <Box
        bg="bg.light"
        flexDirection="column"
        height={height}
        overflow="hidden">
        <Header
          closeModalOpened={closeModalOpened}
          openCloseModal={() => setIsCloseModalOpen(true)}
          linkLogo="https://mpg.football/"
          currentLanguage={i18n.language}
          onChangeLanguage={onChangeLanguage}
          currentChampionship={championshipId}
          onClickChampionShip={setChampionshipId}
        />
        <Box justifyContent="center">
          <Box maxWidth="800px" mx="12px" mt="24px" flexDirection="column">
            <Box ref={refFilter} mb="24px">
              <Filter
                isFilterUse={isFilterUse}
                isFilterRating={isFilterRating}
                isFilterGoal={isFilterGoal}
                maxGoal={maxGoal}
                maxQuotation={maxQuotation}
                inputFilter={inputFilter}
                setInputFilter={setInputFilter}
                resetFilter={resetFilter}
                activatedFiltersLabel={activatedFiltersLabel}
                handleGoal={handleGoal}
                handleRating={handleRating}
                handleUse={handleUse}
                handleQuotation={handleQuotation}
                positionsCurrentQuantity={positionQuantity}
                handleFilter={handleFilter}
                filters={filters}
                clubs={clubPossible}
                season={season || 0}
                setSeason={setSeason}
                possibleSeasons={possibleSeasons}
                width={widthSectionList}
              />
            </Box>

            <ListPlayers
              isLoading={playersStatus === 'loading'}
              handleOpenModal={openStatisticsModal}
              addPlayer={addPlayer}
              removePlayer={removePlayer}
              height={heightSectionList}
              width={widthSectionList}
              sens={sens}
              orderBy={orderBy}
              setOrderBy={onClickOrder}
              currentChampionship={championshipId}
              players={orderedPLayers}
            />
          </Box>
          <Box
            flex={1}
            flexDirection="column"
            mt="24px"
            mx="12px"
            mb="24px"
            maxWidth="400px">
            <Code
              code={code}
              submit={() => setEnabledCode(true)}
              setCode={value =>
                setCode(value.toUpperCase().replace(/[^0-9A-Z]/g, ''))
              }
            />
            <Box flex={1} maxHeight={height - 88 - 104 - 2 * 8 - 2 * 24}>
              <Selection
                generateCode={generateCode}
                budget={budget}
                nbPlayers={selectionNbPlayers}
                removePlayer={removePlayer}
                setQuotation={setQuotation}
                selectionMidfielder={selectionMidfielder}
                selectionStriker={selectionStriker}
                selectionDefender={selectionDefender}
                selectionGoalKeeper={selectionGoalKeeper}
              />
            </Box>
          </Box>
          {currentPlayer && (
            <PlayerStatisticsModal
              seasons={playerSeasons?.statsSeasons}
              defaultSeason={season}
              statistics={playerStats}
              isVisible={playerStatsIsVisible}
              hide={() => setPlayerStatsIsVisible(false)}
              currentPlayer={currentPlayer}
              playerIsSelected={playerIsSelected}
              toggleSelectPlayer={toggleSelectPlayer}
              defaultChampionship={championshipId}
            />
          )}
          <QRCodeModal
            onClickCopy={onClickCopy}
            value={qrCode}
            isVisible={Boolean(qrCode)}
            hide={closeModal}
          />
          <CloseModal
            isVisible={isCloseModalOpen}
            closeModal={() => {
              setIsCloseModalOpen(false);
              setCloseModalOpened(true);
            }}
            link="https://mpg.football/"
          />
        </Box>
      </Box>
    </>
  );
};

export default Mercato;
