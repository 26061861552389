import { useQuery, UseQueryOptions } from "react-query";
import { IChampionshipClub } from "../utils/types";

import { ApiError, apiWrapper, axiosConfig } from "./config";

export type IGetChampionshipClub = {
  championshipClubs: {
    [clubId: string]: IChampionshipClub;
  };
};

const CHAMPIONSHIP_CLUB = "CHAMPIONSHIP_CLUB";

const getChampionshipClub = () => {
  return apiWrapper(
    axiosConfig.get<IGetChampionshipClub>(`/api/data/championship-clubs`)
  );
};

export const useChampionshipClub = (
  options?: UseQueryOptions<IGetChampionshipClub, ApiError>
) =>
  useQuery<IGetChampionshipClub, ApiError>(
    [CHAMPIONSHIP_CLUB],
    () => getChampionshipClub(),
    options
  );
