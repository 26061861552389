import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import Box from "./Box";
import { HEIGHT_GRAPH_AREA_USED, WIDTH_BOX } from "./LineDiagram";

const LineBox: FC<{
  index: number;
  y1: number;
  y2: number;
  color: string | undefined;
}> = ({ index, y2, color, y1 }) => {
  const x1 = WIDTH_BOX / 2 + WIDTH_BOX * index;
  const x2 = WIDTH_BOX / 2 + WIDTH_BOX * (index + 1);
  const themeContext = useContext(ThemeContext);

  return (
    <Box
      zIndex={1}
      position="absolute"
      left={x1 + "px"}
      top={HEIGHT_GRAPH_AREA_USED - Math.max(y2, y1) + "px"}
      width={x2 - x1 + "px"}
      height={Math.abs(y2 - y1) || 1 + "px"}
    >
      <svg>
        <g>
          <line
            x1={0}
            y2={y2 - y1 < 0 ? -y2 + y1 : 0}
            x2={x2 - x1}
            y1={y2 - y1 > 0 ? y2 - y1 : 0}
            strokeWidth="1"
            stroke={color || themeContext.colors.bg.medium}
          />
        </g>
      </svg>
    </Box>
  );
};

export default LineBox;
