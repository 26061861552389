import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Box from '../components/Box';
import Modal from '../components/Modal';
import QRCodeComponent from '../components/QRCodeComponent';
import Typo from '../components/Typo';
import icon from '../assets/images/IconApp.png';
import header from '../assets/images/HeaderMercato.png';
import selectImport from '../assets/images/SelectModalImport.png';
import styled from 'styled-components';
interface QRCodeModalProps {
  value: string;
  isVisible: boolean;
  hide: () => void;
  onClickCopy: () => void;
}
const Step: FC<{img: JSX.Element; step: number}> = ({img, step}) => {
  const {t} = useTranslation();
  return (
    <>
      <Typo mt="l" color="font.dark" variant="header">
        {t(`qr.step${step}.title`)}
      </Typo>
      <Typo my="s" color="font.medium" variant="paragraph">
        {t(`qr.step${step}.desc`)}
      </Typo>
      {img}
    </>
  );
};
const BoxShadow = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`;
const QRCodeModal: FC<QRCodeModalProps> = ({
  value,
  isVisible,
  hide,
  onClickCopy,
}) => {
  const {t} = useTranslation();

  return (
    <Modal isVisible={isVisible} hide={hide}>
      <Box maxWidth="861px" flex={1} position="relative" px="40px">
        <Box py="40px" borderRight="1px solid" pr="40px" borderColor="bg.dark">
          <QRCodeComponent onClickCopy={onClickCopy} value={value} />
        </Box>

        <Box pl="40px" py="40px" flexDirection="column">
          <Typo color="font.dark" variant="title">
            {t('qr.title')}
          </Typo>
          <Step
            step={1}
            img={<img width={48} height={48} src={icon} alt="icon" />}
          />
          <Step
            step={2}
            img={
              <BoxShadow width={315}>
                <img width={315} src={header} alt="header" />
              </BoxShadow>
            }
          />
          <Step
            step={3}
            img={<img width={315} src={selectImport} alt="selectImport" />}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default QRCodeModal;
