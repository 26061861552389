import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import fr from "./i18n/fr.json";
import en from "./i18n/en.json";
import es from "./i18n/es.json";

export const resources = {
  fr: { translation: fr },
  en: { translation: en },
  es: { translation: es },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    supportedLngs: ["fr", "en", "es"],
    debug: true,
    resources: resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
