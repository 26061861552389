/**
 * SHOULD NOT BE EDITED
 */

export const fontSizes = {
  xs: 12,
  s: 14,
  m: 15,
  l: 16,
  xl: 24,
};

export const fonts = {
  book: 'Avenir-Book',
  medium: 'Avenir-Medium',
  heavy: 'Avenir-Heavy',
};
