import { IGetGoalKeeperStatistics } from '../utils/types';
import Box from './Box';
import GoalKeeperEfficiencyCardStats from './GoalKeeperEfficiencyCardStats';
import GoalKeeperPlayerCardStats from './GoalKeeperPlayerCardStats';

interface GoalKeeperStatsTabProps {
  statistics: IGetGoalKeeperStatistics;
  championshipId: number;
}

export default function GoalKeeperStatsTab({
    statistics,
    championshipId,
}: GoalKeeperStatsTabProps) {
  const total = statistics?.championships?.[
    championshipId
  ]?.total;

  return (
    <Box width='100%'>
      {Object.keys(total.stats) && (
        <Box display='flex' flex='1'>
          <Box mb='s' mr='s' ml='m' display='flex' flex='1'>
            <GoalKeeperPlayerCardStats
              statistics={total.stats}
            />
          </Box>
          <Box mb='s' ml='s' mr='m' display='flex' flex='1'>
            <GoalKeeperEfficiencyCardStats
              statistics={total.stats}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
