import React, { FC } from "react";
import { IIcon } from "./IIcons";

const Chevron2: FC<IIcon> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g id="Chevron2" transform="translate(-40 -27)">
        <rect
          id="_24"
          data-name="24"
          width="24"
          height="24"
          fillOpacity={0}
          transform="translate(40 27)"
        />
        <path
          id="Union_4"
          data-name="Union 4"
          d="M-321.757-269.743l-3.535-3.535a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l2.829,2.828,2.829-2.828a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-3.535,3.535a1,1,0,0,1-.707.293A1,1,0,0,1-321.757-269.743Zm3.536-9.6-2.829-2.828-2.829,2.828a1,1,0,0,1-1.414,0,1,1,0,0,1,0-1.414l3.535-3.535a1,1,0,0,1,1.415,0l3.535,3.535a1,1,0,0,1,0,1.414,1,1,0,0,1-.707.293A1,1,0,0,1-318.221-279.344Z"
          transform="translate(373 316)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Chevron2;
