import { useQuery, UseQueryOptions } from "react-query";
import { ApiError, apiWrapper, axiosConfig } from "./config";

export type IGetChampionshipSettings = {
  championships: {
    [key: number]: {
      season: number;
      firstSeason: number;
    };
  };
};

const CHAMPIONSHIP_SETTINGS = "CHAMPIONSHIP_SETTINGS";

const getChampionshipClub = () => {
  return apiWrapper(
    axiosConfig.get<IGetChampionshipSettings>(`/api/data/championships/active`)
  );
};

export const useChampionshipSettings = (
  options?: UseQueryOptions<IGetChampionshipSettings, ApiError>
) =>
  useQuery<IGetChampionshipSettings, ApiError>(
    [CHAMPIONSHIP_SETTINGS],
    () => getChampionshipClub(),
    options
  );
