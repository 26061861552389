import React, { FC } from "react";
import { IIcon } from "./IIcons";

const Close: FC<IIcon> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-Check">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="Check" clipPath="url(#clip-Check)">
        <rect width="24" fillOpacity={0} height="24" />
        <rect
          id="Rectangle_1"
          data-name="Rectangle 1"
          fillOpacity={0}
          width="24"
          height="24"
        />
        <path
          fill={color}
          id="Union_8"
          data-name="Union 8"
          d="M-285.07-10.719a.99.99,0,0,1,.06-1.389l3.358-3.382-3.358-3.382a.99.99,0,0,1-.06-1.389.973.973,0,0,1,1.379.061l3.358,3.382,3.238-3.261a.973.973,0,0,1,1.379-.06.991.991,0,0,1-.06,1.389l-3.238,3.261,3.238,3.261a.991.991,0,0,1,.06,1.389.973.973,0,0,1-1.379-.06l-3.238-3.262-3.358,3.382a1.055,1.055,0,0,1-.745.319A.884.884,0,0,1-285.07-10.719Z"
          transform="translate(292.471 27.49)"
        />
      </g>
    </svg>
  );
};

export default Close;
