import React, { FC } from "react";
import BoxButton from "./BoxButton";
import Typo from "./Typo";
interface PlayerTabProps {
  isSelected: boolean;
  text: string;
  onClick: () => void;
}

const PlayerTab: FC<PlayerTabProps> = ({ isSelected, text, onClick }) => {
  return (
    <BoxButton
      zIndex={1}
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      width="176px"
      height="40px"
    >
      <Typo variant="label" color={isSelected ? "white" : "font.light"}>
        {text}
      </Typo>
    </BoxButton>
  );
};

export default PlayerTab;
