import React, {FC, SVGProps, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Box from './Box';
import {getPlayerData, PlayerData} from '../utils/playerStats';
import PolarLabel from './PolarLabel';
import styled from 'styled-components';

const SvgRegularHexagon: FC<
  {dimension: number} & SVGProps<SVGPolygonElement>
> = ({dimension, ...svgProps}) => {
  return (
    <SvgHexagon
      dimTop={dimension}
      dimTopRight={dimension}
      dimBottomRight={dimension}
      dimBottom={dimension}
      dimBottomLeft={dimension}
      dimTopLeft={dimension}
      {...svgProps}
    />
  );
};
const SvgHexagon: FC<
  {
    dimTop: number;
    dimTopRight: number;
    dimBottomRight: number;
    dimBottom: number;
    dimBottomLeft: number;
    dimTopLeft: number;
  } & SVGProps<SVGPolygonElement>
> = ({
  dimTop,
  dimTopRight,
  dimBottomRight,
  dimBottom,
  dimBottomLeft,
  dimTopLeft,
  ...svgProps
}) => {
  const cos30 = Math.cos(Math.PI / 6);
  const sin30 = Math.sin(Math.PI / 6);
  const points = useMemo(
    () =>
      `0,-${dimTop} ${dimTopRight * cos30},-${dimTopRight * sin30} ${
        dimBottomRight * cos30
      },${dimBottomRight * sin30} 0,${dimBottom} -${dimBottomLeft * cos30},${
        dimBottomLeft * sin30
      } -${dimTopLeft * cos30},-${dimTopLeft * sin30}`,
    [
      cos30,
      dimBottom,
      dimBottomLeft,
      dimBottomRight,
      dimTop,
      dimTopLeft,
      dimTopRight,
      sin30,
    ],
  );

  return <polygon points={points} {...svgProps} />;
};
const NUMBER_OF_HEXAGON = 8;
const HEXAGON_STROKE = 0.02;
const SCALE = 0.9;
const MIN_SCALE = 0.1;
const DIMENSION = '140px';
const LABEL_VERTICAL = '58px';

interface PolarProps {
  stats: PlayerData;
}
const StyledContainer = styled(Box)`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const Polar: FC<PolarProps> = ({stats}) => {
  const {t} = useTranslation();

  const data = getPlayerData(stats, t);

  return (
    <StyledContainer
      border="1px solid"
      borderColor="bg.dark"
      width="300px"
      height="313px"
      alignItems="center"
      justifyContent="center"
      bg="white">
      <Box
        width="265px"
        height="265px"
        alignItems="center"
        position="relative"
        justifyContent="center">
        <PolarLabel
          position="absolute"
          top="24px"
          color="#00CC3F"
          isAbove={true}
          label={data[0].label}
          legendValue={data[0].legendValue || 0}
        />
        <PolarLabel
          position="absolute"
          top={LABEL_VERTICAL}
          right="0"
          color="#EB9724"
          isAbove={true}
          label={data[1].label}
          legendValue={data[1].legendValue || 0}
        />
        <PolarLabel
          position="absolute"
          bottom={LABEL_VERTICAL}
          right="0"
          color="#7B8BE2"
          isAbove={false}
          label={data[2].label}
          legendValue={data[2].legendValue || 0}
        />
        <PolarLabel
          position="absolute"
          bottom="0"
          color="#1EB9E8"
          isAbove={false}
          label={data[3].label}
          legendValue={data[3].legendValue || 0}
        />
        <PolarLabel
          position="absolute"
          bottom={LABEL_VERTICAL}
          left="0"
          color="#E64040"
          isAbove={false}
          label={data[4].label}
          legendValue={data[4].legendValue || 0}
        />
        <PolarLabel
          position="absolute"
          top={LABEL_VERTICAL}
          left="0"
          color="#33DBC1"
          isAbove={true}
          label={data[5].label}
          legendValue={data[5].legendValue || 0}
        />
        <svg
          width={DIMENSION}
          height={DIMENSION}
          viewBox={`${-1 - HEXAGON_STROKE / 2} ${-1 - HEXAGON_STROKE / 2} ${
            2 + HEXAGON_STROKE
          } ${2 + HEXAGON_STROKE}`}>
          {Array(NUMBER_OF_HEXAGON)
            .fill(null)
            .map((_, index) => {
              return (
                <SvgRegularHexagon
                  key={index}
                  dimension={(2 * index + 1) / (2 * NUMBER_OF_HEXAGON - 1)}
                  fill="#324bc8"
                  fillOpacity="0.04"
                />
              );
            })}
          <SvgHexagon
            dimTop={data[0].value * SCALE + MIN_SCALE}
            dimTopRight={data[1].value * SCALE + MIN_SCALE}
            dimBottomRight={data[2].value * SCALE + MIN_SCALE}
            dimBottom={data[3].value * SCALE + MIN_SCALE}
            dimBottomLeft={data[4].value * SCALE + MIN_SCALE}
            dimTopLeft={data[5].value * SCALE + MIN_SCALE}
            fill="#324bc8"
            fillOpacity="0.15"
            stroke="#4054cc"
            strokeWidth={HEXAGON_STROKE}
          />
        </svg>
      </Box>
    </StyledContainer>
  );
};

export default Polar;
