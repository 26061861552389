import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { getChampionshipImage } from "../utils/championship";
import {
  GOAL_WIDTH,
  POSITION_WIDTH,
  PRICE_WIDTH,
  QUOTATION_WIDTH,
  USE_WIDTH,
} from "../utils/listPlayer";
import { orderBy } from "../utils/types";
import Box from "./Box";
import Image from "./Image";
import ListHeaderItem from "./ListHeaderItem";
import Typo from "./Typo";

const QUOTATION = QUOTATION_WIDTH + "px";
const GOAL = GOAL_WIDTH + "px";
const POSITION = POSITION_WIDTH + "px";
const USE = USE_WIDTH + "px";
const PRICE = PRICE_WIDTH + "px";
const PLAYER = "53px";

interface ListHeaderProps {
  itemSelected: orderBy;
  onClickItem: (item: orderBy) => void;
  championshipId: number;
  playersNumber: number;
  sens: number;
}
const ListHeader: FC<ListHeaderProps> = ({
  itemSelected,
  onClickItem,
  championshipId,
  playersNumber,
  sens,
}) => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="column">
      <Box mb="12px" mx="l" alignItems="center">
        <Image
          url={getChampionshipImage(championshipId)}
          alt="championship"
          size={24}
        />
        <Typo color="font.light" ml="m" variant="tab">
          {t("listPlayers.playersNumber", { count: playersNumber })}
        </Typo>
      </Box>
      {Boolean(playersNumber) && (
        <Box
          height="24px"
          bg="white"
          borderBottom="1px solid"
          borderColor="bg.light"
          pl="48px"
          justifyContent="space-between"
        >
          <ListHeaderItem
            sens={sens}
            onClick={() => onClickItem("name")}
            selected={itemSelected === "name"}
            width={PLAYER}
          >
            {t("listPlayers.players")}
          </ListHeaderItem>
          <Box>
            <ListHeaderItem
              sens={sens}
              onClick={() => onClickItem("rating")}
              selected={itemSelected === "rating"}
              width={QUOTATION}
            >
              {t("listPlayers.quotation")}
            </ListHeaderItem>
            <ListHeaderItem
              sens={sens}
              onClick={() => onClickItem("goal")}
              selected={itemSelected === "goal"}
              width={GOAL}
            >
              {t("listPlayers.goal")}
            </ListHeaderItem>
            <ListHeaderItem
              sens={sens}
              onClick={() => onClickItem("position")}
              selected={itemSelected === "position"}
              width={POSITION}
            >
              {t("listPlayers.position")}
            </ListHeaderItem>
            <ListHeaderItem
              sens={sens}
              onClick={() => onClickItem("use")}
              selected={itemSelected === "use"}
              width={USE}
            >
              {t("listPlayers.use")}
            </ListHeaderItem>
            <ListHeaderItem
              sens={sens}
              onClick={() => onClickItem("quotation")}
              selected={itemSelected === "quotation"}
              width={PRICE}
            >
              {t("listPlayers.price")}
            </ListHeaderItem>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ListHeader;
