import React, { FC } from "react";
import SeasonItem from "./SeasonItem";
import SelectorContainer from "./SelectorContainer";
export interface SeasonSelectorProps {
  seasons: number[];
  selectedSeason: number;
  onClickSeason: (season: number) => void;
  isLeft: boolean;
}

const SeasonSelector: FC<SeasonSelectorProps> = ({
  seasons,
  onClickSeason,
  selectedSeason,
  isLeft,
}) => {
  return (
    <SelectorContainer>
      {seasons.map((season) => (
        <SeasonItem
          isLeft={isLeft}
          key={season}
          onClickSeason={() => onClickSeason(season)}
          season={season}
          selected={selectedSeason === season}
        />
      ))}
    </SelectorContainer>
  );
};

export default SeasonSelector;
