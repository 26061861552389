import React, {FC} from 'react';
import {playerRatingColor} from '../utils/playerStats';
import Box from './Box';
import Typo from './Typo';
interface PlayerRatingCircleProps {
  rating: number | null | undefined;
  size?: string;
}

const PlayerRatingCircle: FC<PlayerRatingCircleProps> = ({rating, size}) => {
  const decimalNumberRegex = /(\d*\.\d)/;
  return (
    <Box
      height={size || '40px'}
      width={size || '40px'}
      borderRadius={size || '40px'}
      alignItems="center"
      justifyContent="center"
      bg={playerRatingColor(rating)}>
      <Typo
        variant="header"
        fontSize={rating?.toString()?.match(decimalNumberRegex) && '12px'}
        color="white">
        {rating === 0 || rating ? rating : '-'}
      </Typo>
    </Box>
  );
};

export default PlayerRatingCircle;
