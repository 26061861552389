import React, {FC} from 'react';
import en_google from '../assets/images/google_play_en.png';
import es_google from '../assets/images/google_play_es.png';
import fr_google from '../assets/images/google_play_fr.png';
import en_apple from '../assets/images/app_store_en.svg';
import es_apple from '../assets/images/app_store_es.svg';
import fr_apple from '../assets/images/app_store_fr.svg';
import styled from 'styled-components';

interface StoreButtonButtonProps {
  language: string;
  link: string;
  isGoogle: boolean;
  width: number;
}
const StoreButtonButton: FC<StoreButtonButtonProps> = ({
  language,
  link,
  isGoogle,
  width,
}) => {
  const googleImg =
    language === 'es' ? es_google : language === 'en' ? en_google : fr_google;
  const appleImg =
    language === 'es' ? es_apple : language === 'en' ? en_apple : fr_apple;
  return (
    <a href={link}>
      <StyledImag
        width={isGoogle ? width : width - 19}
        height={'auto'}
        src={isGoogle ? googleImg : appleImg}
        alt="play store button"
      />
    </a>
  );
};
const StyledImag = styled.img`
  image-orientation: flip;
`;

export default StoreButtonButton;
