import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {getChampionshipImage} from '../utils/championship';
import Box from './Box';
import BoxButton from './BoxButton';
import Image from './Image';
import Typo from './Typo';
interface HeaderChampionshipProps {
  focused: boolean;
  championshipId: number;
  onClick: () => void;
}
const StyledTypo = styled(Typo)<{opacity: number}>`
  white-space: nowrap;
  text-align: center;
  opacity: ${({opacity}) => opacity};
`;
const HeaderChampionship: FC<HeaderChampionshipProps> = ({
  focused,
  championshipId,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  const {t} = useTranslation();
  return (
    <BoxButton
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      width="40px"
      onClick={onClick}
      alignItems="center"
      flexDirection="column">
      <Box>
        <Box
          border="2px solid"
          borderColor={hover || focused ? 'white' : 'primary'}
          alignItems="center"
          justifyContent="center"
          height="40px"
          width="40px"
          borderRadius="40px">
          <Image
            alt="championship"
            url={getChampionshipImage(championshipId)}
            size={32}
          />
        </Box>
      </Box>
      <Box position="relative">
        {focused && (
          <Box
            top="6px"
            left="-16px"
            position="absolute"
            bg="white"
            width="8px"
            height="8px"
            borderRadius="8px"
          />
        )}
        <StyledTypo
          opacity={hover || focused ? 1 : 0.72}
          color="white"
          variant={focused ? 'section' : 'label'}>
          {t(`championships.${championshipId}`)}
        </StyledTypo>
      </Box>
    </BoxButton>
  );
};

export default HeaderChampionship;
