import { PlayerStatKeyProps } from './PlayerStatKey';
import { OtherPositionStats } from '../utils/types';
import { useTranslation } from 'react-i18next';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';

interface StrikerPlayerCardStatsProps {
  statistics: OtherPositionStats;
}

export default function StrikerPlayerCardStats({
    statistics: {
        totalStartedMatches,
        totalPlayedMatches,
        totalPenaltiesScored,
        totalGoals,
        totalGoalAssist,
        totalYellowCard,
        totalRedCard,
    },
}: StrikerPlayerCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
        stat: 'totalStartedMatches',
      value: totalStartedMatches,
      subValue:
        totalPlayedMatches === 0
          ? totalPlayedMatches
          : totalPlayedMatches - totalStartedMatches,
    },
    {
        stat: 'totalGoals',
      value: totalGoals,
      subValue: totalPenaltiesScored,
    },
    {
        stat: 'totalGoalAssist',
      value: totalGoalAssist,
    },
    {
        stat: 'totalYellowCard',
      value: totalYellowCard,
    },
    {
        stat: 'totalRedCard',
      value: totalRedCard,
    },
  ];

  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.playerCard')} data={data} />
  )
};
