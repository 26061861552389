import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import Chevron from "../assets/icons/Chevron";
import Box from "./Box";
import BoxButton from "./BoxButton";
import Typo from "./Typo";

interface IListHeaderItem {
  width: string;
  selected: boolean;
  onClick: () => void;
  sens: number;
}
const ListHeaderItem: FC<IListHeaderItem> = ({
  width,
  children,
  selected,
  onClick,
  sens,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <BoxButton
      onClick={onClick}
      height="24px"
      position="relative"
      alignItems="center"
      justifyContent="center"
      mx="l"
      width={width}
    >
      {selected && (
        <Box position="absolute" left="-24px">
          <Chevron
            direction={sens === 1 ? "bottom" : "top"}
            color={themeContext.colors.secondary}
            size={24}
          />
        </Box>
      )}
      <Typo
        color={selected ? "secondary" : "font.light"}
        fontFamily={selected ? "medium" : "book"}
        fontSize="xs"
      >
        {children}
      </Typo>
    </BoxButton>
  );
};
export default ListHeaderItem;
