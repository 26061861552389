import React, {useContext} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ThemeContext} from 'styled-components';
import useWindowDimensions from './hooks/useWindowDimensions';
import LittleScreen from './screens/LittleScreen';
import Mercato from './screens/Mercato';
function App() {
  const themeContext = useContext(ThemeContext);
  const {width} = useWindowDimensions();

  return (
    <>
      <ToastContainer
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        toastStyle={{
          color: themeContext.colors.font.dark,
          background: 'white',
          flex: 1,
          fontFamily: themeContext.fonts.medium,
        }}
        progressStyle={{
          background: themeContext.colors.primary,
        }}
        autoClose={5000}
        limit={1}
      />
      <Router>
        <Switch>
          <Route path="/:teamId">
            {width < 1024 ? <LittleScreen /> : <Mercato />}
          </Route>
          <Route path="/">
            {width < 1024 ? <LittleScreen /> : <Mercato />}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
