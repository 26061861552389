/**
 * SHOULD NOT BE EDITED
 */

import { fonts, fontSizes } from "./fonts";

// Typos variants used in the app
const fontsVariant = {
  legend: {
    fontFamily: fonts.book,
    fontSize: fontSizes.xs,
  },
  tab: {
    fontFamily: fonts.medium,
    fontSize: fontSizes.s,
  },
  paragraph: {
    fontFamily: fonts.book,
    fontSize: fontSizes.m,
  },
  label: {
    fontFamily: fonts.medium,
    fontSize: fontSizes.m,
  },
  section: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes.m,
  },
  header: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes.l,
  },
  title: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes.xl,
  },
};

export default fontsVariant;
