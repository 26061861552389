import {useEffect, useState} from 'react';

import {IListPLayer, orderBy} from '../utils/types';
const sortFunction = (
  orderBy: orderBy,
  sens: number,
  player1: IListPLayer,
  player2: IListPLayer,
) => {
  let order;
  if (orderBy === 'name') {
    order = player1.lastName >= player2.lastName ? 1 : -1;
  }
  if (orderBy === 'position') {
    order = player1.position >= player2.position ? -1 : 1;
  }
  if (orderBy === 'quotation') {
    order = player1.quotation >= player2.quotation ? -1 : 1;
  }
  if (orderBy === 'rating') {
    order =
      (player1.stats.averageRating || 0) >= (player2.stats.averageRating || 0)
        ? -1
        : 1;
  }
  if (orderBy === 'use') {
    order =
      (player1.stats.totalStartedMatches || 0) /
        (player1.stats.totalMatches || 1) >=
      (player2.stats.totalStartedMatches || 0) /
        (player2.stats.totalMatches || 1)
        ? -1
        : 1;
  }
  if (orderBy === 'goal') {
    order =
      (player1.stats.totalGoals || 0) >= (player2.stats.totalGoals || 0)
        ? -1
        : 1;
  }
  return (order || 0) * sens;
};

const useOrderBy = (
  displayedPlayers: IListPLayer[],
  championshipId: number,
) => {
  const [orderBy, setOrderBy] = useState<orderBy>('quotation');
  const [sens, setSens] = useState<number>(1);
  const orderedPLayers = displayedPlayers.sort((player1, player2) =>
    sortFunction(orderBy, sens, player1, player2),
  );

  const onClickOrder = (order: orderBy) => {
    if (order === orderBy) {
      setSens(pre => pre * -1);
    } else {
      setOrderBy(order);
      setSens(1);
    }
  };
  useEffect(() => {
    setOrderBy('quotation');
  }, [championshipId]);

  return {sens, orderBy, onClickOrder, orderedPLayers};
};

export default useOrderBy;
