import { PlayerPerformanceStatus } from "./playerStats";
import { IMatch } from "./types";

const PLAYED_MATCHES = 5;

export const hasEnoughDataToDisplay = (
  matches: IMatch[],
  minMatches: number = PLAYED_MATCHES,
) => {
  const playedMatches = matches.filter(
    value =>
        value.playerPerformance.status ===
          PlayerPerformanceStatus.PLAYED_SUBSTITUTE ||
        value.playerPerformance.status === PlayerPerformanceStatus.STARTER,
    ).length;
  return playedMatches >= minMatches;
};
