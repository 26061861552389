import {useCallback, useEffect, useMemo, useState} from 'react';
import {useChampionshipClub} from '../api/useChampionshipClub';
import Box from '../components/Box';
import ChampionshipSelector from '../components/ChampionshipSelector';
import FilterChampionship from '../components/FilterChampionship';
import FilterSeason from '../components/FilterSeason';
import Modal from '../components/Modal';
import PlayerPresentation from '../components/PlayerPresentation';
import PlayerSelectionButton from '../components/PlayerSelectionButton';
import PlayerTabs from '../components/PlayerTabs';
import Polar from '../components/Polar';
import SeasonSelector from '../components/SeasonSelector';
import useClickFilter from '../hooks/useClickFilter';
import i18n from '../i18n';
import {
  IChampionships,
  IGetPlayerStatistics,
  ISelectionPlayer,
  OtherPositionStats,
} from '../utils/types';
import StatsTab from '../components/PlayerStatisticsStatsTab';
import MatchesTab from '../components/PlayerStatisticsMatchesTab';
import QuotationsTab from '../components/PlayerStatisticsQuotationsTab';
import {usePlayersPool} from '../api/usePlayersPool';
import {isEmpty} from 'lodash';
import {hasEnoughDataToDisplay} from '../utils/hasEnoughDataToDisplay';
import {usePlayerStatistics} from '../api/usePlayerStatistics';
import _ from 'lodash';

type PlayerStatisticsModalProps = {
  statistics: IGetPlayerStatistics | undefined;
  seasons: string[] | undefined;
  isVisible: boolean;
  hide: () => void;
  defaultSeason?: number | undefined;
  defaultChampionship?: number | undefined;
  currentPlayer: ISelectionPlayer;
  playerIsSelected: boolean;
  toggleSelectPlayer: () => void;
};

export default function PlayerStatisticsModal({
  statistics,
  seasons,
  isVisible,
  hide,
  defaultSeason,
  currentPlayer,
  defaultChampionship,
  playerIsSelected,
  toggleSelectPlayer,
}: PlayerStatisticsModalProps): JSX.Element {
  const [tab, setTab] = useState<number>(0);
  const [currentStats, setCurrentStats] = useState<
    IGetPlayerStatistics | undefined
  >(statistics);

  const {filterRef: seasonRef, onClickFilter: onClickSeason} = useClickFilter();

  const [currentSeason, setCurrentSeason] = useState<number | undefined>(
    undefined,
  );

  const championships = useMemo(() => {
    return Object.keys(currentStats?.championships ?? {}).map(s => +s);
  }, [currentStats?.championships]);

  const [currentChampionship, setCurrentChampionship] = useState<number>(
    championships[0],
  );

  const defineCurrentChampionship = useCallback((championship: number) => {
    setCurrentChampionship(championship);
  }, []);

  useEffect(() => {
    if (championships && !currentChampionship) {
      defineCurrentChampionship(championships[0]);
    }
  }, [championships, currentChampionship, defineCurrentChampionship]);

  const defineCurrentSeason = useCallback((season: number) => {
    setCurrentSeason(season);
  }, []);

  const {
    data: playerStats,
    isLoading: playerStatsIsLoading,
  } = usePlayerStatistics(currentPlayer.id, currentSeason!, {
    enabled: Boolean(currentSeason),
  });

  useEffect(() => {
    if (playerStats && !playerStatsIsLoading) {
      setCurrentStats(playerStats);
    }
  }, [playerStats, playerStatsIsLoading]);

  const {data: dataPlayersPool} = usePlayersPool(
    currentChampionship,
    currentSeason,
    {
      enabled: Boolean(currentChampionship),
    },
  );

  useEffect(() => {
    if (defaultChampionship) {
      defineCurrentChampionship(defaultChampionship);
    }
  }, [defaultChampionship, defineCurrentChampionship]);

  useEffect(() => {
    if (defaultSeason && currentSeason === undefined) {
      defineCurrentSeason(defaultSeason);
    }
  }, [currentSeason, defaultSeason, defineCurrentSeason]);

  useEffect(() => {
    if (!isVisible) {
      setTab(0);
      if (defaultChampionship) {
        defineCurrentChampionship(defaultChampionship);
      }
      if (defaultSeason) {
        defineCurrentSeason(defaultSeason);
      }
    }
  }, [
    defaultChampionship,
    defaultSeason,
    defineCurrentChampionship,
    defineCurrentSeason,
    isVisible,
  ]);

  const currentChampionshipStats:
    | IChampionships<OtherPositionStats, any>
    | undefined = useMemo(() => {
    return currentStats?.championships[currentChampionship] ?? undefined;
  }, [currentChampionship, currentStats]);

  const parseSeasons = useMemo(() => seasons?.map(s => +s) ?? [], [seasons]);

  const {data: dataClub} = useChampionshipClub();

  const currentClub = useMemo(() => {
    if (currentPlayer?.clubId) {
      return dataClub?.championshipClubs[currentPlayer?.clubId];
    }
  }, [currentPlayer, dataClub]);

  const lnKey = useMemo(
    () =>
      i18n.language === 'en'
        ? 'en-GB'
        : i18n.language === 'es'
        ? 'es-ES'
        : 'fr-FR',
    [],
  );

  const renderContent = useCallback(() => {
    switch (tab) {
      case 0:
        return (
          <StatsTab
            currentChampionship={currentChampionship}
            currentChampionshipStats={currentChampionshipStats}
            statistics={currentStats}
            availableSeasons={seasons}
          />
        );
      case 1:
        return (
          <MatchesTab
            currentChampionshipStats={currentChampionshipStats}
            availableSeasons={seasons}
          />
        );
      case 2:
        return (
          <QuotationsTab
            currentChampionship={currentChampionship}
            currentChampionshipStats={currentChampionshipStats}
            currentClub={currentClub}
            currentPlayer={currentPlayer}
            poolPlayers={dataPlayersPool?.poolPlayers}
            statistics={currentStats}
            availableSeasons={seasons}
          />
        );
      default:
        return;
    }
  }, [
    currentChampionship,
    currentChampionshipStats,
    currentClub,
    currentPlayer,
    dataPlayersPool,
    seasons,
    currentStats,
    tab,
  ]);

  const isStatisticsEmpty = useMemo(
    () =>
      isEmpty(currentChampionshipStats?.total?.stats) ||
      currentChampionshipStats?.total?.stats?.totalPlayedMatches === 0,
    [currentChampionshipStats],
  );

  const noStatsData = useMemo(() => {
    if (currentChampionshipStats) {
      return (
        seasons?.length === 1 &&
        isStatisticsEmpty &&
        (isStatisticsEmpty ||
          !hasEnoughDataToDisplay(currentChampionshipStats?.total?.matches, 1))
      );
    }
    return false;
  }, [currentChampionshipStats, isStatisticsEmpty, seasons]);

  return (
    <Modal isVisible={isVisible} hide={hide}>
      <Box flexDirection={'column'} m="l">
        <Box flex={1} flexDirection={'row'}>
          <Box flex={1} flexDirection={'column'}>
            <Box mb={'s'}>
              <PlayerPresentation
                position={currentPlayer?.position ?? 0}
                clubName={currentClub?.name[lnKey] ?? ''}
                name={currentPlayer?.name ?? ''}
                jersey={currentPlayer?.jersey ?? ''}
              />
            </Box>
            <Box mt={'s'}>
              {currentChampionshipStats?.keySeasonStats &&
              currentChampionshipStats?.percentRanks ? (
                <Polar
                  stats={
                    {
                      ...currentChampionshipStats,
                      position: currentPlayer?.position || 1,
                    } as any
                  }
                />
              ) : null}
            </Box>
          </Box>
          <Box flex={1} flexDirection={'column'} ml={'xl'}>
            <Box>
              <PlayerTabs currentTab={tab} onClickTab={setTab} />
            </Box>
            <Box
              zIndex={10}
              mt="l"
              mb="l"
              flexDirection="row"
              alignItems="center">
              {noStatsData ? (
                <></>
              ) : (
                <>
                  {currentSeason ? (
                    <FilterSeason
                      isLeft={true}
                      onClick={onClickSeason}
                      selectorItem={
                        <Box ref={seasonRef}>
                          <SeasonSelector
                            isLeft={true}
                            onClickSeason={defineCurrentSeason}
                            seasons={parseSeasons}
                            selectedSeason={currentSeason}
                          />
                        </Box>
                      }
                      season={currentSeason}
                    />
                  ) : null}
                  {currentChampionship ? (
                    <FilterChampionship
                      isLeft
                      championship={currentChampionship}
                      selectorItem={
                        <Box>
                          <ChampionshipSelector
                            onClickChampionship={defineCurrentChampionship}
                            championships={championships}
                            currentChampionship={currentChampionship}
                          />
                        </Box>
                      }
                    />
                  ) : null}
                </>
              )}
            </Box>
            <Box overflowY="scroll" maxHeight="430px" flexDirection="column">
              <Box
                borderRadius="8px"
                bg="bg.light"
                flexDirection="column"
                width="100%">
                {renderContent()}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={'l'} flexDirection={'column'} alignItems={'flex-end'}>
          <PlayerSelectionButton
            onPress={toggleSelectPlayer}
            isSelected={playerIsSelected}
          />
        </Box>
      </Box>
    </Modal>
  );
}
