import React, { FC, useState } from "react";
import Box from "./Box";
import CheckBoxJerseyItem from "./CheckBoxJerseyItem";
interface TeamItemProps {
  name: string;
  jersey: string;
  selected: boolean;
  onClickTeam: () => void;
}
const TeamItem: FC<TeamItemProps> = ({
  name,
  jersey,
  selected,
  onClickTeam,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Box
      onClick={onClickTeam}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      bg={selected ? "secondaryTrans.20" : hover ? "bg.light" : "white"}
      px="l"
      py="12px"
      flex={1}
    >
      <CheckBoxJerseyItem
        onClick={() => {}}
        name={name}
        jersey={jersey}
        selected={selected}
      />
    </Box>
  );
};

export default TeamItem;
