import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SpaceProps } from "styled-system";
import Box from "./Box";
import Typo from "./Typo";
type SelectionIndicatorProps = BudgetProps | PlayersProps;
interface BudgetProps {
  label: "budget";
  info: number;
}
interface PlayersProps {
  label: "players";
  info: number;
}

const Bar: FC<{ proportion: number; color: string }> = ({
  proportion,
  color,
}) => (
  <Box overflow="hidden" bg="bg.light" borderRadius="s" height="8px" flex={1}>
    <Box width={`${proportion * 100}%`} bg={color} />
  </Box>
);
const SelectionIndicator: FC<SelectionIndicatorProps & SpaceProps> = ({
  label,
  info,
  ...props
}) => {
  const { t } = useTranslation();
  const proportion =
    label === "budget"
      ? (info > 500 ? -500 + info : 500 - info) / 500
      : info / 18;
  const isError = label === "budget" ? info > 500 : info > 30;

  return (
    <Box {...props} flex={1} flexDirection="column">
      <Box mb="5px" justifyContent="space-between">
        <Typo color="font.medium" variant="legend">
          {t(`selection.indicator.${label}.label`)}
        </Typo>
        <Typo color={isError ? "error" : "font.medium"} variant="legend">
          {t(`selection.indicator.${label}.value`, {
            value:
              label === "budget"
                ? info > 500
                  ? `- ${info - 500}`
                  : 500 - info
                : `${info}/18`,
          })}
        </Typo>
      </Box>
      <Box flex={1}>
        <Bar proportion={proportion} color={isError ? "error" : "primary"} />
      </Box>
    </Box>
  );
};

export default SelectionIndicator;
