import {fonts, fontSizes} from './fonts';
import space from './spaces';
import colors from './colors';
import sizes from './sizes';
import typos from './typos';
import radii from './radii';

const theme = {
  colors,
  fonts,
  fontSizes,
  typos, // usable fonts for typos (eg: legend = { Avenir-Book - 12px })
  space, // margins & paddings
  sizes, // widths & heights
  radii, // border-radius
};

export default theme;
