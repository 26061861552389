import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {ThemeContext} from 'styled-components';
import {IPayloadDraft, usePostDraftTeam} from '../api/useDraftTeam';
import {ISelectionPlayer} from '../utils/types';

const useQRCode = (
  championshipId: number,
  currentSelection: ISelectionPlayer[],
) => {
  const {t} = useTranslation();
  const themeContext = useContext(ThemeContext);
  const {teamId} = useParams<{teamId?: string}>();
  const {mutate, data, status} = usePostDraftTeam();
  const history = useHistory();
  const [qrCode, setQrCode] = useState(teamId || '');
  const [isNewCode, setIsNewCode] = useState(true);
  const [isAlreadyCopy, setIsAlreadyCopy] = useState(false);

  useEffect(() => {
    setIsAlreadyCopy(false);
  }, [qrCode]);

  const onClickCopy = async () => {
    try {
      await navigator.clipboard.writeText(qrCode);
      if (!isAlreadyCopy) {
        setIsAlreadyCopy(true);
        toast(t('clipBoard', {value: qrCode}), {});
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!qrCode && isNewCode) {
      const NewQrCode = (data?.draftTeam.id || '').split('_').pop() || '';
      setQrCode(NewQrCode);
      setIsNewCode(!Boolean(NewQrCode));
      if (NewQrCode) {
        history.push(`/${NewQrCode}`);
      }
    }
  }, [data, qrCode, history, isNewCode]);

  const closeModal = () => {
    history.push('/');
    setQrCode('');
  };

  const generateCode = async () => {
    let playerSelected: IPayloadDraft = {
      championshipId,
      selectedPlayers: {},
    };
    currentSelection.forEach(player => {
      playerSelected.selectedPlayers[player.id] =
        player.customQuotation || player.quotation;
    });
    try {
      mutate({payload: playerSelected});
    } catch (error) {}
    setIsNewCode(true);
  };
  useEffect(() => {
    if (status === 'error') {
      toast(t('error'), {
        progressStyle: {background: themeContext.colors.error},
      });
    }
  }, [status, themeContext.colors.error, t]);

  return {
    generateCode,
    qrCode,
    closeModal,
    onClickCopy,
  };
};

export default useQRCode;
