import React, { FunctionComponent } from "react";

import { IIcon } from "./IIcons";

const Goal: FunctionComponent<IIcon> = ({ size = 24, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g stroke={color} strokeMiterlimit={10}>
        <path
          d="M19.002 11.98v.125a7 7 0 01-14-.073v-.05a7 7 0 0114 0z"
          fill="#fff"
          strokeWidth={0.644}
        />
        <path
          data-name="Union 6"
          d="M6.289 16.028l.087-.269h2.9l.895 2.757-.217.158a7.011 7.011 0 01-3.665-2.646zm7.644 2.488l.895-2.761h2.9l.053.175a7.011 7.011 0 01-3.661 2.722zm-.453-4.467l1.38 1.676zm-2.86 0l-1.38 1.676zm-.02-.027l-.891-2.737-2.146-.78 2.146.78v-.013L12 9.6V7.185L9.705 5.522l.056-.172a7.036 7.036 0 014.59.035l.043.132-2.344 1.704-.05-.036V9.6l.049-.035 2.345 1.7v.013l2.146-.78-2.15.787-.89 2.737zm5.941-3.537l.9-2.755h.124A6.964 6.964 0 0119 11.979v.123l-.115.083zM5 12.028v-.05a6.961 6.961 0 011.441-4.251h.222l.9 2.755-2.349 1.703z"
          fill={color}
          strokeWidth={0.752}
        />
      </g>
    </svg>
  );
};
export default Goal;
