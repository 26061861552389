import axios, { AxiosError, AxiosPromise } from "axios";

export interface ApiError {
  statusCode?: number;
  error: string;
  message: string;
}

export const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

export const apiWrapper = async <T>(call: AxiosPromise<T>) => {
  try {
    const { data } = await call;
    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const error = err as AxiosError<ApiError>;
      if (error.response) {
        console.error("[API]", error.response.data);
        throw error.response.data;
      }
    }
    const error: ApiError = {
      error: "Unknown",
      message: "An unknown error occured",
    };
    console.error("[API]", error);
    throw error;
  }
};
