export enum UltraPosition {
  GOALKEEPER = 10,
  CENTRAL_DEFENDER = 20,
  LATERAL_DEFENDER = 21,
  DEFENSIVE_MID_FIELDER = 30,
  OFFENSIVE_MID_FIELDER = 31,
  STRIKER = 40,
}

export enum Position {
  GOALKEEPER = 1,
  DEFENDER = 2,
  MID_FIELDER = 3,
  STRIKER = 4,
}
export const positionsFilter = [1, 2, 20, 21, 3, 30, 31, 4];
export interface positionsQuantity {
  1: number;
  2: number;
  3: number;
  4: number;
}
export type TypePosition = 1 | 2 | 3 | 4;
export const positionsMinQuantity: positionsQuantity = {
  1: 2,
  2: 6,
  3: 6,
  4: 4,
};
