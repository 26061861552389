import { useQuery, UseQueryOptions } from "react-query";
import { IPoolPlayer } from "../utils/types";
import { ApiError, apiWrapper, axiosConfig } from "./config";

export type IGetPlayersPool = {
  poolPlayers: IPoolPlayer[];
};

const PLAYER_STATS_QUERYKEY = "PLAYER_STATS_QUERYKEY";

const getPlayersPool = (championshipId: number, season?: number) => {
  return apiWrapper(
    axiosConfig.get<IGetPlayersPool>(
      `api/data/championship-players-pool/${championshipId}${
        season ? `?season=${season}` : ""
      }`
    )
  );
};

export const usePlayersPool = (
  championshipId: number,
  season?: number,
  options?: UseQueryOptions<IGetPlayersPool, ApiError>
) =>
  useQuery<IGetPlayersPool, ApiError>(
    [PLAYER_STATS_QUERYKEY, championshipId, season],
    () => getPlayersPool(championshipId, season),
    options
  );
