import React, { FC } from "react";
import { IQuotation } from "../utils/types";
import LineDiagram from "./LineDiagram";
import PlayerQuotation from "./PlayerQuotation";

interface PlayerQuotationDiagramProps {
  data: IQuotation[];
}

const LABELS = ["0", "10", "20", "30", "40", "50"];
const PlayerQuotationDiagram: FC<PlayerQuotationDiagramProps> = ({ data }) => {
  const lineData = data.map((el) => ({
    y: el.quotation / 50,
    label: el.date,
  }));
  const quotations = data.map((el) => el.quotation);
  const maxQuotations = Math.max(...quotations);

  return (
    <LineDiagram
      axisYLabels={LABELS}
      isLabelReverse
      titleDiagram={"Millions"}
      data={lineData}
    >
      {quotations.map((quotation, index) => (
        <PlayerQuotation
          key={index}
          quotation={quotation}
          isHigher={quotation === maxQuotations}
        />
      ))}
    </LineDiagram>
  );
};

export default PlayerQuotationDiagram;
