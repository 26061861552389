import React, {useState} from 'react';
import styled from 'styled-components';
import Box from '../components/Box';
import logo from '../assets/images/logo.png';
import HeaderLanguage from '../components/HeaderLanguge';
import LanguageSelector from '../components/LanguageSelector';
import i18n from '../i18n';
import Image from '../components/Image';
import terrainOf from '../assets/images/terrainOf.jpg';
import Typo from '../components/Typo';
import {useTranslation} from 'react-i18next';
import useWindowDimensions from '../hooks/useWindowDimensions';
import PlayStoreButton from '../components/StoreButton';
import DownloadButton from '../components/DownloadButton';
import useClickFilter from '../hooks/useClickFilter';
const HeaderStyledLittle = styled(Box)`
  position: sticky;
  top: 0;
  height: 88px;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
  align-items: center;
`;
const Link = styled.a`
  text-decoration-color: ${({theme}) => theme.colors.secondary};
`;
const ContainerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 88px;
  bottom: 0;
  left: 0;
  right: 0px;
`;

const LittleScreen = () => {
  const {height} = useWindowDimensions();
  const imgSize = height < 650 ? 180 : 240;
  const imgBoxSize = height < 650 ? 192 : 252;
  const bottomBorder = height < 650 ? '20px' : '40px';
  const onChangeLanguage = (lg: string) => i18n.changeLanguage(lg);
  const [language, setLanguage] = useState(i18n.language);
  const {t} = useTranslation();
  const {
    containerRef,
    filterRef,
    isFilterOpen,
    onClickFilter,
    forceClose,
  } = useClickFilter();
  const userAgent = navigator.userAgent || navigator.vendor;
  const operatingSystem = /android/i.test(userAgent)
    ? 'Android'
    : /iPad|iPhone|iPod/.test(userAgent)
    ? 'iOS'
    : 'unknown';

  return (
    <>
      <HeaderStyledLittle zIndex={10} px="32px" bg="primary">
        <Box />
        <a href="https://mpg.football/">
          <img height="40px" width="103px" src={logo} alt="logo" />
        </a>
        <HeaderLanguage
          selectorItem={
            <LanguageSelector
              currentLanguage={language}
              onClickLanguage={(lg: string) => {
                onChangeLanguage(lg);
                setLanguage(lg);
                forceClose();
              }}
            />
          }
          containerRef={containerRef}
          filterRef={filterRef}
          isFilterOpen={isFilterOpen}
          onClickFilter={onClickFilter}
          currentLanguage={language}
        />
      </HeaderStyledLittle>
      <ContainerContent>
        <Box
          p="l"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Box
            mb={bottomBorder}
            borderRadius={imgSize}
            width={imgBoxSize}
            height={imgBoxSize}
            alignItems="center"
            justifyContent="center"
            border="12px solid white"
            overflow="hidden">
            <Image alt="terrainOf" url={terrainOf} size={imgSize} />
          </Box>
          <Box flexDirection="column" maxWidth="486px" alignItems="center">
            <Typo mb="l" textAlign="center" variant="title">
              {t('littleScreen.title')}
            </Typo>
            <Typo mb="24px" textAlign="center" variant="paragraph">
              {t('littleScreen.text')}
            </Typo>
            {operatingSystem === 'unknown' && (
              <Box flexDirection="row" alignItems="center">
                <Box mr="m">
                  <PlayStoreButton
                    isGoogle={false}
                    width={155}
                    language={language}
                    link={`https://apps.apple.com/${language}/app/mpg-football/id1171093849`}
                  />
                </Box>
                <Box flexDirection="row" alignItems="flex-start">
                  <PlayStoreButton
                    isGoogle
                    width={155}
                    language={language}
                    link={
                      'https://play.google.com/store/apps/details?id=com.monpetitgazon.monpetitgazonapp'
                    }
                  />
                </Box>
              </Box>
            )}
            {operatingSystem !== 'unknown' && (
              <DownloadButton
                link={
                  operatingSystem === 'iOS'
                    ? `https://apps.apple.com/${language}/app/mpg-football/id1171093849`
                    : 'https://play.google.com/store/apps/details?id=com.monpetitgazon.monpetitgazonapp'
                }
              />
            )}
            <Link href="https://mpg.football/">
              <Typo mt="20px" color="secondary" variant="paragraph">
                {t('littleScreen.link')}
              </Typo>
            </Link>
          </Box>
        </Box>
      </ContainerContent>
    </>
  );
};

export default LittleScreen;
