import { PlayerStatKeyProps } from './PlayerStatKey';
import roundWithDecimal from '../utils/roundWithDecimal';
import { OtherPositionStats } from '../utils/types';
import { useTranslation } from 'react-i18next';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';

interface StrikerDefensiveCardStatsProps {
  statistics: OtherPositionStats;
}

export default function StrikerDefensiveCardStats({
    statistics: {
        totalPlayedMatches,
        totalIntercept,
        totalTackle,
        totalGoalsConceded,
        totalMistake,
        totalCleanSheet,
    },
}: StrikerDefensiveCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
      stat: 'intercepted',
      value: totalPlayedMatches
        ? roundWithDecimal(totalIntercept / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'tackle',
      value: totalPlayedMatches
        ? roundWithDecimal(totalTackle / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'concededGoals',
      value: totalPlayedMatches
        ? roundWithDecimal(totalGoalsConceded / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'totalMistake',
      value: totalMistake,
    },
    {
        stat: 'totalCleanSheet',
      value: totalCleanSheet,
    },
  ];

  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.defensiveCard')} data={data} />
  )
};
