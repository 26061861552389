import { useEffect, useRef, useState } from "react";

const useClickFilter = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const onClickFilter = () => setIsFilterOpen((pre) => !pre);
  const forceClose = () => setIsFilterOpen(false);

  useEffect(() => {
    function onClickOutside(event: MouseEvent) {
      if (
        filterRef.current &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setIsFilterOpen(false);
      }
    }
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [filterRef]);
  return { isFilterOpen, containerRef, onClickFilter, filterRef, forceClose };
};

export default useClickFilter;
