import React, { FC, useState } from "react";
import { SpaceProps } from "styled-system";
import BoxButton from "./BoxButton";
import FilterContainer from "./FilterContainer";
import Typo from "./Typo";

export interface FilterItemProps extends SpaceProps {
  /**
   *  is the machin abled
   */
  activated: boolean;

  name: string;
  selectorItem: JSX.Element;
}
const FilterItem: FC<FilterItemProps> = ({
  activated,
  name,
  selectorItem,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  return (
    <FilterContainer selectorItem={selectorItem} isLeft top="45px">
      <BoxButton
        {...props}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        height="32px"
        px="6px"
        alignItems="center"
        border="1px solid"
        borderColor={activated ? "secondary" : "bg.dark"}
        bg={activated ? "secondaryTrans.20" : hover ? "bg.light" : "white"}
        borderRadius="xs"
      >
        <Typo
          color={activated ? "secondary" : "font.medium"}
          fontFamily="book"
          fontSize="s"
        >
          {name}
        </Typo>
      </BoxButton>
    </FilterContainer>
  );
};

export default FilterItem;
