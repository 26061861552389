import { PlayerStatKeyProps } from './PlayerStatKey';
import roundWithDecimal from '../utils/roundWithDecimal';
import { OtherPositionStats } from '../utils/types';
import { useTranslation } from 'react-i18next';
import PlayerStatisticsBlock from './PlayerStatisticsBlock';

interface MidfielderEfficiencyCardStatsProps {
  statistics: OtherPositionStats;
}

export default function MidfielderEfficiencyCardStats({
    statistics: {
        totalWonContest,
        totalPlayedMatches,
        totalContest,
        totalWonDuel,
        totalDuel,
        totalLostBall,
        totalFouls,
        totalFouled,
      },
}: MidfielderEfficiencyCardStatsProps) {
  const {t} = useTranslation();

  const data: Array<PlayerStatKeyProps> = [
    {
      stat: 'wonContest',
      value: totalPlayedMatches
        ? roundWithDecimal(totalWonContest / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalContest
          ? roundWithDecimal((totalWonContest / totalContest) * 100)
          : 0
      }%`,
    },
    {
        stat: 'wonDuel',
      value: totalPlayedMatches
        ? roundWithDecimal(totalWonDuel / totalPlayedMatches, 1)
        : 0,
      subValue: `${
        totalDuel ? roundWithDecimal((totalPlayedMatches / totalDuel) * 100) : 0
      }%`,
    },
    {
        stat: 'lostBall',
      value: totalPlayedMatches
        ? roundWithDecimal(totalLostBall / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'fouls',
      value: totalPlayedMatches
        ? roundWithDecimal(totalFouls / totalPlayedMatches, 1)
        : 0,
    },
    {
        stat: 'fouled',
      value: totalPlayedMatches
        ? roundWithDecimal(totalFouled / totalPlayedMatches, 1)
        : 0,
    },
  ];

  return (
    <PlayerStatisticsBlock title={t('PlayerStatistics.tabs.Stats.efficiencyCard')} data={data} />
  )
};
