import React, { FC } from "react";
import Box from "./Box";
import { HEIGHT_GRAPH_AREA_USED, WIDTH_BOX } from "./LineDiagram";
import Typo from "./Typo";
interface PlayerQuotationProps {
  quotation: number;
  isHigher: boolean;
}
const PlayerQuotation: FC<PlayerQuotationProps> = ({ quotation, isHigher }) => {
  return (
    <Box
      zIndex={15}
      flexDirection="column"
      position="relative"
      height={HEIGHT_GRAPH_AREA_USED}
      width={WIDTH_BOX}
    >
      <Box
        bottom={(quotation * HEIGHT_GRAPH_AREA_USED) / 50 - 20}
        left={(WIDTH_BOX - 40) / 2}
        position="absolute"
        alignItems="center"
        justifyContent="center"
        height="40px"
        width="40px"
        borderRadius="s"
        border="1px solid"
        borderColor="primary"
        bg={isHigher ? "primary" : "white"}
      >
        <Typo variant="header" color={isHigher ? "white" : "primary"}>
          {quotation}
        </Typo>
      </Box>
    </Box>
  );
};

export default PlayerQuotation;
