import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Box from "./Box";
import Typo from "./Typo";
const StyledLink = styled.a`
  text-decoration: none;
  border-radius: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const StyledBox = styled(Box)`
  box-shadow: 2px 2px 4px #00000029;
`;
interface DownloadButtonProps {
  link: string;
}
const DownloadButton: FC<DownloadButtonProps> = ({ link }) => {
  const { t } = useTranslation();
  return (
    <StyledLink href={link}>
      <StyledBox
        flex={1}
        bg="secondary"
        border="2px solid white"
        maxWidth="348px"
        justifyContent="center"
        borderRadius="24px"
        padding="13px"
      >
        <Typo variant="header" color="white">
          {t("littleScreen.download")}
        </Typo>
      </StyledBox>
    </StyledLink>
  );
};

export default DownloadButton;
