import {useQuery, UseQueryOptions} from 'react-query';
import {IGetPlayerSeasons} from '../utils/types';
import {ApiError, apiWrapper, axiosConfig} from './config';

const PLAYER_STATS_QUERYKEY = 'PLAYER_STATS_QUERYKEY';

const getPlayerSeasons = (championshipPlayerId: string) => {
  return apiWrapper(
    axiosConfig.get<IGetPlayerSeasons>(
      `/api/data/championship-player-stats-summary/${championshipPlayerId}`,
    ),
  );
};

export const usePlayerSeasons = (
  championshipPlayerId: string,
  options?: UseQueryOptions<IGetPlayerSeasons, ApiError>,
) =>
  useQuery<IGetPlayerSeasons, ApiError>(
    [PLAYER_STATS_QUERYKEY, championshipPlayerId],
    () => getPlayerSeasons(championshipPlayerId),
    options,
  );
