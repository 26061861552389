import React, {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Arrow from '../assets/icons/Arrow';
import Box from './Box';
import BoxButton from './BoxButton';

const StyledInput = styled.input`
  flex: 1;
  display: flex;
  -webkit-outline: none;
  -moz-outline: none;
  -ms-outline: none;
  -o-outline: none;
  outline: none;
  border: none;

  padding: 10px 12px;
  font-size: 15px;
  font-family: ${({theme}) => theme.fonts.medium};
  color: ${({theme}) => theme.colors.font.dark};
  background: transparent;

  &::placeholder {
    color: ${({theme}) => theme.colors.font.light};
  }
`;
const StyledContainer = styled(Box)<{focused: boolean}>`
  box-shadow: 0 0 0
    ${({theme, focused}) =>
      !focused ? '0' : ` 3px ${theme.colors.secondaryTrans[50]}`};
`;
interface CodeInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}
const CodeInput: FC<CodeInputProps> = ({value, onChange, onSubmit}) => {
  const {t} = useTranslation();
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const refInput = useRef<HTMLInputElement>(null);

  const isDisabled = value.length === 0;

  return (
    <StyledContainer
      alignItems="center"
      borderRadius="s"
      overflow="hidden"
      px="s"
      border="1px solid"
      borderColor="bg.dark"
      bg={focused ? 'white' : 'bg.light'}
      flex={1}
      focused={focused}>
      <StyledInput
        ref={refInput}
        value={value}
        onChange={e => onChange(e.target.value)}
        onSubmit={onSubmit}
        placeholder={t('code.placeholder')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onBlur();
            onSubmit();
            if (refInput.current) {
              refInput.current.blur();
            }
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <BoxButton
        onClick={onSubmit}
        disabled={isDisabled}
        alignItems="center"
        justifyContent="center"
        height="32px"
        width="32px"
        bg={isDisabled ? 'secondaryTrans.50' : 'secondary'}
        borderRadius="xs">
        <Arrow color="white" size={32} direction={'right'} />
      </BoxButton>
    </StyledContainer>
  );
};

export default CodeInput;
