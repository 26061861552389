import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { getChampionshipImage } from "../utils/championship";
import Box from "./Box";
import freeKick from "../assets/images/freekick.png";
import PlayerRanking from "./PlayerRanking";
interface PlayerRankingProps {
  championship: number;
  championshipRanking: number;
  position: number;
  positionRanking: number;
  club: string;
  clubJersey: string;
  clubRanking: number;
}

const PlayerRankings: FC<PlayerRankingProps> = ({
  championship,
  championshipRanking,
  club,
  clubRanking,
  clubJersey,
  position,
  positionRanking,
}) => {
  const { t } = useTranslation();
  return (
    <Box bg="white" py="l" borderRadius="s">
      <PlayerRanking
        ranking={championshipRanking}
        label={t(`championships.${championship}`)}
        image={getChampionshipImage(championship)}
      />
      <Box width="1px" mx="14px" bg="bg.dark" />
      <PlayerRanking
        ranking={positionRanking}
        label={t(`playerPositions.presentation.${position}`)}
        image={freeKick}
      />
      <Box width="1px" mx="14px" bg="bg.dark" />
      <PlayerRanking ranking={clubRanking} label={club} image={clubJersey} />
    </Box>
  );
};

export default PlayerRankings;
